import React, { Component } from 'react';
import { Route, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { QUERY_STATUSES, monthsArray } from '../../../constants';
import { fetchLeadListingFilters, fetchLeadListingFiltersSuccess} from '../../../actions/quotes';

import $ from 'jquery';

class AllQuotesFilters extends Component {
  constructor(props){
    super(props);
    var defaultColClass = "col-sm-4";
    var specialContentClass = "";
    var quickView = false;


    this.state = {
				quoteFilterData: null,
        lead_type: props.lead_type,
        filterSelectedValues:{

          departure_city: '',
          source_city: '',
          month_of_travel: '',
          no_adults: '',

        },
        fetchingRecords: props.fetchingRecords,
	    };
  }

  componentDidMount() {
    this.fetchQuoteFilters(this.state.lead_type);

    var widthFilters = $('#filters-form-div').innerWidth();

    var heightFilters = $('#filters-form-div').innerHeight();
    $('#filters-form-div').css('width',widthFilters);
    $('.filter-height').css('height',heightFilters);

    var fixmeTop = $('.form-block').offset().top;
    if(fixmeTop){
      $(window).scroll(function() {
          var currentScroll = $(window).scrollTop();
          if (currentScroll >= fixmeTop) {
              $('body').addClass("content-fixed");
              //setTimeout(function(){

            //}, 100);
          } else {
             $('body').removeClass("content-fixed");
          }
        });
      }
  }

  fetchQuoteFilters(lead_type){
    this.props.fetchLeadListingFilters({type:lead_type}).then((response) => {
			if(response.value.data.result === 'success'){
				this.setState({quoteFilterData: response.value.data.payload});
			}
		})
  }

  applyFilters(e){
    if(e){
      e.preventDefault();
    }
    var destination = $('#destination_city').val();
    var departure = $('#source_city').val();
    var departure_month = $('#month_of_travel').val();
    var adults = $('#no_adults').val();
    if(this.state.lead_type && this.state.lead_type == 'verified'){
      var verified_on = $('#verified_on').val();
    }
    var params = {destination,departure,departure_month,adults, verified_on};
    this.props.applyFilters(params);

  }

  clearAllFilters(e){
    e.preventDefault();
    this.props.applyFilters({destination:'', departure:'', departure_month:'', adults:''});
    document.getElementById('filters-form').reset();
  }




  componentWillReceiveProps(nextProps){



  }

	render() {
    return (
      <div className="row">
      <div className="col-lg-12 col-md-4 col-sm-12 col-xs-12">
        <div className="card info-card card-borderless tab-ui-block shadow-card filter-block-right">
          <div className="head-block-filter">
            <h2>Filters</h2>
            <a href="javascript:void(0);" onClick={(e) => this.clearAllFilters(e)}>CLEAR ALL</a>
          </div>
          <div className="form-block" id = "filters-form-div">
            <form  id="filters-form">
            <div className="row">
              <div className="col-sm-2">
            <div className="form-group">
              <label>Destination</label>
              <div className="controls custom-select">
                <select className=" cs-select cs-skin-slide " id = "destination_city" onChange={(e) => this.applyFilters()}>
                  <option value="" key='-1' >Select</option>
                  {
                    this.state.quoteFilterData && this.state.quoteFilterData.destination && this.state.quoteFilterData.destination.length > 0
                    && this.state.quoteFilterData.destination.map((obj, index) => (
                      <option value={obj} key={index}>{obj}</option>
                    ))
                  }
                </select>
              </div>
            </div>
          </div>
          <div className="col-sm-2">
            <div className="form-group">
              <label>Source City</label>
              <div className="controls custom-select">
                <select className="cs-select cs-skin-slide" data-init-plugin="select2" id = "source_city" onChange = {(e) => this.applyFilters()}>
                  <option value="" key='-1' >Select</option>
                  {
                    this.state.quoteFilterData && this.state.quoteFilterData.departure && this.state.quoteFilterData.departure.length > 0
                    && this.state.quoteFilterData.departure.map((obj, index) => (
                      <option value={obj} key={index}>{obj}</option>
                    ))
                  }

                </select>
              </div>
            </div>
          </div>
            {
              this.state.lead_type && this.state.lead_type == 'verified' &&
              <div className="col-sm-2">
              <div className="form-group">
                <label>Verified</label>
                <div className="controls custom-select">
                  <select className="cs-select cs-skin-slide"  id = "verified_on" onChange = {(e) => this.applyFilters()}>
                    <option value="" >Select</option>
                    <option value="email" >Verified Through Email</option>
                    <option value="phone" >Verified Through Phone</option>

                  </select>
                </div>
              </div>
            </div>
            }

              <div className="col-sm-2">
                <div className="form-group">
                  <label>Month of Travel</label>
                  <div className="controls custom-select">
                    <select className="cs-select cs-skin-slide" data-init-plugin="select2" id = "month_of_travel" onChange = {(e) => this.applyFilters()}>
                      <option value="" key='-1' >Select</option>
                      {
                        this.state.quoteFilterData && this.state.quoteFilterData.departure_month && this.state.quoteFilterData.departure_month.length > 0
                        && this.state.quoteFilterData.departure_month.map((obj, index) => (
                          obj > 0 && <option value={obj} key={index}>{monthsArray[parseInt(obj) - 1].fullName}</option>
                        ))
                      }

                    </select>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="form-group">
                  <label>No. of Adults</label>
                  <div className="controls custom-select">
                    <select className="cs-select cs-skin-slide" data-init-plugin="select2" id = "no_adults" onChange = {(e) => this.applyFilters()}>
                      <option value="" key='-1' >Select</option>
                      {
                        this.state.quoteFilterData && this.state.quoteFilterData.adults && this.state.quoteFilterData.adults.length > 0
                        && this.state.quoteFilterData.adults.map((obj, index) => (
                          <option value={obj} key={index}>{obj}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
              </div>

            </div>

          </form>
          </div>
        </div>
      </div>
    </div>

    );
	}
}



const mapDispatchToProps = (dispatch) => {
  return {
		fetchLeadListingFilters: (data) => {
      return dispatch(fetchLeadListingFilters(data));
    }
  }
}
export default connect(null,mapDispatchToProps)(AllQuotesFilters);
