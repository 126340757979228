import React, { Component } from 'react';
import { Route, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import 'moment-timezone';



class TravelerInfo extends Component {
  constructor(props) {
    super(props);
    var defaultColClass = "col-sm-4";
    var specialContentClass = "";
    var quickView = false;
    if (props.defaultColClass) {
      defaultColClass = props.defaultColClass;
    }
    if (props.quickView) {
      quickView = true;
    }
    this.state = {
      defaultColClass: props.defaultColClass ? props.defaultColClass : defaultColClass,
      specialContentClass: props.specialContentClass ? props.specialContentClass : specialContentClass,
      record: props.travelerInfo,
      quickView: quickView
    };
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
  }

  render() {
    const { info } = this.props;
    //alert(this.state.detailView);
    return (
      <div>
        <div className="detail-tour">
          <div className="row">
            <div className={this.state.defaultColClass + " tour-view-list"}>
              <label>DESTINATION</label>
              <span>{this.state.record && this.state.record.destination}</span>
            </div>

            <div className={this.state.defaultColClass + " tour-view-list"}>
              <label>FROM</label>
              <span>{this.state.record && this.state.record.departure}</span>
            </div>
            <div className={this.state.defaultColClass + " tour-view-list"}>
              <label>Type of Package</label>
              <span className="highlight-red">{this.state.record && this.state.record.title}</span>
            </div>
            <div className={this.state.defaultColClass + " tour-view-list"}>
              <label>DEPARTURE {this.state.record && this.state.record.flexible_timing == 0 ? 'Date' : 'Month'}</label>
              {this.state.record && this.state.record.flexible_timing == 0 && <span><Moment format="DD MMM, YY">{this.state.record.departure_date}</Moment></span>}
              {this.state.record && this.state.record.flexible_timing == 1 && <span><Moment format="MMMM">{this.state.record.departure_date}</Moment></span>}
            </div>

            <div className={this.state.defaultColClass + " tour-view-list"}>
              <label>TRAVELERS</label>

              <span>{this.state.record && this.state.record.adults} Adults . {this.state.record && this.state.record.children} Children</span>
            </div>
            <div className={this.state.defaultColClass + " tour-view-list"}>
              <label>DURATION</label>
              <span>{info && info.days} days & {info && info.nights} {info && info.nights > 1 ? "nights" : "night"}</span>
              {/* <span>{this.state.record && this.state.record.no_of_days} days & {this.state.record && this.state.record.no_of_days - 1} {this.state.record && this.state.record.no_of_days - 1 > 1 ? "nights" : "night"}</span> */}
            </div>

            {
              !this.state.quickView &&
              <div className={this.state.defaultColClass + " tour-view-list"}>
                <label>Budget ({(this.state.record && this.state.record.flight_status == 1) ? 'with' : 'without'} Flight)</label>
                <span>{this.state.record && this.state.record.budget}</span>
              </div>
            }
            {
              !this.state.quickView &&
              <div className={this.state.defaultColClass + " tour-view-list"}>
                <label>HOTEL ACCOMMODATION</label>
                <span>{this.state.record && this.state.record.hotel_preferences ? "Yes . " + this.state.record.hotel_preferences + " star" : 'Not required'}</span>

              </div>
            }
            {
              !this.state.quickView &&
              <div className={this.state.defaultColClass + " tour-view-list"}>
                <label>Cab For Sightseeing</label>
                <span>{this.state.record && this.state.record.cab_type ? "Yes" : "No"}  {this.state.record && this.state.record.cab_type ? ". Private" : ""}</span>
              </div>
            }


          </div>
        </div>
        {
          !this.state.quickView &&
          <div className={this.state.specialContentClass + " special-content"}>
            <label>Special Requests</label>
            <p>{this.state.record && this.state.record.additional_requirements}</p>
          </div>
        }
      </div>

    );
  }
}



export default TravelerInfo;
