import React, { Component, PropTypes } from 'react';
const renderRadio = ({ input, id, initialValue, notes, parentDivClass, fieldClass, label, type, placeholder, props, meta: { initial, touched, error, invalid, warning } }) => {
  //console.log('rrrrrr', initial);
  return (
    <div className={`${"radio-active"} ${parentDivClass ? parentDivClass : ' '} ${touched && error ? ' has-error ' : ''}`} >
      <input {...input} id={id ? id : ''} className={` ${fieldClass ? fieldClass : '   '} `} type='radio' />
      <label htmlFor={id ? id : ''}>{label}</label>
      {touched && ((error && <span className="error">{error}</span>) || (warning && <span className="error">{warning}</span>))}
    </div>
  )
}

export default renderRadio;
