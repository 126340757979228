import React, { Component } from 'react';
import { connect } from 'react-redux';


import {objToQueryParams, WEBSITE_TITLE, inArray, mysqlDateToJavascript, yyyymmdd} from "../../../constants";
import MetaTags from 'react-meta-tags';
import Loader from '../../../components/Loader';


class PerformanceByMonth extends Component {
  constructor(props){
    super(props);
    this.state = {
      records: null,
    }
  }

  componentDidMount() {
    this.setState({records: this.props.records, fetchingReport: this.props.fetchingReport});
  }
  componentWillReceiveProps(nextProps) {
    /*if(!this.props.records && nextProps.records){
      this.setState({records: nextProps.records});
    }*/
    this.setState({records: nextProps.records, fetchingReport: nextProps.fetchingReport});
  }

  render() {
    return (


            <div className="table-right-block">
              {this.state.fetchingReport && <Loader />}
              <table>
                <thead>
                  <tr>
                    {
                      this.state.records && this.state.records.months && this.state.records.months.length > 0 && this.state.records.months.map((obj, index) => {

                        return(
                          <th key={obj.month}>{obj.month_year}</th>
                        )
                      })
                    }
                  </tr>
                </thead>
                <tbody>

                  <tr>
                    <td height="52" className="no-border"></td>
                  </tr>
                  <tr>
                    {
                      this.state.records && this.state.records.months && this.state.records.months.length > 0 && this.state.records.months.map((obj, index) => {
                        var count = 0;
                        this.state.records.primary_metric && this.state.records.primary_metric.length > 0 && this.state.records.primary_metric.map((obj1, index1) => {
                          if(parseInt(obj.month) == obj1.month){
                            count = obj1.leads;
                          }
                        })
                        return(
                          <td key={obj.month}>{count}</td>
                        )
                      })
                    }
                  </tr>
                  <tr>
                    {
                      this.state.records && this.state.records.months && this.state.records.months.length > 0 && this.state.records.months.map((obj, index) => {
                        var count = 0;
                        this.state.records.primary_metric && this.state.records.primary_metric.length > 0 && this.state.records.primary_metric.map((obj1, index1) => {
                          if(parseInt(obj.month) == obj1.month){
                            count = obj1.quoted_trips;
                          }
                        })
                        return(
                          <td key={obj.month}>{count}</td>
                        )
                      })
                    }
                  </tr>
                  <tr>
                    {
                      this.state.records && this.state.records.months && this.state.records.months.length > 0 && this.state.records.months.map((obj, index) => {
                        var count = 0;
                        this.state.records.primary_metric && this.state.records.primary_metric.length > 0 && this.state.records.primary_metric.map((obj1, index1) => {
                          if(parseInt(obj.month) == obj1.month){
                            count = obj1.converted_trips;
                          }
                        })
                        return(
                          <td key={obj.month}>{count}</td>
                        )
                      })
                    }
                  </tr>

                  <tr>
                    <td height="52" className="no-border"></td>
                  </tr>
                  <tr>
                    {
                      this.state.records && this.state.records.months && this.state.records.months.length > 0 && this.state.records.months.map((obj, index) => {
                        var count = 0;
                        this.state.records.result_notes && this.state.records.result_notes.length > 0 && this.state.records.result_notes.map((obj1, index1) => {
                          if(parseInt(obj.month) == obj1.month){
                            count = obj1.notes;
                          }
                        })
                        return(
                          <td key={obj.month}>{count}</td>
                        )
                      })
                    }
                  </tr>

                  <tr>
                    {
                      this.state.records && this.state.records.months && this.state.records.months.length > 0 && this.state.records.months.map((obj, index) => {
                        var count = 0;
                        this.state.records.result_messages && this.state.records.result_messages.length > 0 && this.state.records.result_messages.map((obj1, index1) => {
                          if(parseInt(obj.month) == obj1.month){
                            count = obj1.traveler;
                          }
                        })
                        return(
                          <td key={obj.month}>{count}</td>
                        )
                      })
                    }
                  </tr>



                  <tr>
                    {
                      this.state.records && this.state.records.months && this.state.records.months.length > 0 && this.state.records.months.map((obj, index) => {
                        var count = 0;
                        this.state.records.result_messages && this.state.records.result_messages.length > 0 && this.state.records.result_messages.map((obj1, index1) => {
                          if(parseInt(obj.month) == obj1.month){
                            count = obj1.agents;
                          }
                        })
                        return(
                          <td key={obj.month}>{count}</td>
                        )
                      })
                    }
                  </tr>
                </tbody>
              </table>
            </div>



    );
	}
}

const mapStatesToProps = (state, ownProps) => {
  return {
    records: ownProps.records
  };
}


export default connect(null, null)(PerformanceByMonth);
