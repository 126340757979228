import React, { Component } from 'react';
import { Route, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Moment from 'react-moment';
import 'moment-timezone';
import { objToQueryParams, WEBSITE_TITLE } from "../../../constants";
import { listLeads, fetchLeadListingFilters, fetchLeadListingFiltersSuccess, listQuoteRejectionReasons, listQuoteRejectionReasonsSuccess, markVerified, viewPhoneNumber } from '../../../actions/quotes';
import TravelerInfo from './TravelerInfo';
import LeadsFilter from './LeadsFilter';
import RejectQuote from './RejectQuote';
import MetaTags from 'react-meta-tags';
import Loader from '../../../components/Loader';
import ReactPaginate from 'react-paginate';


class UnverifiedLeads extends Component {
  constructor(props) {
    super(props);
    var page = 1;
    var searchQuery = '';
    var destination = '';
    var departure = '';
    var departure_month = '';
    var adults = '';
    if (props.location.search) {
      const values = queryString.parse(this.props.location.search);
      if (values.page) {
        page = values.page;
      }
      if (values.search) {
        searchQuery = values.search;
      }
      if (values.destination) {
        destination = values.destination;
      }
      if (values.departure) {
        departure = values.departure;
      }
      if (values.departure_month) {
        departure_month = values.departure_month;
      }
      if (values.adults) {
        adults = values.adults;
      }
    }
    this.state = {
      fetchingPhoneNumber: false,
      page: page,
      searchQuery: searchQuery,
      destination: destination,
      departure: departure,
      departure_month: departure_month,
      adults: adults,
      status: props.status,
      records: [],
      allData: null,
      openEditForm: false,
      quoteFilterData: props.quote_filters ? props.quote_filters : null,
      rejection_reasons: props.rejection_reasons ? props.rejection_reasons : null,
      fetchingRecords: false,
    };
    this.applyFilters = this.applyFilters.bind(this);
  }

  componentDidMount() {
    this.fetchRecords({ page: this.state.page, searchQuery: this.state.searchQuery, destination: this.state.destination, departure: this.state.departure, departure_month: this.state.departure_month, adults: this.state.adults });
    this.fetchRejectionReasons();
  }
  applyFilters(params) {
    var page = 1;
    var queryParams = this.props.location.search;
    var path = this.props.location.pathname;
    var values = queryString.parse(this.props.location.search);
    values = Object.assign(values, { page: page, destination: params.destination, departure: params.departure, departure_month: params.departure_month, adults: params.adults });
    this.props.history.push(path + "?" + objToQueryParams(values));
  }

  fetchRecords(params) {
    if (!params) {
      params = { type: "unverified" };
    } else {
      params = Object.assign(params, { type: "unverified" });
    }
    this.setState({ fetchingRecords: true });
    this.props.listLeads(params).then((response) => {
      this.setState({ fetchingRecords: false });
      if (response.value.data.result === 'success') {
        this.setState({ records: response.value.data.payload.data, allData: response.value.data.payload });
      }
    })
  }



  fetchRejectionReasons() {
    this.props.listQuoteRejectionReasons().then((response) => {
      if (response.value.data.result === 'success') {
        this.props.listQuoteRejectionReasonsSuccess(response.value.data.payload);
      }
    })
  }


  componentWillReceiveProps(nextProps) {
    if (this.props.status != nextProps.status) {
      this.setState({ records: nextProps.quotes });
    }
    if (!this.props.rejection_reasons && nextProps.rejection_reasons) {
      this.setState({ rejection_reasons: nextProps.rejection_reasons });
    }
    if (!this.props.quote_filters && nextProps.quote_filters) {
      this.setState({ quoteFilterData: nextProps.quote_filters });
    }

    if (this.props.location.search != nextProps.location.search) {
      var queryParams = nextProps.location.search;
      var path = nextProps.location.pathname;
      var values = queryString.parse(nextProps.location.search);
      this.setState({ page: values.page ? values.page : 1 });
      this.fetchRecords({
        page: values.page ? values.page : 1,
        searchQuery: values.search ? values.search : '',
        destination: values.destination ? values.destination : '',
        departure: values.departure ? values.departure : '',
        departure_month: values.departure_month ? values.departure_month : '',
        adults: values.adults ? values.adults : '',

      });
    }

  }

  markVerified(e, quote_id) {
    e.preventDefault();
    var recordsArray = [];
    this.setState({ fetchingRecords: true });
    this.props.markVerified({ quote_id }).then((response) => {
      this.setState({ fetchingRecords: false });
      if (response.value.data.result === 'success') {

        var queryParams = this.props.location.search;
        var path = this.props.location.pathname;
        var values = queryString.parse(this.props.location.search);
        values = Object.assign(values, { refresh_time: new Date().getTime() });
        this.props.history.push(path + "?" + objToQueryParams(values));
        /*var records = this.state.records;
        for(var i=0; i < records.length; i++){
          if(records[i].id != quote_id){
            recordsArray.push(records[i]);
          }
        }
        this.setState({records: recordsArray.length > 0?recordsArray:null});*/
      }
    });
  }

  handlePageClick = (data) => {
    var page = parseInt(data.selected) + 1;
    var queryParams = this.props.location.search;
    var path = this.props.location.pathname;
    var values = queryString.parse(this.props.location.search);
    values = Object.assign(values, { page: page, searchQuery: values.searchQuery ? values.searchQuery : '' });
    this.props.history.push(path + "?" + objToQueryParams(values));
  }

  getPhoneNumber(e, quote_id) {
    this.setState({ fetchingPhoneNumber: true });
    e.preventDefault();
    this.props.viewPhoneNumber({ quote_id: quote_id, from_page: 'unverified_phone_access' }).then((response) => {
      this.setState({ fetchingPhoneNumber: false });
      var records = this.state.records;
      if (records.length > 0) {
        for (var i = 0; i < records.length; i++) {
          if (records[i].id == quote_id) {
            records[i] = Object.assign(records[i], { unverified_phone_access: 1 });
            break;
          }
        }
      }
      this.setState({ records: records });
    })
  }

  render() {
    console.log(this.state.records, "records")
    return (
      <div className="dashboard-content">
        <MetaTags>
          <title>{WEBSITE_TITLE} – unverified leads</title>
        </MetaTags>
        <div className="content sm-gutter">
          <div className="container-fluid p-t-0 p-b-25 sm-padding-10 new-quotes">
            <LeadsFilter lead_type="unverified" applyFilters={this.applyFilters} fetchingRecords={this.state.fetchingRecords} filterDefaultValues={{ query_status: this.state.query_status, destination: this.state.destination }} />
            <div className="filter-height"></div>
            <div className="row">
              {
                this.state.fetchingRecords && <Loader />
              }
              {
                this.state.fetchingPhoneNumber && <Loader />
              }
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 new-quotes-left">
                <div className="header-block-ui no-border">
                  <h3>Unverified Requests</h3>
                </div>
                <div className="card info-card card-borderless tab-ui-block shadow-card">
                  {
                    this.state.records && this.state.records.length > 0 &&
                    this.state.records.map((obj, inxex) => (
                      <div key={obj.id} className="quotes-status-block active-quotes">
                        <div className="quotes-id-block">
                          <a target="_blank" href={"/admin/quotes/detail/" + obj.id}><label style={{cursor: "pointer"}}>ID {obj.id}</label></a>
                          {/*<div className="id-status">Active</div>*/}
                          <time className="cls-time"><Moment format="DD MMM, YY h:mm a" fromNow fromNowDuring={36000000}>{obj.created_at + '-0000'}</Moment></time>
                        </div>
                        <div className="content-quotes">
                          <h3>Quote request by {obj.name} from {obj.departure} to {obj.destination}</h3>
                        </div>
                        <TravelerInfo quickView={true} travelerInfo={obj} />

                        <div className="qutoes-block-bottom">
                          <div className="quote-user">
                            <figure>
                              {/*<img src="/assets/img/alexa.png" />*/}
                            </figure>
                            <figcaption>
                              <label>{obj.name}</label>
                              {obj.unverified_phone_access == 0 && <label><a href="#" onClick={(e) => this.getPhoneNumber(e, obj.id)}><small><i className="fa fa-mobile" aria-hidden="true"></i> Get Phone Number</small></a></label>}
                              {obj.unverified_phone_access == 1 && <label><small>{obj.country_code}-{obj.contact_number}</small></label>}
                            </figcaption>
                          </div>
                          <div className="new-quote-action">
                            <a href="#" onClick={(e) => this.markVerified(e, obj.id)} className="btn btn-primary">Mark Verified</a>
                            {this.state.rejection_reasons && this.state.rejection_reasons.length > 0 && <RejectQuote rejection_reasons={this.state.rejection_reasons} quote_id={obj.id} fetchRecords={this.fetchRecords} list_type="1" />}
                          </div>

                        </div>
                      </div>
                    ))
                  }
                  {
                    !this.state.fetchingRecords && !this.state.records.length > 0 &&
                    <div className="quotes-unavailable">
                      <figure className="icon-block"><ion-icon name="list-box"></ion-icon></figure>
                      <figcaption>
                        <p>There are no Unverified Leads available at this time</p>
                      </figcaption>
                    </div>
                  }
                </div>
                {
                  this.state.allData && this.state.allData.total > 0 &&
                  <ReactPaginate previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    nextClassName="page-item"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    initialPage={parseInt(this.state.page) - 1}
                    breakClassName={"break-me"}
                    pageCount={this.state.allData.last_page}
                    onPageChange={this.handlePageClick}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"} />
                }
              </div>

            </div>
          </div>
        </div>
      </div>

    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    quotes: state.quote.new_quotes,
    status: state.quote.status,
    rejection_reasons: state.quote.rejection_reasons ? state.quote.rejection_reasons : null,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    listLeads: (data) => {
      return dispatch(listLeads(data));
    },
    listQuoteRejectionReasons: (params) => {
      return dispatch(listQuoteRejectionReasons(params));
    },
    listQuoteRejectionReasonsSuccess: (data) => {
      dispatch(listQuoteRejectionReasonsSuccess(data));
    },
    markVerified: (data) => {
      return dispatch(markVerified(data));
    },
    viewPhoneNumber: (data) => {
      return dispatch(viewPhoneNumber(data));
    }
  }
}
export default connect(mapStatesToProps, mapDispatchToProps)(UnverifiedLeads);
