import React, {Component} from 'react';
import {Route, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import queryString from 'query-string';
import Moment from 'react-moment';
import 'moment-timezone';
import $ from 'jquery';
import Loader from '../../../components/Loader';
import QuoteNotesNew from '../quotes/QuoteNotesNew';


import {objToQueryParams} from "../../../constants";
import {fetchPayments, fetchPaymentsSuccess, markPaymentReceived, markPaymentMade, deleteInvoiceUploads} from '../../../actions/quotes';
import CurrencyFormat from 'react-currency-format';
import {mysqlDateToJavascript, yyyymmdd, API_ROOT} from '../../../constants';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

// Import React FilePond
import {FilePond, File, registerPlugin} from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
const token = localStorage.getItem('jwtToken');
class PaymentsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: null,
      fetchingRecords: false,
    };
  }

  componentDidMount() {
    this.fetchRecords();
    $(document).click(function(e){
      if(!$(e.target).parents('.view-btn-block').length){
        $('.view-link').removeClass('active');
        $(".vc-block").removeClass('active');
      };

    });
  }

  componentDidUpdate(){
    /*$(".view-link").click(function(){
          $(".vc-block").toggleClass("active");
      })*/

  }
  showFiles(e, action_type){
    e.stopPropagation();
    //e.preventDefault();
    $('.view-link').removeClass('active');
    $(e.target).addClass('active');
    $(".vc-block").removeClass('active');
    if(action_type == 'voucher'){
      $(".vc-block-voucher").toggleClass("active");
    }else if(action_type == 'payment'){
      $(".vc-block-payment").toggleClass("active");
    }

  }

  markPaymentReceived(e, id, quote_id) {
    confirmAlert({
      title: 'Confirm!',
      message: 'Are you sure to mark this payment as payment received?',
      buttons: [
        {
          label: 'Cancel'
        }, {
          label: 'Confirm',
          onClick: () => {
            this.props.markPaymentReceived({installment_id: id, quote_id: quote_id}).then((response) => {
              if (response.value.data.result === 'success') {
                this.fetchRecords();
              }
            })
          }
        }
      ]
    });
  }

  markPaymentMade(e, id) {
    confirmAlert({
      title: 'Confirm!',
      message: 'Are you sure to mark this payment as payment made?',
      buttons: [
        {
          label: 'Cancel'
        }, {
          label: 'Confirm',
          onClick: () => {
            this.props.markPaymentMade({installment_id: id}).then((response) => {
              if (response.value.data.result === 'success') {
                this.fetchRecords();
              }
            })
          }
        }
      ]
    });
  }

  fetchRecords(params) {
    this.setState({fetchingRecords: true});
    this.props.fetchPayments(params).then((response) => {
      this.setState({fetchingRecords: false});
      if (response.value.data.result === 'success') {
        this.setState({records: response.value.data.payload});
        this.props.fetchPaymentsSuccess(response.value.data.payload);
      }
    })
  }
  deleteInvoiceUploads(e, attachment_id, action_type){
    confirmAlert({
      title: 'Confirm!',
      message: 'Are you sure to delete this file?',
      buttons: [
        {
          label: 'Cancel'
        }, {
          label: 'Confirm',
          onClick: () => {
            this.props.deleteInvoiceUploads({attachment_id, type: action_type}).then((response) => {
              if(response.value.data.result === 'success'){
                this.fetchRecords();
              }
            })
          }
        }
      ]
    });
    /*if(attachment_id){
      this.props.deleteInvoiceUploads({attachment_id, type: action_type}).then((response) => {

      })
    }*/
  }

  fetchQuoteFilters() {}

  render() {
    return (<div className="dashboard-content">
      <div className="content sm-gutter">
        <div className="container-fluid p-t-0 p-b-25 sm-padding-10 new-quotes payment-vouchers">
          <div className="row">
            {
              this.state.fetchingRecords && <Loader />
            }
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 new-quotes-left">
              <div className="header-block-ui no-border">
                <h3>Payments & Vouchers</h3>
              </div>
              <div className="card info-card card-borderless tab-ui-block ">
                {
                  !this.state.fetchingRecords && this.state.records && this.state.records.query && this.state.records.query.map((obj, index) => {
                    var installments = null;
                    var amount_received = 0;
                    var total_amount_to_be_received = 0;
                    var vendor_amount = (obj.quotation.flight_cost?parseInt(obj.quotation.flight_cost):0) + (obj.quotation.visa_cost?parseInt(obj.quotation.visa_cost):0) + (obj.quotation.package_cost?parseInt(obj.quotation.package_cost):0);
                    var amount_pending = 0;
                    if (obj.quotation && obj.quotation.invoice && obj.quotation.invoice[0] && obj.quotation.invoice[0].installments && obj.quotation.invoice[0].installments.length > 0) {
                      installments = obj.quotation.invoice[0].installments;

                      installments.map((objIns, indexIns) => {
                        if (objIns.payment_received == 1) {
                          amount_received += parseInt(objIns.installment);
                        }
                        total_amount_to_be_received += parseInt(objIns.installment);
                      })
                    }

                    var starting_date = yyyymmdd(mysqlDateToJavascript(obj.quotation.start_date));
                    //var total_payment_to_be_received = parseInt(obj.quotation.package_cost?obj.quotation.package_cost:0) + parseInt(obj.quotation.visa_cost?obj.quotation.visa_cost:0) + parseInt(obj.quotation.flight_cost?obj.quotation.flight_cost:0);
                    amount_pending = total_amount_to_be_received - amount_received;
                    return (<div key={index} className="quotes-status-block active-quotes shadow-card">
                      <div className="quotes-id-block">
                        <label>
                          <a href={"/admin/quotes/follow_up/detail/"+obj.trip_id} target="_blank">ID {obj.trip_id}</a>
                        </label>
                        <div className="id-status">{obj.status == 3?"In-Progress":"Converted"}</div>
                        {/*<time className="cls-time">Last Update 2 days ago</time>*/}
                      </div>
                      <div className="content-quotes start-date">
                        <div className="left-block">
                          <h3>
                            <label>Starting date:</label>
                            {starting_date[1]}</h3>
                        </div>

                      </div>
                      <div className="voucher-detail-block">
                        <div className="vd-left-block">
                          <ul>
                            <li>
                              <label>Total payment that has to be received:</label>&nbsp;
                              <CurrencyFormat value={total_amount_to_be_received} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={obj.quotation.currency + ' '}/></li>
                            <li>
                              <label>Total commission:</label>&nbsp;
                              <CurrencyFormat value={obj.quotation.commission} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={obj.quotation.currency + ' '}/>&nbsp;&nbsp;<a href={"/admin/payments/invoice/"+obj.id} target="_blank">
                                <i>View Invoice</i>
                              </a></li>

                            <li>
                              <label>Total payment that has to be transferred to Vendor:</label>&nbsp;
                              <CurrencyFormat value={vendor_amount} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={obj.quotation.currency + ' '}/></li>
                            <li>
                              <label>Payment pending:</label>&nbsp;
                              <CurrencyFormat value={amount_pending} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={obj.quotation.currency + ' '}/></li>

                          </ul>
                        </div>
                        <div className="vd-right-block">
                          <label>Upload</label>
                          <div className="up-voucher">
                            <div className="file-upload">
                            <FilePond ref={ref => this.pond = ref} name="file" allowMultiple={true} allowImagePreview={false} labelIdle="Vouchers" maxFiles={5} server={{
                                url: API_ROOT + "/uploadvoucher?quote_reply_id=" + obj.quotation.id,
                                process: {
                                  headers: {'Authorization': `Bearer ${token}`},
                                  onload: (res) => {
                                    res = JSON.parse(res);
                                    if (res.result == 'success') {
                                      //change('landscape_image', res.data.id);
                                      //return res.data.id
                                      return true
                                    }
                                  }
                                }
                              }} allowReplace={false}></FilePond>
                            </div>
                            {
                              obj.quotation.voucher && <div className="view-btn-block">
                                <a href="javascript:;" className="view-link" onClick = {(e) => this.showFiles(e, 'voucher')}>View ({obj.quotation.voucher.length} {obj.quotation.voucher.length > 1?'files':'file'})</a>
                                <ul className="vc-block vc-block-voucher">
                                  {
                                    obj.quotation.voucher.map((objVoucher, indexVoucher) => {

                                      return(
                                        <li key={indexVoucher}>
                                          <div className="left-block">
                                            <a href={objVoucher.path} target="_blank">{objVoucher.name}</a>
                                            <p>Uploaded at: <Moment format="DD MMM, YY h:mm a" fromNow fromNowDuring={36000000}>{objVoucher.created_at+'-0000'}</Moment></p>
                                          </div>

                                          <div className="view-right-block">
                                            <a href="javascript:;" onClick = {(e) => this.deleteInvoiceUploads(e, objVoucher.id, 'voucher')}><img src="/assets/img/ic_delete@1x.svg" alt="" /></a>
                                          </div>
                                        </li>
                                      )
                                    })
                                  }


                                </ul>
                              </div>
                            }
                          </div>
                          <div className="up-voucher">
                            <div className="file-upload">
                            <FilePond ref={ref => this.pond = ref} name="file" allowMultiple={true} allowImagePreview={false} maxFiles={5} labelIdle="Payment proof" server={{
                                url: API_ROOT + "/uploadpaymentproof?quote_reply_id=" + obj.quotation.id,
                                process: {
                                  headers: {'Authorization': `Bearer ${token}`},
                                  onload: (res) => {
                                    res = JSON.parse(res);
                                    if (res.result == 'success') {
                                      //change('landscape_image', res.data.id);
                                      return res.data.id
                                    }
                                  }
                                }
                              }} allowReplace={false}></FilePond>
                            </div>
                              {
                                obj.quotation.paymentproof && <div className="view-btn-block">
                                  <a href="javascript:;" className="view-link" onClick = {(e) => this.showFiles(e, 'payment')}>View ({obj.quotation.paymentproof.length} {obj.quotation.paymentproof.length > 1?'files':'file'})</a>
                                    <ul className="vc-block vc-block-payment">
                                      {
                                        obj.quotation.paymentproof.map((objVoucher, indexVoucher) => {

                                          return(
                                            <li>
                                              <div className="left-block">
                                                <a href={objVoucher.path} target="_blank">{objVoucher.name}</a>
                                                <p>Uploaded at: <Moment format="DD MMM, YY h:mm a" fromNow fromNowDuring={36000000}>{objVoucher.created_at+'-0000'}</Moment></p>
                                              </div>

                                              <div className="view-right-block">
                                                <a href="#" onClick = {(e) => this.deleteInvoiceUploads(e, objVoucher.id, 'payment')}><img src="/assets/img/ic_delete@1x.svg" alt="" /></a>
                                              </div>
                                            </li>
                                          )
                                        })
                                      }
                                    </ul>
                                </div>

                              }
                          </div>

                        </div>
                      </div>

                      <div className="pv-detail">
                        <table>
                          <thead>
                            <tr>
                              <th>Receiving Date</th>
                              <th>Installment Amount</th>
                              <th>Amount Received Status</th>
                              <th>Amount Received Status</th>
                              <th>Payment Made Status</th>
                              <th>Payment Made to Supplier</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              installments && installments.map((objInvoice, indexInvoice) => {
                                var receiving_date = yyyymmdd(mysqlDateToJavascript(objInvoice.installment_date));
                                var payment_received_date = objInvoice.payment_received_date ? yyyymmdd(mysqlDateToJavascript(objInvoice.payment_received_date)) : null;
                                var payment_made_date = objInvoice.payment_received_date ? yyyymmdd(mysqlDateToJavascript(objInvoice.payment_received_date)) : null;
                                return (<tr key={indexInvoice}>
                                  <td>{receiving_date[1]}</td>
                                  <td><CurrencyFormat value={objInvoice.installment} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={objInvoice.currency + ' '}/>
                                    <span className="info"></span>
                                  </td>
                                  <td>{ payment_received_date == null ? "--" : "Received on " + payment_received_date[1] }</td>
                                  {(objInvoice.payment_received !== 0) && <td>Payment marked</td>}
                                  {
                                    (objInvoice.payment_received === 0) && <td>
                                        <button className="btn btn-primary" onClick={(e) => this.markPaymentReceived(e, objInvoice.id, obj.quotation.quote_id)}>Mark Payment</button>
                                      </td>
                                  }
                                  <td>{ payment_made_date == null ? "--" : "Payment made on " + payment_made_date[1] }</td>
                                  {(objInvoice.payment_made !== 0) && <td>Payment Made</td>}
                                  {
                                    (objInvoice.payment_made === 0) && <td>
                                        <button className="btn btn-primary" onClick={(e) => this.markPaymentMade(e, objInvoice.id)}>Mark Payment</button>
                                      </td>
                                  }
                                </tr>)
                              })
                            }

                          </tbody>

                        </table>
                      </div>


                      <div className="qutoes-block-bottom">
                        <div className="quote-user">
                          <figure>
                            {obj.quotation.profile_image && <img src={obj.quotation.profile_image} alt = ""/>}
                          </figure>
                          <figcaption>
                            <label>{obj.quotation.trip_advisor}</label>
                          </figcaption>
                        </div>
                        <div className="payment-coment-block">
                          <a rel="noopener noreferrer" href={"/admin/quotes/follow_up/detail/"+obj.trip_id+"?section=comments"} target="_blank" className="outline-btn">Comment</a>
                          <QuoteNotesNew trip_id = {obj.trip_id} type="button" />
                        </div>
                      </div>
                    </div>)
                  })
                }
                {

                  !this.state.fetchingRecords && (!this.state.records || this.state.records.length <= 0)  &&
                  <div className="card info-card card-borderless tab-ui-block shadow-card quotation-follow">
                    <div className="quotes-unavailable">
                      <figure className="icon-block"><ion-icon name="list-box"></ion-icon></figure>
                      <figcaption>
                        <p>There are no quotes under payments section</p>
                      </figcaption>
                    </div>
                  </div>
                }
              </div>
            </div>

            {/*<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <div className="card info-card card-borderless tab-ui-block shadow-card filter-block-right">
                <div className="head-block-filter">
                  <h2>Filters</h2>
                  <a href="javascript:void(0);">CLEAR ALL</a>
                </div>
                <div className="form-block">
                  <div className="form-group">
                    <label>Destination</label>
                    <div className="select2-style-custom">
                      <select className="full-width destination-select" data-init-plugin="select2">
                        <option></option>
                        <option>Switzerland</option>
                        <option>Goa</option>
                        <option>Dubai</option>
                        <option>Shimla</option>
                        <option>Manali</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Month of Travel</label>
                    <div className="select2-style-custom">
                      <select className="full-width month-select" data-init-plugin="select2">
                        <option></option>
                        <option value='1'>Janaury</option>
                        <option value='2'>February</option>
                        <option value='3'>March</option>
                        <option value='4'>April</option>
                        <option value='5'>May</option>
                        <option value='6'>June</option>
                        <option value='7'>July</option>
                        <option value='8'>August</option>
                        <option value='9'>September</option>
                        <option value='10'>October</option>
                        <option value='11'>November</option>
                        <option value='12'>December</option>
                      </select>
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Payment Stage</label>
                    <div className="select2-style-custom">
                      <select className="full-width payment-select" data-init-plugin="select2">
                        <option></option>
                        <option value='1'>Janaury</option>
                        <option value='2'>February</option>
                        <option value='3'>March</option>
                        <option value='4'>April</option>
                        <option value='5'>May</option>
                        <option value='6'>June</option>
                        <option value='7'>July</option>
                        <option value='8'>August</option>
                        <option value='9'>September</option>
                        <option value='10'>October</option>
                        <option value='11'>November</option>
                        <option value='12'>December</option>
                      </select>
                    </div>
                  </div>

                  <div className="submit-filter">
                    <input type="submit" className="btn btn-complete" value="Apply Filters"/>
                  </div>
                </div>
              </div>
            </div>*/}
          </div>
        </div>
      </div>
    </div>);
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    /*payments: state.quote.payments
      ? [...state.quote.payments]
      : null*/
    payments: null
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPayments: (data) => {
      return dispatch(fetchPayments(data));
    },
    fetchPaymentsSuccess: (data) => {
      return dispatch(fetchPaymentsSuccess(data));
    },
    markPaymentReceived: (data) => {
      return dispatch(markPaymentReceived(data));
    },
    markPaymentMade: (data) => {
      return dispatch(markPaymentMade(data));
    },
    deleteInvoiceUploads: (data) => {
      return dispatch(deleteInvoiceUploads(data));
    }

  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(PaymentsList);
