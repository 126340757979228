import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import Header from './Header';
import LeftNavigation from './LeftNavigation';
import { PermissibleRender } from '@brainhubeu/react-permissible';

import {ROUTES} from '../../routesList';
import Dashboard from './Dashboard';
import Logout from './Logout';
import NewQuotesList from './quotes/NewQuotesList';
import AllQuotesList from './quotes/AllQuotesList';
import DuringStayQuotes from './quotes/DuringStayQuotes';
import PostStayQuotes from './quotes/PostStayQuotes';
import FollowUpQuotesList from './quotes/FollowUpQuotesList';
import QuoteDetail from './quotes/QuoteDetail';
import FollowUpQuoteDetail from './quotes/FollowUpQuoteDetail';
import Calendar from './calendar/Calendar';
import QuoteForm from './quotes/QuoteForm';
import Payments from './payments/Payments';
import Stats from './stats/Stats';
import Invoice from './payments/Invoice';
import UnverifiedLeads from './quotes/UnverifiedLeads';
import UnassignedLeads from './quotes/UnassignedLeads';
import VerifiedLeads from './quotes/VerifiedLeads';
import ChangePassword from './ChangePassword';
import RejectedLeads from './quotes/RejectedLeads';
import { ToastContainer } from 'react-toastify';
import ScriptLoader from 'react-script-loader-hoc';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';


class Layout extends Component {
  constructor(props){
    super(props);
    this.state = {
      permissions: props.permissions,
      user: props.user
    }
  }

  componentWillMount() {
  }

	render() {
    return (
      <div className="main-wrapper">
        <ToastContainer autoClose={5000} />
        <LeftNavigation  permissions = {this.state.permissions} />
        <div className="page-container">
          <Header />
          <div className = "page-content-wrapper">
            <Route exact path={ROUTES.DASHBOARD.URL} component={Dashboard} />
            {/*<Route  path={ROUTES.ADMIN.URL} component={Dashboard} />
            <Route  path={ROUTES.ADMIN_DASHBOARD.URL} component={Dashboard} />*/}
            <PermissibleRender userPermissions={this.state.permissions} requiredPermissions={['manage_quotation_followup', 'view_quotation']} >
              <Route  path={ROUTES.QUOTE_DETAIL.DETAIL.URL} component={QuoteDetail} />
            </PermissibleRender>
            <PermissibleRender userPermissions={this.state.permissions} requiredPermissions={['create_quotation']} >
              <Route  path={ROUTES.QUOTE_FORM.ADD.URL} component={QuoteForm} />
              <Route  path={ROUTES.QUOTE_FORM.EDIT.URL} component={QuoteForm} />
            </PermissibleRender>
            <PermissibleRender userPermissions={this.state.permissions} requiredPermissions={['manage_verified_leads']} >
              <Route  path={ROUTES.QUOTES.UNVERIFIED.LIST.URL} component={UnverifiedLeads} />
            </PermissibleRender>
            <PermissibleRender userPermissions={this.state.permissions} requiredPermissions={['manage_unverified_leads']} >
              <Route  path={ROUTES.QUOTES.VERIFIED.LIST.URL} component={VerifiedLeads} />
            </PermissibleRender>
            <PermissibleRender userPermissions={this.state.permissions} requiredPermissions={['manage_rejected_leads']} >
              <Route  path={ROUTES.QUOTES.REJECTED.LIST.URL} component={RejectedLeads} />
            </PermissibleRender>
            <PermissibleRender userPermissions={this.state.permissions} requiredPermissions={['manage_new_quotes']} >
              <Route  path={ROUTES.QUOTES.NEW.LIST.URL} component={NewQuotesList} />
            </PermissibleRender>
            <PermissibleRender userPermissions={this.state.permissions} requiredPermissions={['manage_quotation_followup']} >
              <Route  path={ROUTES.QUOTES.FOLLOW_UP.LIST.URL} component={FollowUpQuotesList} />
            </PermissibleRender>
            <PermissibleRender userPermissions={this.state.permissions} requiredPermissions={['manage_quotation_followup']} >
              <Route  path={ROUTES.QUOTE_FOLLOW_UP_DETAIL.DETAIL.URL} component={FollowUpQuoteDetail} />
            </PermissibleRender>
            <PermissibleRender userPermissions={this.state.permissions} requiredPermissions={['manage_analytics']} >
              <Route  path={ROUTES.STATS.URL} component={Stats} />
            </PermissibleRender>
            <PermissibleRender userPermissions={this.state.permissions} requiredPermissions={['manage_calendar']} >
              <Route  path={ROUTES.CALENDAR.URL} component={Calendar} />
            </PermissibleRender>
            <PermissibleRender userPermissions={this.state.permissions} requiredPermissions={['manage_payments_vouchers']} >
              <Route  path={ROUTES.PAYMENT.URL} component={Payments} />
            </PermissibleRender>
            <PermissibleRender userPermissions={this.state.permissions} requiredPermissions={['manage_all_quotes']} >
              <Route  path={ROUTES.QUOTES.ALL_QUOTES.LIST.URL} component={AllQuotesList} />
            </PermissibleRender>
            <PermissibleRender userPermissions={this.state.permissions} requiredPermissions={['create_invoice']} >
              <Route  path={ROUTES.INVOICE.URL} component={Invoice} />
            </PermissibleRender>
            <PermissibleRender userPermissions={this.state.permissions} requiredPermissions={['manage_during_stay']} >
              <Route path={ROUTES.QUOTES.DURING_STAY.LIST.URL} component={DuringStayQuotes} />
            </PermissibleRender>
            <PermissibleRender userPermissions={this.state.permissions} requiredPermissions={['manage_post_stay']} >
              <Route path={ROUTES.QUOTES.POST_STAY.LIST.URL} component={PostStayQuotes} />
            </PermissibleRender>
            <Route path={ROUTES.QUOTES.UNASSIGNED_LEADS.LIST.URL} component={UnassignedLeads} />
            <Route  path={ROUTES.CHANGE_PASSWORD.URL} component={ChangePassword} />
            <Route  path={ROUTES.LOGOUT.URL} component={Logout} />
          </div>
        </div>
      </div>
    );
	}
}
export default ScriptLoader('/pages/js/pages.js')(Layout);
