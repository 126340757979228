import React, {Component} from 'react';
import {Route, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import queryString from 'query-string';
import Moment from 'react-moment';
import 'moment-timezone';
import $ from 'jquery';
import {getQuoteInvoiceDetail, fetchSettings} from '../../../actions/quotes';
import CurrencyFormat from 'react-currency-format';
import {objToQueryParams, formatDayNight, mysqlDateToJavascript, yyyymmdd, API_ROOT} from '../../../constants';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class Invoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quote_id: props.match.params.quote_id,
      record: null,
      inclusions: null,
      settings: null
    };
  }

  componentDidMount() {
    $("body").addClass('nav-webe-hide');
    this.fetchRecord();
    this.props.fetchSettings({key: 'bank_details,invoice_note'}).then((response) => {
      if(response.value.data.result === 'success'){
        this.setState({settings: response.value.data.payload});
      }
    })
  }

  componentDidUpdate() {
    /*$(".view-link").click(function(){
          $(".vc-block").toggleClass("active");
      })*/
  }

  fetchRecord() {
    this.props.getQuoteInvoiceDetail({quote_id: this.state.quote_id}).then((response) => {
      if (response.value.data.result === 'success') {
        var record = response.value.data.payload;
        this.setState({record: response.value.data.payload});
        //format inclusions
        if (record && record.quotation && record.quotation[0].inclusion && record.quotation[0].inclusion.length > 0) {
          for (var i = 0; i < record.quotation[0].inclusion.length; i++) {
            var total_inclusions = record.quotation[0].inclusion[i].data.length;
            var show_in_inclusions = '';
            var show_in_exclusions = '';
            var flag_inclusion_status = '';
            for (var k = 0; k < record.quotation[0].inclusion[i].data.length; k++) {
              if (record.quotation[0].inclusion[i].data[k].radio == 'yes') {
                show_in_inclusions = 'yes';
              } else if (record.quotation[0].inclusion[i].data[k].radio == 'no') {
                show_in_exclusions = 'yes';
              }
              record.quotation[0].inclusion[i] = Object.assign(record.quotation[0].inclusion[i], {
                show_in_inclusions: show_in_inclusions,
                show_in_exclusions: show_in_exclusions
              });
            }
          }
        }
        this.setState({inclusions: record.quotation[0].inclusion});
        console.log('eeeeeee', record.quotation[0].inclusion);
      }
    })
  }
  componentWillReceiveProps(nextProps) {}

  render() {
    return (<div className="dashboard-content">
      <div className="content sm-gutter">
        <div className="container-fluid p-t-0 p-b-25 sm-padding-10 new-quotes payment-vouchers">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 new-quotes-left">
              <div className="header-block-ui no-border">
                <h3>Invoice Preview</h3>
              </div>
              <div className="card info-card card-borderless tab-ui-block shadow-card">
                <div className="idtext-block">
                  <div className="id-left-block">
                    <h4>TRIP ID {this.state.record && this.state.record.query && this.state.record.query[0].trip_id}</h4>
                  </div>

                  <div className="id-right-block">
                    <ul className="btn-block payment-coment-block">
                      <li>
                        <a href="javascript:;" className="fill-btn">
                          Download Invoice
                        </a>
                      </li>
                      <li>
                        <a href="javascript:;" className="outline-btn">Send Invoice</a>
                      </li>
                    </ul>
                  </div>

                </div>
                <div className="invoice-detail">
                  <div className="invoice-ui in-payment-detail">
                    <div className="content-quotes">
                      <h3>Payment Details</h3>
                    </div>
                    <div className="currency-block">
                      {
                        this.state.record && <table>
                            <thead>
                              <tr>
                                <th colspan="7">
                                  <label>
                                    <strong>Currency : {this.state.record.quotation && this.state.record.quotation[0].currency}</strong>
                                  </label>
                                </th>
                              </tr>
                              <tr>
                                <th>Flight Cost</th>
                                <th>Visa Cost</th>
                                <th>Land Package Cost</th>
                                <th>Commission</th>
                                <th>Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <p><CurrencyFormat value={this.state.record.quotation && this.state.record.quotation[0].flight_cost} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={this.state.record.quotation && this.state.record.quotation[0].currency + ' '}/></p>
                                </td>
                                <td>
                                  <p><CurrencyFormat value={this.state.record.quotation && this.state.record.quotation[0].visa_cost} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={this.state.record.quotation && this.state.record.quotation[0].currency + ' '}/></p>
                                </td>
                                <td>
                                  <p><CurrencyFormat value={this.state.record.quotation && this.state.record.quotation[0].package_cost} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={this.state.record.quotation && this.state.record.quotation[0].currency + ' '}/></p>
                                </td>
                                <td>
                                  {this.state.record.quotation &&
                                  <p><CurrencyFormat value={parseInt(this.state.record.quotation[0].total_package_price) - parseInt(this.state.record.quotation[0].flight_cost?this.state.record.quotation[0].flight_cost:0) - parseInt(this.state.record.quotation[0].visa_cost?this.state.record.quotation[0].visa_cost:0) - parseInt(this.state.record.quotation[0].package_cost?this.state.record.quotation[0].package_cost:0)} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={this.state.record.quotation[0].currency + ' '}/></p>
                                  }
                                </td>
                                <td>
                                  {
                                    this.state.record.quotation &&
                                    <p><CurrencyFormat value={this.state.record.quotation[0].total_package_price} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={this.state.record.quotation[0].currency + ' '}/></p>
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </table>
                      }
                    </div>
                    <div className="installment">
                      <table>
                        <thead>
                          <tr>
                            <th colspan="4">
                              <strong>No. of instalments : {this.state.record && this.state.record.quotation && this.state.record.quotation[0].invoice.no_of_installments}</strong>
                            </th>
                          </tr>
                          <tr>
                            <th>Installment
                            </th>
                            <th>Installment Amount</th>
                            <th>Payment</th>
                            <th>Due Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.state.record && this.state.record.quotation && this.state.record.quotation[0].invoice.installments.map((obj, index) => {
                              return (<tr>
                                <td>{formatDayNight(index + 1)}</td>
                                <td><CurrencyFormat value={obj.installment} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={this.state.record.quotation[0].currency + ' '}/></td>
                                <td>To Travalot</td>
                                <td>
                                  <Moment format="DD MMM, YYYY">{obj.installment_date}</Moment>
                                </td>
                              </tr>)
                            })
                          }

                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="invoice-ui in-booking">
                    <div className="booking-block">
                      <div className="content-quotes">
                        <h3>Booking Summary</h3>
                      </div>
                      <table>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Destination</th>
                            <th>Travel Dates</th>
                            <th>Travellers</th>
                            <th>Duration</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{this.state.record && this.state.record.query && this.state.record.query[0].name}</td>
                            <td>{this.state.record && this.state.record.query && this.state.record.query[0].destination}</td>
                            <td>{
                                this.state.record && this.state.record.quotation && <span>
                                    <Moment format="DD MMM, YYYY">{this.state.record.quotation[0].start_date}</Moment> <i>to</i> <Moment format="DD MMM, YYYY" add={{
                                        days: (this.state.record.quotation[0].days - 1)
                                      }}>{this.state.record.quotation[0].start_date}</Moment>
                                  </span>
                              }</td>
                            <td>{
                                this.state.record && this.state.record.query && <span>{this.state.record.query[0].adults} {this.state.record.query[0].adults == 1?"Adult":"Adults"}, {this.state.record.query[0].children} {this.state.record.query[0].children == 1?"Child":"Children"}</span>
                              }</td>
                            <td>{
                                this.state.record && this.state.record.quotation && <span>{this.state.record.quotation[0].days} Days & {this.state.record.quotation[0].nights} {this.state.record.quotation[0].nights == 1?"Night":"Nights"}</span>
                              }</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {
                      this.state.record && this.state.record.quotation && this.state.record.quotation[0].hotels && <div className="hotel-info-block">
                          <div className="content-quotes">
                            <h3>Hotel Details</h3>
                          </div>
                          <div className="hotel-details">
                            <table>
                              <thead>
                                <tr>
                                  <th>City</th>
                                  <th>Nights</th>
                                  <th>Hotel Name</th>
                                  <th>Category</th>
                                  <th>Room Type</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  this.state.record.quotation[0].hotels.map((obj, index) => {
                                    return (<tr>
                                      <td>{obj.hotel_city}</td>
                                      <td>{obj.hotel_label_nights}</td>
                                      <td>{obj.hotel_name}</td>
                                      <td>
                                        {Array.from(Array(parseInt(obj.hotel_rating)), () => 0).map(() => (<ion-icon name="star"></ion-icon>))}
                                      </td>
                                      <td>{obj.hotel_room_type}</td>
                                    </tr>)
                                  })
                                }

                              </tbody>
                            </table>
                          </div>
                        </div>
                    }
                    <div className="flight-info-block">
                      <div className="content-quotes">
                        <h3>Flight Details</h3>
                      </div>
                      <div className="flight-content">
                        <p dangerouslySetInnerHTML={{__html: this.state.record && this.state.record.quotation && this.state.record.quotation[0].flight_details}} />
                      </div>
                    </div>
                    <div className="cab-info-block">
                      <div className="content-quotes">
                        <h3>Cab Details</h3>
                      </div>
                      <div className="flight-content">
                        <p dangerouslySetInnerHTML={{__html: this.state.record && this.state.record.quotation && this.state.record.quotation[0].cab_details}} />
                      </div>
                    </div>
                  </div>
                  <div className="invoice-ui in-payment-detail">
                    <div className="content-quotes">
                      <h3>Inclusion/Exclusion
                      </h3>
                    </div>
                    <div className="ie-data">
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <table>
                                {
                                  this.state.inclusions && this.state.inclusions.map((obj, index) => {
                                    return (obj.show_in_inclusions == 'yes' && <tr>
                                      <td>
                                        <p className="heading">
                                          <ion-icon name="checkmark"></ion-icon>{obj.category}</p>
                                        <ul>
                                          {

                                            obj.data && obj.data.length > 0 && obj.data.map((obj1, index1) => {

                                              return (obj1.inclusion == 1 && <li>{obj1.title}{obj1.comments && <p>{obj1.comments}</p>}</li>)
                                            })

                                          }
                                        </ul>
                                      </td>
                                    </tr>)
                                  })
                                }

                              </table>
                            </td>
                            <td>
                              <table className="exclusion-listing">
                                {
                                  this.state.inclusions && this.state.inclusions.map((obj, index) => {
                                    return (obj.show_in_exclusions == 'yes' && <tr>
                                      <td>
                                        <p className="heading">
                                          <ion-icon name="close"></ion-icon>{obj.category}</p>
                                        <ul>
                                          {

                                            obj.data && obj.data.length > 0 && obj.data.map((obj1, index1) => {

                                              return (obj1.inclusion == 0 && <li>{obj1.title}{obj1.comments && <p>{obj1.comments}</p>}</li>)
                                            })

                                          }
                                        </ul>
                                      </td>
                                    </tr>)
                                  })
                                }

                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="invoice-ui deliver-block">
                    <div className="content-quotes">
                      <h3>Deliverables
                      </h3>
                    </div>
                    <div className="deliver-content">
                      <table>
                        <thead>
                          <tr>
                            <th>From Agent (within {this.state.record && this.state.record.quotation && this.state.record.quotation[0].invoice.days_after_payment_received}
                              Days of Payment)</th>
                            <th>
                              Document(s) required from Traveler</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style={{
                                verticalAlign: 'top'
                              }}>
                              <table>
                                {

                                this.state.record && this.state.record.quotation && this.state.record.quotation[0].invoice.travel_agent_deliverable && this.state.record.quotation[0].invoice.travel_agent_deliverable.length > 0 && this.state.record.quotation[0].invoice.travel_agent_deliverable.map((obj,index) => {
                                  return(
                                    <tr>
                                      <td>{obj.title}</td>
                                    </tr>
                                  )
                                })

                                }
                              </table>
                            </td>
                            <td style={{
                                verticalAlign: 'top'
                              }}>
                              <table>
                                {

                                this.state.record && this.state.record.quotation && this.state.record.quotation[0].invoice.travelor_document && this.state.record.quotation[0].invoice.travelor_document.length > 0 && this.state.record.quotation[0].invoice.travelor_document.map((obj,index) => {
                                  return(
                                    <tr>
                                      <td>{obj.title}</td>
                                    </tr>
                                  )
                                })

                                }
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="invoice-ui deliver-block">
                    <div className="content-quotes">
                      <h3>NEFT Transfer Details
                      </h3>
                    </div>
                    <div className="payment-table">
                      <p>Option 1: To make payment via RTGS, NEFT, IMPS, cheque or demand draft use the bank details Listed below:</p>
                      <table className="">
                        <tbody>
                          {
                            this.state.settings && this.state.settings.bank_details && this.state.settings.bank_details.map((obj, index) => {
                              return(
                                <tr>
                                  <td>{obj.label}</td>
                                  <td>{obj.field_value}</td>
                                </tr>
                              )
                            })
                          }

                        </tbody>
                      </table>
                    </div>

                    <div className="descrition-block">
                      <p className="mb-20">
                        Please add booking ID: {this.state.record && this.state.record.query && this.state.record.query[0].trip_id} in remarks and email us the screenshot or transaction reciept to track your payment confirmation.</p>
                      <p>
                        Note: {this.state.settings && this.state.settings.invoice_note }</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <div className="card info-card card-borderless tab-ui-block shadow-card filter-block-right">
                <div className="head-block-filter">
                  <h2>Traveller Details</h2>
                </div>
                <div className="td-block">
                  <table>
                    <tbody>
                      <tr>
                        <td className="user-name" colspan="2">
                          <span className="td-user quote-user"><figure style = {{width: 50+'px', height: 50+'px'}}><img src="assets/img/profiles/avatar.jpg" alt=""/></figure></span>
                          <p>{this.state.record && this.state.record.query && this.state.record.query[0].name}</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Travellers</td>
                        <td>
                          {this.state.record && this.state.record.query && <p>{this.state.record.query[0].adults+" "+(this.state.record.query[0].adults == 1?'Adult':'Adults')+" & "+ this.state.record.query[0].children+" "+(this.state.record.query[0].children == 1?'Child': 'Children')}</p>}
                        </td>
                      </tr>
                      <tr>
                        <td>Destination</td>
                        <td>{this.state.record && this.state.record.query && this.state.record.query[0].destination}</td>
                      </tr>
                      <tr>
                        <td>Duration</td>
                        <td>{this.state.record && this.state.record.quotation && this.state.record.quotation[0].days}</td>
                      </tr>
                      <tr>
                        <td>Starting Date</td>
                        <td>{this.state.record && this.state.record.quotation && <Moment format="DD MMM, YYYY">{this.state.record.quotation[0].start_date}</Moment>}</td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>);
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    payments: state.quote.payments
      ? [...state.quote.payments]
      : null
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getQuoteInvoiceDetail: (data) => {
      return dispatch(getQuoteInvoiceDetail(data));
    },
    fetchSettings: (params) => {
      return dispatch(fetchSettings(params));
    }

  };
}

export default connect(null, mapDispatchToProps)(Invoice);
