import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
export const APP_VERSION = '6';

export const API_ROOT = 'https://api.islandhop.in';
export const APP_DOMAIN = 'travalot.com';
export const BASE_URL = 'https://api.islandhop.in';
export const BASE_URL_LIVE = 'https://api.islandhop.in';


export const WEBSITE_TITLE = "Travalot";

export function clearSession(){
  localStorage.removeItem('jwtToken');
  localStorage.removeItem('persist:root');
  window.location = '/';
}

export const QUERY_STATUSES = ["New Quote", "In-Progress", "Rejected","Invoice Generated", "Cancelled", "Converted", "Booking Cancelled"];
export function verifyAndUpdateAppVersion(){
  // Display App Version
  const version = localStorage.getItem("APP_VERSION");
  if(version === null || version != APP_VERSION){
    localStorage.setItem("APP_VERSION", APP_VERSION);
    clearSession();
  }
}

export const GOOGLE_MAP_API_KEY = 'AIzaSyA1r4lhEqwZh6kxkMTVLPtIGByzeh5JQuA';

export function validateEmail(email) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return (true)
  }
  return (false)
}

export function objToQueryParams(obj) {
  var str = [];
  for (var p in obj)
    //if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    //}
  return str.join("&");
}

export function stripHtmlTags(str, length) {
  if ((str === null) || (str === '')) {
    return false;
  } else {
    str = str.toString();
    str = str.replace(/<[^>]*>/g, '')
    if (length && length > 0 && str.length > length) {
      str = str.substr(0, length);
      str = str + "...";
    }
  }
  return str;
}

export const hotelsRatingArray = [
  {
    id: 1,
    name: 1
  },
  {
    id: 2,
    name: 2
  },
  {
    id: 3,
    name: 3
  },
  {
    id: 4,
    name: 4
  },
  {
    id: 5,
    name: 5
  },
  {
    id: 6,
    name: 6
  },
  {
    id: 7,
    name: 7
  }
]

export const monthsArray = [
  {
    id: 1,
    name: 'Jan',
    fullName: 'January'
  }, {
    id: 2,
    name: 'Feb',
    fullName: 'February'
  }, {
    id: 3,
    name: 'Mar',
    fullName: 'March'
  }, {
    id: 4,
    name: 'Apr',
    fullName: 'April'
  }, {
    id: 5,
    name: 'May',
    fullName: 'May'
  }, {
    id: 6,
    name: 'Jun',
    fullName: 'June'
  }, {
    id: 7,
    name: 'Jul',
    fullName: 'July'
  }, {
    id: 8,
    name: 'Aug',
    fullName: 'August'
  }, {
    id: 9,
    name: 'Sep',
    fullName: 'September'
  }, {
    id: 10,
    name: 'Oct',
    fullName: 'October'
  }, {
    id: 11,
    name: 'Nov',
    fullName: 'November'
  }, {
    id: 12,
    name: 'Dec',
    fullName: 'December'
  }
];
export const daysArray = [
  {
    id: 1,
    name: 'Mon',
    fullName: 'Monday'
  }, {
    id: 2,
    name: 'Tue',
    fullName: 'Tuesday'
  }, {
    id: 3,
    name: 'Wed',
    fullName: 'Wednesday'
  }, {
    id: 4,
    name: 'Thu',
    fullName: 'Thursday'
  }, {
    id: 5,
    name: 'Fri',
    fullName: 'Friday'
  }, {
    id: 6,
    name: 'Sat',
    fullName: 'Saturday'
  }, {
    id: 7,
    name: 'Sun',
    fullName: 'Sunday'
  }
];
export function formatDate(milliseconds, formatType) {
  var monthNames = new Array("Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec");

  var today = new Date(milliseconds);
  var cDate = today.getDate();
  var cMonth = today.getMonth();
  var cYear = today.getFullYear();

  var cHour = today.getHours();
  var ampm = cHour >= 12
    ? 'pm'
    : 'am';
  cHour = cHour % 12;
  cHour = cHour
    ? cHour
    : 12; // the hour '0' should be '12'
  if (cHour < 10) {
    cHour = "0" + cHour;
  }
  var cMin = today.getMinutes();
  if (cMin < 10) {
    cMin = "0" + cMin;
  }
  var cSec = today.getSeconds();
  if (cSec < 10) {
    cSec = "0" + cSec;
  }
  if (formatType === 'date') {
    return monthNames[cMonth] + " " + cDate + ", " + cYear;
  } else if (formatType === 'time') {
    return cHour + ":" + cMin + ":" + cSec + ' ' + ampm;
  } else {
    return monthNames[cMonth] + " " + cDate + ", " + cYear + " " + cHour + ":" + cMin + ":" + cSec + ' ' + ampm;
  }

}

export function convertMS(milliseconds) {
  var day,
    hour,
    minute,
    seconds;
  seconds = Math.floor(milliseconds / 1000);
  minute = Math.floor(seconds / 60);
  seconds = seconds % 60;
  hour = Math.floor(minute / 60);
  minute = minute % 60;
  day = Math.floor(hour / 24);
  hour = hour % 24;
  return {day: day, hour: hour, minute: minute, seconds: seconds};
}

export function formatDateDisplay(value, unit, suffix, date, defaultFormatter, dateFormat) {
  if (dateFormat) {
    return formatDate(date);
  } else {
    if (unit === "second") {
      if (value < 20) {
        return "just now";
      } else {
        return value + " secs ago";
      }
    } else if (unit === "minute") {
      if (value == 1) {
        return value + " min ago";
      } else {
        return value + " mins ago";
      }
    } else if (unit === "hour") {
      if (value == 1) {
        return value + " hr ago";
      } else {
        return value + " hrs ago";
      }
    } else if (unit === "day") {
      if (value == 1) {
        return value + " day ago";
      } else {
        return value + " days ago";
      }
    } else if (unit === "week") {
      if (value == 1) {
        return value + " week ago";
      } else {
        return value + " weeks ago";
      }
    } else if (unit === "month" && value < 2) {
      if (value == 1) {
        return value + " month ago";
      } else {
        return value + " months ago";
      }
    } else {
      //return formatDate(date);
      //return d.toString("MMM dd");
      return formatDate(date, 'date');

    }
  }
}






export const MONTHS_ARRAY = [
  {
    id: '01',
    value: 'JAN'
  }, {
    id: '02',
    value: 'FEB'
  }, {
    id: '03',
    value: 'MAR'
  }, {
    id: '04',
    value: 'APR'
  }, {
    id: '05',
    value: 'MAY'
  }, {
    id: '06',
    value: 'JUN'
  }, {
    id: '07',
    value: 'JUL'
  }, {
    id: '08',
    value: 'AUG'
  }, {
    id: '09',
    value: 'SEP'
  }, {
    id: '10',
    value: 'OCT'
  }, {
    id: '11',
    value: 'NOV'
  }, {
    id: '12',
    value: 'DEC'
  }
];
export const EMAIL_OUTGOING_SERVER = [
  {
    id: '0',
    value: "via Myteambit's SMTP servers"
  }, {
    id: '1',
    value: 'via my own custom SMTP servers'
  }
];

export const EMAIL_OUTGOING_SERVER_ENCRYPTION = [
  {
    id: 'ssl',
    value: "SSL"
  }, {
    id: 'tls',
    value: 'TLS'
  }
];

export const EMAIL_OUTGOING_SERVER_AUTHENTICATION = [
  {
    id: 'plain',
    value: "Plain"
  }, {
    id: 'login',
    value: 'Login'
  }, {
    id: 'cram_md5',
    value: "CRAM MD5"
  }, {
    id: 'digest_md5',
    value: 'Digest MD5'
  }
];

export const KB_DEFAULT_LOGO_IMAGE = "/images/company-logo.png";
export const KB_DEFAULT_FAVICON_IMAGE = "/images/favicon-icon.png";



export function FromatValidationErrors(errors, error_keys) {
  Object.keys(errors).map(function(key) {
    error_keys[key] = errors[key][0];
    return key;
  });
  return error_keys;
}

export function jsUcfirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function creditCardYears() {
  var date = new Date();
  var currentYear = date.getFullYear();
  var yearsArray = [];
  for (var i = 0; i < 10; i++) {
    var year = currentYear + i;
    year = year.toString();
    var lastCharYear = year.slice(-2);
    var obj = {
      id: lastCharYear,
      value: year
    };
    yearsArray.push(obj);
  }
  return yearsArray;
}

export function currentYear() {
  var date = new Date();
  var year = date.getFullYear();
  year = year.toString();
  var lastCharYear = year.slice(-2);
  return lastCharYear;
}

export function currentMonth() {
  var d = new Date();
  var n = d.getMonth();
  n = n + 1;
  if (n < 10) {
    n = "0" + n.toString();
  }
  n = n.toString();

  return n;
}
export function normalizeNameField(value) {
  var v = value.replace(/[^a-z\s]/i, '');
  return jsUcfirst(v);

}

export function normalizeSubdomainField(value) {
  var v = value.replace(/[^a-zA-Z0-9\-]/i, '');
  return v;
}

export function normalizeCvcField(value) {
  var v = value.replace(/[^0-9\-]/i, '');
  var updated_str = v.substr(0, 4);
  return updated_str;
}

export function subdomainFieldValidation(value) {
  var error = false;
  if (!value || value.trim() === '') {
    error = 'Enter subdomain';
  } else if (value.trim().length < 2) {
    error = 'Subdomain must be at least 2 characters long';
  } else if (value.trim().length > 24) {
    error = 'Subdomain can not have more than 24 characters';
  }
  return error;
}

export function nameFieldValidation(value) {
  var error = false;
  if (!value || value.trim() === '') {
    error = 'Enter a name';
  } else if (value.trim().length < 2) {
    error = 'Name must be at least 2 characters long';
  } else if (value.trim().length > 24) {
    error = 'Name can not have more than 24 characters';
  }
  return error;
}

export function cvcValidation(value) {
  var error = false;
  if (!value || value.trim() === '') {
    error = 'Required';
  } else if (value.trim().length < 3) {
    error = '3-4 digits';
  } else if (value.trim().length > 4) {
    error = '3-4 digits';
  }
  return error;
}

export function confirmDelete(actionFunction) {
  confirmAlert({
    title: 'Confirm to delete',
    message: 'Are you sure to do this?',
    buttons: [
      {
        label: 'Cancel'
      }, {
        label: 'Confirm',
        onClick: actionFunction
      }
    ]
  });
}

/*export function confirmDialog(actionFunction) {
  confirmAlert({
    title: 'Confirm!',
    message: 'Are you sure to do this?',
    buttons: [
      {
        label: 'Cancel'
      }, {
        label: 'Confirm',
        onClick: actionFunction
      }
    ]
  });
}*/

export function _dispatch(nextState, rerender = false) {
  rerender = rerender
    ? new Date().getTime()
    : nextState.status;
  return {
    ...nextState,
    status: rerender
  }
}

export const CURRENCY = [
  {symbol: 'INR', title: '₹ Rupee'},
  {symbol: 'EUR', title: '€ Euro'},
  {symbol: 'USD', title: '$ Dollar'},
]

export const NIGHTS = [
  {value:'1', label:'1st' },
  {value:'2', label:'2nd' },
  {value:'3', label:'3rd' },
  {value:'4', label:'4th' },
  {value:'5', label:'5th' },
  {value:'6', label:'6th' },
  {value:'7', label:'7th' },
  {value:'8', label:'8th' },
  {value:'9', label:'9th' },
  {value:'10', label:'10th' },
  {value:'11', label:'11th' },
  {value:'12', label:'12th' },
  {value:'13', label:'13th' },
  {value:'14', label:'14th' },
  {value:'15', label:'15th' },
  {value:'16', label:'16th' },
  {value:'17', label:'17th' },
  {value:'18', label:'18th' },
  {value:'19', label:'19th' },
  {value:'20', label:'20th' },
  {value:'21', label:'21st' },
  {value:'22', label:'22nd' },
  {value:'23', label:'23rd' },
  {value:'24', label:'24th' },
  {value:'25', label:'25th' },
  {value:'26', label:'26th' },
  {value:'27', label:'27th' },
  {value:'28', label:'28th' },
  {value:'29', label:'29th' },
  {value:'30', label:'30th' },
  {value:'31', label:'31th' },
  {value:'32', label:'32th' },
  {value:'33', label:'33th' },
  {value:'34', label:'34th' },
  {value:'35', label:'35th' },
  {value:'36', label:'36th' },
  {value:'37', label:'37th' },
  {value:'38', label:'38th' },
  {value:'39', label:'39th' },
  {value:'40', label:'40th' },
  {value:'41', label:'41th' },
  {value:'42', label:'42th' },
  {value:'43', label:'43th' },
  {value:'44', label:'44th' },
  {value:'45', label:'45th' },
  {value:'46', label:'46th' },
  {value:'47', label:'47th' },
  {value:'48', label:'48th' },
  {value:'49', label:'49th' },
  {value:'50', label:'50th' },
  {value:'51', label:'51th' },
  {value:'52', label:'52th' },
  {value:'53', label:'53th' },
  {value:'54', label:'54th' },
  {value:'55', label:'55th' },
  {value:'56', label:'56th' },
  {value:'57', label:'57th' },
  {value:'58', label:'58th' },
  {value:'59', label:'59th' },
  {value:'60', label:'60th' },
  {value:'61', label:'61th' },
  {value:'62', label:'62th' },
  {value:'63', label:'63th' },
  {value:'64', label:'64th' },
  {value:'65', label:'65th' },
  {value:'66', label:'66th' },
  {value:'67', label:'67th' },
  {value:'68', label:'68th' },
  {value:'69', label:'69th' },
  {value:'70', label:'70th' },
  {value:'71', label:'71th' },
  {value:'72', label:'72th' },
  {value:'73', label:'73th' },
  {value:'74', label:'74th' },
  {value:'75', label:'75th' },
  {value:'76', label:'76th' },
  {value:'77', label:'77th' },
  {value:'78', label:'78th' },
  {value:'79', label:'79th' },
  {value:'80', label:'80th' },
  {value:'81', label:'81th' },
  {value:'82', label:'82th' },
  {value:'83', label:'83th' },
  {value:'84', label:'84th' },
  {value:'85', label:'85th' },
  {value:'86', label:'86th' },
  {value:'87', label:'87th' },
  {value:'88', label:'88th' },
  {value:'89', label:'89th' },
  {value:'90', label:'90th' },
  {value:'91', label:'91th' },
  {value:'92', label:'92th' },
  {value:'93', label:'93th' },
  {value:'94', label:'94th' },
  {value:'95', label:'95th' },
  {value:'96', label:'96th' },
  {value:'97', label:'97th' },
  {value:'98', label:'98th' },
  {value:'99', label:'99th' },
]

export function formatDayNight(no){
  for(var i=0; i < NIGHTS.length; i++){
    if(no == (i+1)){
      return NIGHTS[i].label;
    }
  }
  return no;
}

export const QUOTE_NUMBERS = [
  "First",
  "Second",
  "Third",
  "Fourth",
  "Fifth",
  "Sixth",
  "Seventh",
  "Eighth",
  "Ninth",
  "Tenth",
]

export const ROOM_TYPES = [
  {id: 'Standard', value: 'Standard'},
  {id: 'Super Deluxe', value: 'Super Deluxe'},
  {id: 'Deluxe', value: 'Deluxe'},
  {id: 'Luxury', value: 'Luxury'},
  {id: 'Duplex', value: 'Duplex'},
  {id: 'Executive Suite', value: 'Executive Suite'},
  {id: 'Family Suite', value: 'Family Suite'},
  {id: 'Grande Suite', value: 'Grande Suite'},
  {id: 'Houseboat', value: 'Houseboat'},
  {id: 'Others', value: 'Others'}

]

export function removeDuplicates(arr){
    let unique_array = []
    for(let i = 0;i < arr.length; i++){
        if(unique_array.indexOf(arr[i]) == -1){
            unique_array.push(arr[i])
        }
    }
    return unique_array
}

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function mysqlDateToJavascript(mysqlDate){
  var dateParts = mysqlDate.split("-");
  var jsDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2].substr(0,2));
  return jsDate;
}


export function yyyymmdd(date){
  var monthsArray = ["Jan","Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  var x = date;
  var y = x.getFullYear().toString();
  var m = (x.getMonth() + 1).toString();
  var d = x.getDate().toString();
  var monthName = monthsArray[x.getMonth()];
  (d.length == 1) && (d = '0' + d);
  (m.length == 1) && (m = '0' + m);
  var yyyymmdd = y +"-"+ m +"-"+ d;
  var format2 = d+" "+monthName+", "+y;
  var format3 = monthName+", "+y;
  var format4 = d+" "+monthName;
  return [yyyymmdd, format2, format3, format4];
}


export function isNormalInteger(str) {
    var n = Math.floor(Number(str));
    return n !== Infinity && n >= 0;
}

export function inArray(needle, haystack) {
    var length = haystack.length;
    for(var i = 0; i < length; i++) {
        if(haystack[i] == needle) return true;
    }
    return false;
}

export const ADD_NOTES_DATA = [
  {
    id: 1,
    img: 'ic_not_rechable.svg',
    label: 'Traveler not reachable',
    data: [
      {
        id:1,
        label: "Phone number doesn't work",
        info: "Followup mail will be sent, asking traveler to call back."
      },
      {
        id:2,
        label: "Couldn't talk to traveler this time, try again later",
        info: "Followup mail will be sent on your behalf asking traveler to call back."
      },
      {
        id:3,
        label: "Tried enough, leave the lead",
        info: "Trip will be hidden from the dashboard in Quotation followup stage to keep your dashboard clean."
      }
    ]
  },
  {
    id: 2,
    img: 'ic_notbook.svg',
    label: "Won't book with us",
    data: [
      {
        id: 1,
        label: "Traveler is not sure about the plan/cancelled plan",
        info: ""
      },
      {
        id: 2,
        label: "Traveler booked with someone else",
        info: ""
      }
    ]
  },
  {
    id: 3,
    img: 'ic_inprogress.svg',
    label: "Talk in progress with traveler",
    data: [
      {
        id:1,
        label: "Initial stage only - Quote not seen",
        info: ""
      },
      {
        id:2,
        label: "Getting Quote/package customized",
        info: ""
      },
      {
        id:3,
        label: "Traveler interested, but will book after few weeks ",
        info: ""
      }
    ]
  },
  {
    id: 4,
    img: 'ic_hot_lead.svg',
    label: "Traveler will finalize",
    data:[

    ]
  }

]



export const GLOBAL_ERROR = 'GLOBAL_ERROR';
export const LOCAL_ERROR = 'LOCAL_ERROR';
export const FACEBOOK_APP_ID = '308915869913933';
