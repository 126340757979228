import React, { Component, PropTypes } from 'react';
import dateFnsLocalizer from 'react-widgets-date-fns';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import Moment from 'moment';
import { mysqlDateToJavascript } from '../../constants';
import 'react-widgets/dist/css/react-widgets.css';


dateFnsLocalizer()

const renderDatePicker = ({ input, id, date, readOnly, notes, min, defaultValue, parentDivClass, fieldClass, label, type, placeholder, meta: { touched, error, invalid, warning } }) => {
    //var defaultValue = null;
    defaultValue = null;
    if(input.value){
      if(typeof input.value == 'string'){
        defaultValue = mysqlDateToJavascript(input.value);
      }else {
        defaultValue = input.value;
      }
    }
    //console.log('aaaaa', defaultValue);

    return(
      <div className={parentDivClass ? parentDivClass : ''} >
        {type != "hidden" && (<label>{label}</label>)}
        <DateTimePicker
          name={input.name}  time={false} min={min?min:false} defaultValue = {defaultValue?defaultValue:null} format="YYYY-MM-dd" className={ ` ${fieldClass ? fieldClass:' custom-field  '} ${touched && error ? ' is-invalid ':''}` }
          onChange={(newValue) => {

            input.onChange(newValue)
          }}
          onBlur={(newValue) => {
            input.onBlur(newValue)
          }}
        />
        {touched && ((error && <label className="error">{error}</label>) || (warning && <div className="invalid-feedback">{warning}</div>))}
        {
        	notes && (<div className="help-notes"><small>{notes}</small></div>)
        }
      </div>
    )
  }

export default renderDatePicker;
