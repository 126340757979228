import React, { Component } from 'react';
import { Route, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Moment from 'react-moment';
import 'moment-timezone';
import {objToQueryParams, WEBSITE_TITLE, inArray, yyyymmdd} from "../../../constants";
import { performanceReport } from '../../../actions/quotes';
import MetaTags from 'react-meta-tags';
import Loader from '../../../components/Loader';
import PerformanceBySpoc from './PerformanceBySpoc';
import PerformanceByDay from './PerformanceByDay';
import PerformanceByWeek from './PerformanceByWeek';
import PerformanceByMonth from './PerformanceByMonth';
import PerformanceByDestination from './PerformanceByDestination';
import $ from 'jquery';


class PerformanceTab extends Component {
  constructor(props){
    super(props);
    var startDate = yyyymmdd(this.props.startDate);
    var endDate = yyyymmdd(this.props.endDate);
    this.state = {
      records: null,
      selectedTab: 'day',
      fetchingReport: false,
      startDate: startDate[0],
      endDate: endDate[0],
      selectedDestination: this.props.selectedDestination,
      fetchTime: null
    }
  }

  componentDidMount() {
    $("body").addClass('nav-webe-hide');
    this.fetchPerformanceReport({type: this.state.selectedTab, from: this.state.startDate, to: this.state.endDate, destinations: this.state.selectedDestination});
  }

  fetchPerformanceReport(params){
    if(!params){
      var params = {type: this.state.selectedTab};
    }
    this.setState({fetchingReport: true});
    this.props.performanceReport(params).then((response) => {
      this.setState({fetchingReport: false});
      var records = response.value.data.payload;
      this.setState({records: records, fetchTime: new Date().getTime()});
    });
  }

  selectTab(e, tab){
    this.setState({records: null, selectedTab: tab});
    this.fetchPerformanceReport({type: tab, from: this.state.startDate, to: this.state.endDate, destinations: this.state.selectedDestination});
  }

  componentWillReceiveProps(nextProps){
    var startDateOld = yyyymmdd(this.props.startDate);
    var endDateOld = yyyymmdd(this.props.endDate);
    var startDate = yyyymmdd(nextProps.startDate);
    var endDate = yyyymmdd(nextProps.endDate);
    if(startDateOld[0] != startDate[0] || endDateOld[0] != endDate[0] || this.props.selectedTab != nextProps.selectedTab || this.props.selectedDestination.length != nextProps.selectedDestination.length){
      this.setState({startDate: startDate[0], endDate: endDate[0], selectedDestination: nextProps.selectedDestination});
      this.fetchPerformanceReport({type: this.state.selectedTab, from: startDate[0], to: endDate[0], destinations: nextProps.selectedDestination});
    }
  }

  render() {
    return (
      <div className="tab-pane active" id="performance">
        <div className="filter-block">
          <label className="label-heading">Select filter</label>
          <ul>
            <li>
              <input type="radio" name="filter" id="by_spoc" onClick = {(e) => this.selectTab(e, 'spoc')}  />
              <label htmlFor="#by_spoc">By spoc</label>
            </li>
            <li>
              <input type="radio" name="filter" id="by_destination" onClick = {(e) => this.selectTab(e, 'destination')}/>
              <label htmlFor="#by_destination">By Destination</label>
            </li>
            <li>
              <input type="radio" name="filter" id="by_day" defaultChecked = {true} onClick = {(e) => this.selectTab(e, 'day')} />
              <label htmlFor="#by_day">By Day</label>
            </li>
            <li>
              <input type="radio" name="filter" id="by_week" onClick = {(e) => this.selectTab(e, 'week')} />
              <label htmlFor="#by_week">By Week</label>
            </li>
            <li>
              <input type="radio" name="filter" id="by_month" onClick = {(e) => this.selectTab(e, 'month')} />
              <label htmlFor="#month">By Month</label>
            </li>
          </ul>
        </div>
        <div className="pv-detail table-block">
          <h3 className="table-heading">Review Activity</h3>
          <div className="table-space">
            <div className="table-left-block">
              <table>
                <thead>
                  <tr>
                    <th>Metric</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="sub-heading">Primary Metric</td>
                  </tr>
                  <tr>
                    <td>Leads Given</td>
                  </tr>
                  <tr>
                    <td>Quoted Trips</td>
                  </tr>
                  <tr>
                    <td>Converted Trips</td>
                  </tr>
                  <tr>
                    <td className="sub-heading">Miscellaneous Metric</td>
                  </tr>
                  <tr>
                    <td>No. of Notes Added</td>
                  </tr>
                  <tr>
                    <td>Comments by Traveler</td>
                  </tr>
                  <tr>
                    <td>Comments by Agent</td>
                  </tr>
                </tbody>
              </table>
            </div>
            {
              this.state.selectedTab == 'spoc' && <PerformanceBySpoc fetchTime = {this.state.fetchTime} fetchingReport = {this.state.fetchingReport} records = {this.state.records} />
            }
            {
              this.state.selectedTab == 'day' && <PerformanceByDay fetchTime = {this.state.fetchTime} fetchingReport = {this.state.fetchingReport} records = {this.state.records} />
            }
            {
              this.state.selectedTab == 'week' && <PerformanceByWeek fetchTime = {this.state.fetchTime} fetchingReport = {this.state.fetchingReport} records = {this.state.records} />
            }
            {
              this.state.selectedTab == 'month' && <PerformanceByMonth fetchTime = {this.state.fetchTime} fetchingReport = {this.state.fetchingReport} records = {this.state.records} />
            }
            {
              this.state.selectedTab == 'destination' && <PerformanceByDestination fetchTime = {this.state.fetchTime} fetchingReport = {this.state.fetchingReport} records = {this.state.records} />
            }
          </div>
        </div>
      </div>
    );
	}
}



const mapDispatchToProps = (dispatch) => {
  return {
		performanceReport: (data) => {
      return dispatch(performanceReport(data));
    }
  }
}
export default connect(null, mapDispatchToProps)(PerformanceTab);
