import React, { Component } from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const config = {
  //extraPlugins:['autogrow'],
  toolbar: [ 'bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote' ],
  

}
const renderCKEdtior = ({ input, id, readOnly, notes, parentDivClass, fieldClass, label, type, placeholder, meta: { touched, error, invalid, warning } }) => {

return (
     <div className={parentDivClass ? parentDivClass : ''} >
       {type != "hidden" && (<label>{label}</label>)}
       <CKEditor
         {...input}  content={input.value} activeClass={ ` ${fieldClass ? fieldClass:' ckeditor-container  '} ${touched && error ? '  is-invalid ':''}` }
          config={config}
          editor={ ClassicEditor }
          data={input.value}
          onInit={ editor => {
              // You can store the "editor" and use when it is needed.
              //console.log( 'Editor is ready to use!', editor );
          } }
          onChange={ ( event, editor ) => {
              //const data = editor.getData();
              input.onChange(editor.getData());
              console.log( editor.getData()  );
          } }
          onBlur={ editor => {
            //input.onBlur(editor.getData());
            //console.log( 'Blur.', editor.getData() );
          } }
          onFocus={ editor => {
              //console.log( 'Focus.', editor );
          } }
      />
      {touched && ((error && <label className="error">{error}</label>) || (warning && <div className="invalid-feedback">{warning}</div>))}
      {
       notes && (<div className="help-notes"><small>{notes}</small></div>)
      }
     </div>

  )
}

export default renderCKEdtior;



/*import React, { Component, PropTypes } from 'react';
import CKEditor from "react-ckeditor-component";
const config = {
  toolbarGroups: [
		{ name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
		{ name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
		{ name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
		{ name: 'forms', groups: [ 'forms' ] },
		{ name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
		{ name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
		{ name: 'links', groups: [ 'links' ] },
		{ name: 'insert', groups: [ 'insert' ] },
		{ name: 'styles', groups: [ 'styles' ] },
		{ name: 'colors', groups: [ 'colors' ] },
		{ name: 'tools', groups: [ 'tools' ] },
		{ name: 'others', groups: [ 'others' ] },
		{ name: 'about', groups: [ 'about' ] }
	],
  height: 80,
  autoGrow_minHeight: 80,
  autoGrow_maxHeight: 500,
  removeButtons: 'Source,Save,Templates,NewPage,Preview,Print,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Find,Replace,SelectAll,Scayt,Radio,Form,TextField,Textarea,Select,Button,ImageButton,HiddenField,About,Maximize,ShowBlocks,Styles,Font,Format,Flash,Image,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Anchor,BidiLtr,BidiRtl,Language,Outdent,Indent,Subscript,Superscript,Strike,CreateDiv,Blockquote,CopyFormatting,RemoveFormat,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,Checkbox'
}

const renderCKEdtior = ({ input, id, readOnly, notes, parentDivClass, fieldClass, label, type, placeholder, meta: { touched, error, invalid, warning } }) => {

return (
     <div className={parentDivClass ? parentDivClass : ''} >
       {type != "hidden" && (<label>{label}</label>)}
       <CKEditor
          {...input}  content={input.value} activeClass={ ` ${fieldClass ? fieldClass:' ckeditor-container  '} ${touched && error ? '  is-invalid ':''}` }

           config={config}

           events={{
            blur: (evt)=>{

              input.onBlur(evt.editor.getData());
            },
            change: (evt)=>{
              input.onChange(evt.editor.getData());
            }
           }}

          />

       {touched && ((error && <label className="error">{error}</label>) || (warning && <div className="invalid-feedback">{warning}</div>))}
       {
       	notes && (<div className="help-notes"><small>{notes}</small></div>)
       }
     </div>

  )
}

export default renderCKEdtior;*/
