import React, { Component } from 'react';
import ForgotPasswordForm from '../../components/admin/ForgotPasswordForm';
import { ToastContainer } from 'react-toastify';
import $ from 'jquery';
import 'react-toastify/dist/ReactToastify.css';

class ForgotPassword extends Component {
  render() {
    return (
      <div style= {{height:"100%"}}>
        <div className="login-page">
          <div className="login-logo">
            <img src="/assets/img/logo-sm.png" />
          </div>
          <div className="login-head">
            <h2>Forgot Password</h2>
            <p>Please fill the email to continue</p>
          </div>
          <ForgotPasswordForm />
        </div>
        <div className="sm-footer-block">
          <ul>
            <li>
              <a href="javascript:void(0);">ABOUT</a>
            </li>
            <li>
              <a href="javascript:void(0);">CONTACT</a>
            </li>
            <li>
              <a href="javascript:void(0);">HELP</a>
            </li>
            <li>
              <a href="javascript:void(0);">TERMS</a>
            </li>
            <li>
              <a href="javascript:void(0);">PRIVACY POLICY</a>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}
export default ForgotPassword;
