import React, { Component, PropTypes } from 'react';
import dateFnsLocalizer from 'react-widgets-date-fns';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import Moment from 'moment';
import 'react-widgets/dist/css/react-widgets.css';

dateFnsLocalizer()

const renderDateTimePicker = ({ input, id, date, readOnly, notes, parentDivClass, fieldClass, label, type, placeholder, meta: { touched, error, invalid, warning } }) => {
    return(
      <div className={parentDivClass ? parentDivClass : ''} >
        {type != "hidden" && (<label>{label}</label>)}
        <DateTimePicker
          name={input.name} date={date?true: false} format="YYYY-MM-dd HH:ii" className={ ` ${fieldClass ? fieldClass:' custom-field  '} ${touched && error ? ' is-invalid ':''}` }

          onChange={(newValue) => {
            input.onChange(newValue)
          }}
          onBlur={(newValue) => {
            input.onBlur(newValue)
          }}
        />
        {touched && ((error && <label className="error">{error}</label>) || (warning && <div className="invalid-feedback">{warning}</div>))}
        {
        	notes && (<div className="help-notes"><small>{notes}</small></div>)
        }
      </div>
    )
  }

export default renderDateTimePicker;
