import { combineReducers } from 'redux';

import UserReducer from './reducer_user';
import QuoteReducer from './reducer_quotes';
import RoleReducer from './reducer_role';
import CitiesReducer from './reducer_cities';
import DestinationsReducer from './reducer_destinations';

import { reducer as reduxFormReducer } from 'redux-form';

import  StateReducer  from './reducer_state';



const appReducer = combineReducers({
  user: UserReducer,
  form: reduxFormReducer,
  quote: QuoteReducer,
  cities: CitiesReducer,
  StateReducer: StateReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_USER') {
    state = undefined;
  }
  return appReducer(state, action)
}

export default rootReducer;
