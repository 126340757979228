import React, { Component } from 'react';
import { Route, Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import renderField from '../../FormFields/renderField';
import renderDatePicker from '../../FormFields/renderDatePicker';
import CheckboxGroup2 from '../../FormFields/CheckboxGroup2';
import renderCKEditor from '../../FormFields/renderCKEditor';
import { toast } from 'react-toastify';
import CurrencyFormat from 'react-currency-format';
import {
  Field,
  reduxForm,
  change,
  FieldArray,
  FormSection,
  SubmissionError
} from 'redux-form';
import renderSelect from '../../FormFields/renderSelect';
import $ from 'jquery';
import { CURRENCY, NIGHTS, API_ROOT, ROOM_TYPES, yyyymmdd, removeDuplicates, numberWithCommas, isNormalInteger } from '../../../constants';
import { invoiceRequirements, updateInvoice, createInvoice, fetchSettings } from '../../../actions/quotes';
//import DatePicker from "react-datepicker";

const no_of_installments = [
  {
    id: 1,
    value: 1
  }, {
    id: 2,
    value: 2
  }, {
    id: 3,
    value: 3
  }, {
    id: 4,
    value: 4
  }, {
    id: 5,
    value: 5
  }, {
    id: 6,
    value: 6
  }
];

function validate(values) {
  var errors = {};
  var citiesSectionErrors = {};
  var hasErrors = false;
  if (!values.no_of_installments || values.no_of_installments === '-1') {
    errors.no_of_installments = "Select no. of installments";
    hasErrors = true;
  }
  if (!values.billing_address || values.billing_address.trim() == '') {
    errors.billing_address = "Enter billing address/location";
    hasErrors = true;
  }
  if (!values.days_after_payment_received || values.days_after_payment_received.trim() == '') {
    errors.days_after_payment_received = "Please enter";
    hasErrors = true;
  }
  if (!values.installments || !values.installments.length) {
    errors.installments = {
      _error: 'At least one installment must be created'
    };
    hasErrors = true;
  } else {
    const membersArrayErrors = [];
    values.installments.forEach((member, memberIndex) => {
      const memberErrors = {};
      if (!member || !member.installment) {
        memberErrors.installment = 'Required';
        membersArrayErrors[memberIndex] = memberErrors;
        hasErrors = true;
      } else {
        if (!isNormalInteger(member.installment)) {
          memberErrors.installment = 'Not valid';
          membersArrayErrors[memberIndex] = memberErrors;
          hasErrors = true;
        }
      }
      if (!member || !member.currency || member.currency == '-1') {
        memberErrors.currency = 'Select';
        membersArrayErrors[memberIndex] = memberErrors;
        hasErrors = true;
      }
      if (!member || !member.installment_date || member.installment_date == '') {
        memberErrors.installment_date = 'Select date';
        membersArrayErrors[memberIndex] = memberErrors;
        hasErrors = true;
      }
    })
    if (membersArrayErrors.length) {
      errors.installments = membersArrayErrors
    }
  }
  return hasErrors && errors;
}


class QuoteInvoice extends Component {
  constructor(props) {
    super(props);
    //var package_total_cost = parseInt(props.invoice_quotation_data.visa_cost) + parseInt(props.invoice_quotation_data.flight_cost) + parseInt(props.invoice_quotation_data.package_cost);
    var package_total_cost = props.invoice_quotation_data.total_package_price;
    this.state = {
      quote_status: props.quote_status,
      submitting: false,
      quote_id: props.invoice_quotation_data.quote_id,
      startDate: new Date(),
      invoice_quotation_data: props.invoice_quotation_data,
      package_total_cost: package_total_cost,
      invoice_requirements: null,
      quotation_index: props.quotation_index,
      invoice_id: props.invoice_quotation_data && props.invoice_quotation_data.invoice && props.invoice_quotation_data.invoice.id ? props.invoice_quotation_data.invoice.id : null
    };


  }

  prepareForm(props) {
    //var package_total_cost = parseInt(props.invoice_quotation_data.visa_cost) + parseInt(props.invoice_quotation_data.flight_cost) + parseInt(props.invoice_quotation_data.package_cost);
    var package_total_cost = props.invoice_quotation_data.total_package_price;
    props.initialize(props.invoice_quotation_data);
    props.change('amount_due', package_total_cost);
    if (props.invoice_quotation_data.invoice) {
      props.initialize(props.invoice_quotation_data.invoice);
      //initialize deliverables
      if (props.invoice_quotation_data.invoice.travel_agent_deliverable) {
        var arrayDeliverables = [];
        var invoice = props.invoice_quotation_data.invoice;
        for (var i = 0; i < invoice.travel_agent_deliverable.length; i++) {
          if (invoice.travel_agent_deliverable[i].travel_agent_deliverable_id != 0) {
            arrayDeliverables.push(invoice.travel_agent_deliverable[i].travel_agent_deliverable_id);
          } else if (invoice.travel_agent_deliverable[i].travel_agent_deliverable_id == 0) {
            props.change('checkbox_travel_agent_deliverable_comment', invoice.travel_agent_deliverable[i].checkbox_travel_agent_deliverable_comment);
          }
        }
        props.change('travel_agent_deliverable', arrayDeliverables);
      } else {
        props.change('travel_agent_deliverable', []);
      }
      if (props.invoice_quotation_data.invoice.travelor_document) {
        var arrayDeliverables = [];
        var invoice = props.invoice_quotation_data.invoice;
        for (var i = 0; i < invoice.travelor_document.length; i++) {
          if (invoice.travelor_document[i].travelor_document_id != 0) {
            arrayDeliverables.push(invoice.travelor_document[i].travelor_document_id);
          } else if (invoice.travelor_document[i].travelor_document_id == 0) {
            props.change('checkbox_travelor_document_comment', invoice.travelor_document[i].checkbox_travelor_document_comment);
          }
        }
        props.change('travelor_document', arrayDeliverables);
      } else {
        props.change('travelor_document', []);
      }
      if (props.invoice_quotation_data.invoice.installments) {
        var total_price = 0;
        for (var i = 0; i < props.invoice_quotation_data.invoice.installments.length; i++) {
          total_price = total_price + parseInt(props.invoice_quotation_data.invoice.installments[i].installment);
        }
        package_total_cost = total_price;
        props.change('amount_due', total_price);
        this.setState({ package_total_cost: total_price });
      }
      props.change('invoice_id', props.invoice_quotation_data.invoice.id);
    }

    this.setState({
      package_total_cost: package_total_cost,

    });
    props.change('visa_cost', props.invoice_quotation_data.visa_cost);
    props.change('flight_cost', props.invoice_quotation_data.flight_cost);
    props.change('package_cost', props.invoice_quotation_data.package_cost);

    this.renderInstallments = this.renderInstallments.bind(this);

  }




  componentDidMount() {
    if (!this.state.invoice_id) {
      this.props.fetchSettings({ key: 'quote_terms_conditions' }).then((response) => {
        if (response.value.data.result === 'success') {
          //this.setState({settings: response.value.data.payload});
          this.props.change('cancellation_policy', response.value.data.payload.quote_terms_conditions);
        }
      })
    }
    this.prepareForm(this.props);
    this.fetchInvoiceRequirements();
  }

  fetchInvoiceRequirements() {
    this.props.invoiceRequirements().then((response) => {
      if (response.value.data.result === 'success') {
        this.setState({ invoice_requirements: response.value.data.payload });
      }
    })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.quotation_index != nextProps.quotation_index) {
      this.setState({ invoice_quotation_data: nextProps.invoice_quotation_data });
      this.props.reset('QuoteInvoiceForm');
      this.prepareForm(nextProps);
    }
  }

  submitForm(values, dispatch, props) {
    this.setState({ submitting: true });
    //console.log('vvvvvv', values); return;
    if (values.invoice_id) {
      this.props.updateInvoice(values).then((result) => {
        this.setState({ submitting: false });
        if (result.value.data.result == 'failure') {
          if (result.value.data.error) {
            throw new SubmissionError(result.value.data.error);
          }
          toast.error(result.value.data.flash_message);
        } else {
          toast.success(result.value.data.flash_message);
          this.setState({ redirectBack: true });
        }
      });
    } else {
      this.props.createInvoice(values).then((result) => {
        this.setState({ submitting: false });
        if (result.value.data.result == 'failure') {
          if (result.value.data.error) {
            throw new SubmissionError(result.value.data.error);
          }
          toast.error(result.value.data.flash_message);
        } else {
          toast.success(result.value.data.flash_message);
          this.setState({ redirectBack: true });
        }
      });
    }
  }

  updateAmountDue() {
    var total = 0;
    $(".installment-class").each(function (e) {
      if ($(this).val() != "") {
        total = total + parseInt($(this).val());
      }
    });
    var total_cost = parseInt(this.state.package_total_cost);
    this.props.change("amount_due", total);
    this.setState({ package_total_cost: total });
  }

  renderInstallments({
    fields,
    meta: {
      touched,
      error,
      submitFailed
    }
  }) {
    return (
      fields.map((member, index) => <div key={index}>
        <Field type="text" name={`${member}.installment`} fieldClass={"installment-class textfield"} component={renderField} parentDivClass="form-group" label='Installment' onBlur={(e) => this.updateAmountDue()} />
        <Field name={`${member}.currency`} component={renderSelect} selectDataObject={CURRENCY} dont_show_select_option={false} fieldSet={{
          id: 'symbol',
          value: 'title'
        }} label="Currency" fieldClass="cs-select cs-skin-slide" parentDivClass="currency form-group days-input" />
        <Field type="text" name={`${member}.installment_date`} min={new Date()} component={renderDatePicker} parentDivClass="form-group days-input" label='On' />
      </div>)
    )
  }

  updateInstallmentsForm(e) {
    var installments = parseInt(e.target.value);
    var arrayInstallments = [];
    if (this.state.invoice_quotation_data.invoice) {
      if (installments > 0) {
        for (var i = 0; i < installments; i++) {
          if (this.state.invoice_quotation_data.invoice.installments[i]) {
            arrayInstallments.push(this.state.invoice_quotation_data.invoice.installments[i]);
          } else {
            arrayInstallments.push({});
          }
        }
      }
    } else {
      this.props.change("amount_due", this.state.package_total_cost);
      if (installments > 0) {
        for (var i = 0; i < installments; i++) {
          arrayInstallments.push({});
        }
      }
    }
    this.props.change('installments', arrayInstallments);
  }
  componentDidUpdate() {
    //this.updateAmountDue();
  }


  render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    if (this.state.redirectBack) {
      return <Redirect to={'/admin/payments/invoice/' + this.state.quote_id} />
    }
    return (<div className="card card-default m-b-0 create-invoice-block">
      <div className="card-header " role="tab" id="headingthree">
        <h4 className="card-title">
          <a data-toggle="collapse" data-parent="#accordion" href="#quotations3" aria-expanded="false" aria-controls="quotations3" className="">
            <span className="card-title-inner">

              {(this.state.quote_status == 0 || this.state.quote_status == 1 || this.state.quote_status == 3) ? ((this.state.invoice_quotation_data.invoice && this.state.invoice_quotation_data.invoice.id) ? "Edit " : "Create ") : "View "}Invoice</span>
          </a>
        </h4>
      </div>
      <div id="quotations3" className="collapse show" role="tabcard" aria-labelledby="headingthree">
        <div className="card-body">
          <form onSubmit={handleSubmit(this.submitForm.bind(this))}>
            <Field name="invoice_id" type="hidden" component="input" />
            <div className="row">
              <div className="col-md-8">
                <div className="sec-title  twt">
                  <h6>Payment detail</h6>
                </div>
                <div className="form-block basic-detail-block ">
                  <Field name="no_of_installments" component={renderSelect} label="No. of Installment" parentDivClass="currency form-group days-input" fieldClass="cs-select cs-skin-slide" selectDataObject={no_of_installments} fieldSet={{
                    id: 'id',
                    value: 'value'
                  }} dont_show_select_option={false} onChange={(e) => this.updateInstallmentsForm(e)} />
                </div>
                <div className="form-block basic-detail-block ci-block">
                  <FieldArray name="installments" component={this.renderInstallments} />
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-block basic-detail-block ">
                      <Field type="text" name="amount_due" component={renderField} parentDivClass="form-group" label='Net amount payable' readOnly={true} />
                    </div>
                  </div>
                  {/*<div className="col-sm-6">
                    <div className="form-block basic-detail-block ">
                      <div className="form-group ">
                        <label>Discount</label>
                        <div className="textfield-block">
                          <input type="text" className="textfield" placeholder="0"/>
                        </div>
                      </div>
                    </div>
                  </div>*/}
                </div>
                <div className="form-block basic-detail-block ">
                  <Field type="text" name="billing_address" component={renderField} parentDivClass="form-group" label='Billing Address' placeholder="Traveler's location" />
                </div>
                <div className="row">

                  <div className="col-sm-6">
                    <div className="form-block basic-detail-block ">
                      <Field type="text" name="flight_cost" component={renderField} parentDivClass="form-group" label='Flight cost' readOnly={true} />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-block basic-detail-block ">
                      <Field type="text" name="visa_cost" component={renderField} parentDivClass="form-group" label='Visa cost' readOnly={true} />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-block basic-detail-block ">
                      <Field type="text" name="package_cost" component={renderField} parentDivClass="form-group" label='Land package cost' readOnly={true} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="invoicetotalview">

                  <div className="form-block basic-detail-block total-amout-view">
                    <div className="form-group ">
                      <label>Net Amount Payable to Travelot</label>
                      <span><CurrencyFormat value={this.state.package_total_cost} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={this.state.invoice_quotation_data.currency + ' '} /></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="box-hide-wrapper">
                  <div className="include-hide field-check-show travelAgentBlock">
                    <div className="box-hide-wrapper">
                      <h4>Travel Agent's Deliverables</h4>
                      <div className="include-hide">
                        <div className="inclusion-lists-wrapper">
                          <Field type="text" name="days_after_payment_received" component={renderField} parentDivClass="form-group" label='Days after Payment Received' />
                        </div>
                      </div>
                    </div>
                    <div className="form-group checkbox-listing">
                      <h5>
                        Select Deliverables (if any)</h5>
                      {

                        this.state.invoice_requirements && this.state.invoice_requirements.travel_agent_deliverable && this.state.invoice_requirements.travel_agent_deliverable.length > 0
                        && <div><CheckboxGroup2 options={this.state.invoice_requirements.travel_agent_deliverable} name="travel_agent_deliverable" />
                          <Field type="text" name="checkbox_travel_agent_deliverable_comment" component={renderField} parentDivClass="form-group" placeholder='Write other deliverables' />
                        </div>
                      }


                    </div>

                    <div className="box-hide-wrapper">
                      <h4>Traveller Documents</h4>
                    </div>
                    <div className="form-group checkbox-listing">
                      <h5>
                        Select all the required documents</h5>
                      {

                        this.state.invoice_requirements && this.state.invoice_requirements.travelor_document && this.state.invoice_requirements.travelor_document.length > 0
                        && <div><CheckboxGroup2 options={this.state.invoice_requirements.travelor_document} name="travelor_document" />
                          <Field type="text" name="checkbox_travelor_document_comment" component={renderField} parentDivClass="form-group" placeholder='Write other required documents' />
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12">
                <div className="box-hide-wrapper">
                  <div class="heading-in-card heading-in-card-label"><h2>Cancellation Policy / Terms and Conditions</h2></div>
                  <Field name="cancellation_policy" component={renderCKEditor} />

                </div>
              </div>
            </div>
            {
              ((this.state.quote_status == 0 || this.state.quote_status == 1 || this.state.quote_status == 3) && (this.state.invoice_quotation_data.accepted_quote != 2)) && <button type="submit" disabled={submitting} className="btn btn-complete mt-30" >Submit {submitting && <i className="fa fa-spinner fa-spin"></i>}</button>
            }

          </form>
        </div>
      </div>
    </div>);
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    formValues: state.form.QuoteInvoiceForm ? state.form.QuoteInvoiceForm.values : null
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    invoiceRequirements: (data) => {
      return dispatch(invoiceRequirements(data));
    },
    createInvoice: (data) => {
      return dispatch(createInvoice(data));
    },
    updateInvoice: (data) => {
      return dispatch(updateInvoice(data));
    },
    fetchSettings: (data) => {
      return dispatch(fetchSettings(data));
    }
  };
}
QuoteInvoice = reduxForm({
  form: 'QuoteInvoiceForm', // a unique identifier for this form
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(QuoteInvoice);

export default connect(mapStateToProps, mapDispatchToProps)(QuoteInvoice);
