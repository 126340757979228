import {
  _dispatch, LIST_FAQS_SUCCESS, LIST_DESTINATIONS_SUCCESS, DESTINATION_DETAIL_SUCCESS, LIST_THINGS_TO_DO_SUCCESS, THINGS_TO_DO_DETAIL_SUCCESS, DELETE_THINGS_TO_DO, DELETE_DESTINATION
} from '../actions/destinations';

const INITIAL_STATE = { destinations:[], status:null };

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {

    case LIST_DESTINATIONS_SUCCESS:
      return (() => {
        const destinations = action.payload;
        return _dispatch({ ...state, destinations}, true);
      })();

    case DESTINATION_DETAIL_SUCCESS:
     return (() => {
       const destination = action.payload;
       return _dispatch({ ...state, destination}, false);
     })();

    case LIST_FAQS_SUCCESS:
      return (() => {
        const faqs = action.payload;
        return _dispatch({ ...state, faqs}, true);
      })();

    case LIST_THINGS_TO_DO_SUCCESS:
      return (() => {
        const thingsToDo = action.payload;
        return _dispatch({ ...state, thingsToDo}, true);
      })();

    case THINGS_TO_DO_DETAIL_SUCCESS:
      return (() => {
        const thingsToDoDetail = action.payload;
        return _dispatch({ ...state, thingsToDoDetail}, true);
      })();

    case DELETE_DESTINATION:
      var index = false;
      return (() => {
        const id = action.payload.id;
        const data = [...state.destinations.data];
        if(data.length > 0){
          for(var i=0; i<data.length; i++){
            if(data[i].id === id){
              index = i;
              break;
            }
          }
        }
        if(index !== false){
          data.splice(index, 1);
        }
        var destinations = Object.assign(state.destinations, {data: data});
        return _dispatch({ ...state, destinations}, true);
      })();

    case DELETE_THINGS_TO_DO:
      var index = false;
      return (() => {
        const id = action.payload.id;
        const thingsToDo = [...state.thingsToDo];
        if(thingsToDo.length > 0){
          for(var i=0; i<thingsToDo.length; i++){
            if(thingsToDo[i].id == id){
              index = i;
              break;
            }
          }
        }
        if(index !== false){
          thingsToDo.splice(index, 1);
        }
        return _dispatch({ ...state, thingsToDo}, true);
      })();


    default:
      return state;
  }
}
