export const ROUTES = {

  DASHBOARD: {
    URL: "/",
    PARENT: "Layout"
  },
  ADMIN: {
    URL: "/admin",
    PARENT: "Layout"
  },
  ADMIN_DASHBOARD: {
    URL: "/admin/dashboard",
    PARENT: "Layout"
  },
  QUOTES: {
    DURING_STAY:{
      LIST: {
        URL: "/admin/quotes/during-stay/list",
        PARENT: "Layout"
      }
    },
    POST_STAY:{
      LIST: {
        URL: "/admin/quotes/post-stay/list",
        PARENT: ""
      }
    },
    UNVERIFIED:{
      LIST: {
        URL: "/admin/leads/unverified/list",
        PARENT: "Layout"
      }
    },
    VERIFIED:{
      LIST: {
        URL: "/admin/leads/verified/list",
        PARENT: "Layout"
      }
    },
    REJECTED:{
      LIST: {
        URL: "/admin/leads/rejected/list",
        PARENT: "Layout"
      }
    },
    NEW:{
      LIST:{
        URL:"/admin/quotes/new/list",
        PARENT: "Layout"
      }
    },
    FOLLOW_UP:{
      LIST:{
        URL:"/admin/quotes/follow_up/list",
        PARENT: "Layout"
      }
    },
    ALL_QUOTES:{
      LIST:{
        URL:"/admin/all-quotes/list",
        PARENT: "Layout"
      }
    },
    UNASSIGNED_LEADS:{
      LIST:{
        URL:"/admin/leads/unassigned/list",
        PARENT: "Layout"
      }
    }
  },
  QUOTE_DETAIL:{
    DETAIL:{
      URL:"/admin/quotes/detail/:quote_id",
      PARENT: "Layout"
    }
  },
  QUOTE_FOLLOW_UP_DETAIL:{
    DETAIL:{
      URL:"/admin/quotes/follow_up/detail/:quote_id",
      PARENT: "Layout"
    }
  },
  QUOTE_FORM:{
    ADD:{
      URL:"/admin/quotes/add/:quote_id",
      PARENT: "Layout"
    },
    EDIT:{
      URL:"/admin/quotes/edit/:quote_id/:quotation_id",
      PARENT: "Layout"
    }
  },
  STATS:{
    URL:"/admin/stats/:tab",
    PARENT: "Layout"
  },
  CALENDAR:{
    URL:"/admin/calendar",
    PARENT: "Layout"
  },
  PAYMENT:{
    URL:"/admin/payments-vouchers",
    PARENT: "Layout"
  },
  INVOICE:{
    URL:"/admin/payments/invoice/:quote_id",
    PARENT: "Layout"
  },
  CHANGE_PASSWORD:{
    URL:"/admin/change-password",
    PARENT: "Layout"
  },
  LOGOUT: {
    URL:"/logout",
    PARENT: "Layout"
  },
};
