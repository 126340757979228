import React, { Component } from 'react';
import { Route, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Moment from 'react-moment';
import 'moment-timezone';
import { objToQueryParams } from "../../../constants";
import { quoteDetail, quoteDetailSuccess, listQuoteRejectionReasons, listQuoteRejectionReasonsSuccess } from '../../../actions/quotes';
import TravelerInfo from './TravelerInfo';
import $ from 'jquery';
import RejectQuote from './RejectQuote';

class QuoteDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.quote_id,
      status: props.status,
      record: props.record,
      openEditForm: false,
      rejection_reasons: props.rejection_reasons ? props.rejection_reasons : null,
    };
  }

  componentDidMount() {
    $("body").addClass('nav-webe-hide');
    this.fetchRecord();
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.state != nextProps.state) {
      this.setState({ record: nextProps.record });
    }
  }

  fetchRecord() {
    this.props.quoteDetail({ id: this.state.id }).then((response) => {
      if (response.value.data.result === 'success') {
        this.props.quoteDetailSuccess(response.value.data.payload[0]);
      }
    })
    this.props.listQuoteRejectionReasons().then((response) => {
      if (response.value.data.result === 'success') {
        this.props.listQuoteRejectionReasonsSuccess(response.value.data.payload);
      }
    })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.status != nextProps.status) {
      this.setState({ record: nextProps.record });
    }
    if (!this.props.rejection_reasons && nextProps.rejection_reasons) {
      this.setState({ rejection_reasons: nextProps.rejection_reasons });
    }
  }

  render() {

    console.log(this.state.record, "records")
    return (
      <div className="dashboard-content">
        <div className="content sm-gutter">
          <div className="container-fluid p-t-0 p-b-25 sm-padding-10 new-quotes">
            <div className="row">
              <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 new-quotes-left">
                <div className="card info-card card-borderless tab-ui-block shadow-card ">
                  <div className="quotes-status-block active-quotes">
                    <div className="quotes-id-block">
                      <label>ID {this.state.record && this.state.record.id}</label>
                      <div className="id-status">Active</div>
                      <time className="cls-time">20 mins ago</time>
                    </div>
                    <div className="content-quotes">
                      <h3>Quote request by {this.state.record && this.state.record.name} from <i>{this.state.record && this.state.record.departure}</i> to <i>{this.state.record && this.state.record.destination}</i></h3>
                    </div>
                    <div className="heading-in-card">
                      <h2>Traveler Requirements</h2>
                    </div>
                    <TravelerInfo travelerInfo={this.state.record} />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <div className="card info-card card-borderless tab-ui-block shadow-card">
                  <div className="head-block-filter">
                    <h2>TRAVELER DETAILS</h2>
                  </div>
                  <div className="quotes-list">
                    {/*<h5 className="heading-sm-block">Quotes</h5>
                    <p className="no-quote">No Quotes has been given.</p>*/}
                  </div>
                  <div className="tranvallor-detail">
                    <div className="quote-user">
                      <figure>
                        {
                          this.state.record && this.state.record.profile_image ?
                            <img src={this.state.record.path} alt={"profile"} /> : null
                        }
                      </figure>
                      <figcaption>
                        <label>{this.state.record && this.state.record.name}</label>
                        {this.state.record && <span>{this.state.record.country_code}-{this.state.record.contact_number}</span>}
                        {this.state.record && <p>{this.state.record.email_id}</p>}
                      </figcaption>
                    </div>
                  </div>
                  <div className="qutoes-block-bottom quotes-btn-left">
                    <div className="new-quote-action ">
                      <div className="quotation-dropdown dropdown-style dropdown" id="quation-center">
                        <a target="_blank" data-toggle="dropdown" href={"/admin/quotes/add/" + this.state.record.trip_id} className="btn btn-primary">GIVE QUOTATION</a>
                        <div className="dropdown-menu dropdown-list-accept" role="menu" aria-labelledby="quation-center">
                          <ul>
                            <li><a target="_blank" href={"/admin/quotes/add/" + this.state.record.trip_id + "/?last_link=new_quotes_list"}>Create New Quote</a></li>
                            <li><a target="_blank" href={"/admin/quotes/add/" + this.state.record.trip_id + "/?last_link=new_quotes_list&last_quotes_page=true"}>From Last 15 Quotes</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {this.state.rejection_reasons && this.state.rejection_reasons.length > 0 && <RejectQuote rejection_reasons={this.state.rejection_reasons} quote_id={this.state.record.trip_id} fetchRecords={() => null} list_type='2' />}

                    {/* <div className="new-quote-action">
                      <a href="javascript:void(0);" className="btn btn-primary">GIVE QUOTATION</a>
                      <select className="cs-select cs-skin-slide select-style-ui" data-init-plugin="cs-select">
                        <option value="sightseeing">REJECT</option>
                        <option value="business">Accept</option>
                      </select>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    record: state.quote.quote_detail ? state.quote.quote_detail : null,
    status: state.quote.status,
    rejection_reasons: state.quote.rejection_reasons ? state.quote.rejection_reasons : null,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    quoteDetail: (data) => {
      return dispatch(quoteDetail(data));
    },
    quoteDetailSuccess: (payload) => {
      dispatch(quoteDetailSuccess(payload));
    },
    listQuoteRejectionReasons: (params) => {
      return dispatch(listQuoteRejectionReasons(params));
    },
    listQuoteRejectionReasonsSuccess: (data) => {
      dispatch(listQuoteRejectionReasonsSuccess(data));
    },
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(QuoteDetail);
