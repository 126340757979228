import React, { Component } from 'react';
import { Route, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Moment from 'react-moment';
import 'moment-timezone';
import { objToQueryParams, WEBSITE_TITLE } from "../../../constants";
import { listNewQuotes, listNewQuotesSuccess, fetchQuoteListingFilters, fetchQuoteListingFiltersSuccess, listQuoteRejectionReasons, listQuoteRejectionReasonsSuccess, viewPhoneNumber } from '../../../actions/quotes';
import TravelerInfo from './TravelerInfo';
import QuoteFilters from './QuoteFilters';
import RejectQuote from './RejectQuote';
import MetaTags from 'react-meta-tags';
import Loader from '../../../components/Loader';
import { PermissibleRender } from '@brainhubeu/react-permissible';



class NewQuotesList extends Component {
  constructor(props) {
    super(props);
    var page = 1;
    var searchQuery = '';
    if (props.location.search) {
      const values = queryString.parse(this.props.location.search);
      if (values.page) {
        page = values.page;
      }
      if (values.search) {
        searchQuery = values.search;
      }
    }
    this.state = {
      permissions: props.user && props.user.user ? props.user.user.permissions : [],
      fetchingPhoneNumber: false,
      page: page,
      searchQuery: searchQuery,
      status: props.status,
      records: props.quotes ? props.quotes : [],
      openEditForm: false,
      quoteFilterData: props.quote_filters ? props.quote_filters.new : null,
      rejection_reasons: props.rejection_reasons ? props.rejection_reasons : null,
      fetchingRecords: false,
    };
    this.fetchRecords = this.fetchRecords.bind(this);
  }

  componentDidMount() {
    this.fetchRecords();
    this.fetchRejectionReasons();
    this.fetchQuoteFilters();
  }

  fetchRecords(params) {
    if (!params) {
      params = {};
    }
    this.setState({ fetchingRecords: true });
    this.props.listNewQuotes(params).then((response) => {
      this.setState({ fetchingRecords: false });
      if (response.value.data.result === 'success') {
        this.props.listNewQuotesSuccess(response.value.data.payload);
      }
    })
  }

  fetchQuoteFilters() {
    this.props.fetchQuoteListingFilters().then((response) => {
      if (response.value.data.result === 'success') {
        this.props.fetchQuoteListingFiltersSuccess(response.value.data.payload);
      }
    })
  }

  fetchRejectionReasons() {
    this.props.listQuoteRejectionReasons().then((response) => {
      if (response.value.data.result === 'success') {
        this.props.listQuoteRejectionReasonsSuccess(response.value.data.payload);
      }
    })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.status != nextProps.status) {
      this.setState({ records: nextProps.quotes });
    }
    if (!this.props.rejection_reasons && nextProps.rejection_reasons) {
      this.setState({ rejection_reasons: nextProps.rejection_reasons });
    }
    if (!this.props.quote_filters && nextProps.quote_filters) {
      this.setState({ quoteFilterData: nextProps.quote_filters.new });
    }
  }

  getPhoneNumber(e, quote_id) {
    this.setState({ fetchingPhoneNumber: true });
    e.preventDefault();
    this.props.viewPhoneNumber({ quote_id: quote_id, from_page: 'quotation_phone_access' }).then((response) => {
      this.setState({ fetchingPhoneNumber: false });
      var records = this.state.records;
      if (records.length > 0) {
        for (var i = 0; i < records.length; i++) {
          if (records[i].id == quote_id) {
            records[i] = Object.assign(records[i], { quotation_phone_access: 1 });
            break;
          }
        }
      }
      this.setState({ records: records });
    })
  }

  render() {
    console.log(this.state.records, "records");
    return (
      <div className="dashboard-content">

        <MetaTags>
          <title>{WEBSITE_TITLE} – new quotes</title>
        </MetaTags>
        <div className="content sm-gutter">
          <div className="container-fluid p-t-0 p-b-25 sm-padding-10 new-quotes">
            <QuoteFilters quoteFilterData={this.state.quoteFilterData} fetchRecords={this.fetchRecords} fetchingRecords={this.state.fetchingRecords} />
            <div className="row">
              {
                this.state.fetchingRecords && <Loader />
              }
              {
                this.state.fetchingPhoneNumber && <Loader />
              }
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 new-quotes-left">
                <div className="header-block-ui no-border">
                  <h3>New Quote Requests</h3>
                  {/*<div className="filter-sort">
                      <label>Sort by </label>
                      <select className="cs-select cs-skin-slide select-style-ui" data-init-plugin="cs-select">
                        <option value="sightseeing">Latest</option>
                        <option value="business">Popular</option>
                      </select>
                    </div>*/}
                </div>
                <div className="card info-card card-borderless tab-ui-block shadow-card">
                  {
                    this.state.records && this.state.records.length > 0 &&
                    this.state.records.map((obj, inxex) => (
                      <div key={obj.id} className="quotes-status-block active-quotes">
                        <div className="quotes-id-block">
                          <a target="_blank" href={"/admin/quotes/detail/" + obj.id}><label>ID {obj.id}</label></a>
                          <div className="id-status">Active</div>
                          <time className="cls-time"><Moment format="DD MMM, YY h:mm a" fromNow fromNowDuring={36000000}>{obj.created_at + '-0000'}</Moment></time>
                        </div>
                        <div className="content-quotes">
                          <h3>Quote request by {obj.name} from {obj.departure} to {obj.destination}</h3>
                        </div>
                        <TravelerInfo quickView={true} travelerInfo={obj} />
                        <div className="qutoes-block-bottom">
                          <div className="quote-user">
                            <figure>
                              {/*<img src="/assets/img/alexa.png" />*/}
                            </figure>
                            <figcaption>
                              <label>{obj.name}</label>
                              <PermissibleRender userPermissions={this.state.permissions} requiredPermissions={['view_client_info']} >
                                {obj.quotation_phone_access == 0 && <label><a href="#" onClick={(e) => this.getPhoneNumber(e, obj.id)}><small><i className="fa fa-mobile" aria-hidden="true"></i> Get Phone Number</small></a></label>}
                                {obj.quotation_phone_access == 1 && <label><small>{obj.country_code}-{obj.contact_number}</small></label>}
                              </PermissibleRender>
                            </figcaption>
                          </div>
                          <PermissibleRender userPermissions={this.state.permissions} requiredPermissions={['create_quotation']} >
                            <div className="new-quote-action ">
                              <div className="quotation-dropdown dropdown-style dropdown" id="quation-center">
                                <a target="_blank" data-toggle="dropdown" href={"/admin/quotes/add/" + obj.id} className="btn btn-primary">GIVE QUOTATION</a>
                                <div className="dropdown-menu dropdown-list-accept" role="menu" aria-labelledby="quation-center">
                                  <ul>
                                    <li><a target="_blank" href={"/admin/quotes/add/" + obj.id + "/?last_link=new_quotes_list"}>Create New Quote</a></li>
                                    <li><a target="_blank" href={"/admin/quotes/add/" + obj.id + "/?last_link=new_quotes_list&last_quotes_page=true"}>From Last 15 Quotes</a></li>
                                  </ul>
                                </div>
                              </div>
                              {this.state.rejection_reasons && this.state.rejection_reasons.length > 0 && <RejectQuote rejection_reasons={this.state.rejection_reasons} quote_id={obj.id} fetchRecords={this.fetchRecords} list_type='2' />}
                            </div>
                          </PermissibleRender>
                        </div>
                      </div>
                    ))
                  }
                  {
                    !this.state.fetchingRecords && this.state.records.length <= 0 &&
                    <div className="quotes-unavailable">
                      <figure className="icon-block"><ion-icon name="list-box"></ion-icon></figure>
                      <figcaption>
                        <p>Quote Requests are not available at this time</p>
                      </figcaption>
                    </div>
                  }
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    user: { ...state.user },
    quotes: state.quote.new_quotes,
    status: state.quote.status,
    rejection_reasons: state.quote.rejection_reasons ? state.quote.rejection_reasons : null,
    quote_filters: state.quote.quote_filters ? state.quote.quote_filters : null
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    listNewQuotes: (data) => {
      return dispatch(listNewQuotes(data));
    },
    listNewQuotesSuccess: (payload) => {
      dispatch(listNewQuotesSuccess(payload));
    },
    fetchQuoteListingFilters: () => {
      return dispatch(fetchQuoteListingFilters());
    },
    fetchQuoteListingFiltersSuccess: (data) => {
      dispatch(fetchQuoteListingFiltersSuccess(data));
    },
    listQuoteRejectionReasons: (params) => {
      return dispatch(listQuoteRejectionReasons(params));
    },
    listQuoteRejectionReasonsSuccess: (data) => {
      dispatch(listQuoteRejectionReasonsSuccess(data));
    },
    viewPhoneNumber: (data) => {
      return dispatch(viewPhoneNumber(data));
    }
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(NewQuotesList);
