import axios from 'axios';

import { API_ROOT } from '../constants';

export const RESET_TOKEN = 'RESET_TOKEN';

//Sign Up User
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNUP_USER_FAILURE = 'SIGNUP_USER_FAILURE';
export const RESET_USER = 'RESET_USER';

//Sign In User
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_PENDING = 'SIGNIN_USER_PENDING';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNIN_USER_FAILURE = 'SIGNIN_USER_FAILURE';

//Activate Account
export const ACTIVATE_ACCOUNT = 'ACTIVATE_ACCOUNT';
export const ACTIVATE_ACCOUNTPENDING = 'ACTIVATE_ACCOUNT_PENDING';
export const ACTIVATE_ACCOUNT_FULFILLED = 'ACTIVATE_ACCOUNT_FULFILLED';
export const ACTIVATE_ACCOUNT_FAILURE = 'ACTIVATE_ACCOUNT_FAILURE';


//Forgot password
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_PENDING = 'FORGOT_PASSWORD_PENDING';
export const FORGOT_PASSWORD_FULFILLED = 'FORGOT_PASSWORD_FULFILLED';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';
export const FORGOT_PASSWORD_RESET = 'FORGOT_PASSWORD_RESET';

//Forgot Subdomain
export const FORGOT_SUBDOMAIN = 'FORGOT_SUBDOMAIN';
export const FORGOT_SUBDOMAIN_PENDING = 'FORGOT_SUBDOMAIN_PENDING';
export const FORGOT_SUBDOMAIN_FULFILLED = 'FORGOT_SUBDOMAIN_FULFILLED';
export const FORGOT_SUBDOMAIN_FAILURE = 'FORGOT_SUBDOMAIN_FAILURE';
export const FORGOT_SUBDOMAIN_RESET = 'FORGOT_SUBDOMAIN_RESET';

//Reset Password
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const PASSWORD_RESET_PENDING = 'PASSWORD_RESET_PENDING';
export const PASSWORD_RESET_FULFILLED = 'PASSWORD_RESET_FULFILLED';
export const PASSWORD_RESET_FAILURE = 'PASSWORD_RESET_FAILURE';

//Fetch Logged in User
export const FETCH_LOGGED_IN_USER = 'FETCH_LOGGED_IN_USER';
export const FETCH_LOGGED_IN_USER_PENDING = 'FETCH_LOGGED_IN_USER_PENDING';
export const FETCH_LOGGED_IN_USER_SUCCESS = 'FETCH_LOGGED_IN_USER_SUCCESS';
export const FETCH_LOGGED_IN_USER_FAILURE = 'FETCH_LOGGED_IN_USER_FAILURE';

//Update User Profile
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_USER_PROFILE_PENDING = 'UPDATE_USER_PROFILE_PENDING';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAILURE = 'UPDATE_USER_PROFILE_FAILURE';

//Update User Password
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_PENDING = 'UPDATE_PASSWORD_PENDING';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE';


//validate email, if success, then load user and login
export const VALIDATE_EMAIL = 'VALIDATE_EMAIL';
export const VALIDATE_EMAIL_SUCCESS = 'VALIDATE_EMAIL_SUCCESS';
export const VALIDATE_EMAIL_FAILURE = 'VALIDATE_EMAIL_FAILURE';



//called when email is updated in profile to update main user's email state
export const UPDATE_USER_EMAIL = 'UPDATE_USER_EMAIL';

export const VERIFY_SUBDOMAIN = 'VERIFY_SUBDOMAIN';

//log out user
export const LOGOUT_USER = 'LOGOUT_USER';

//Send contact us email

export const SEND_CONTACTUS_EMAIL = 'SEND_CONTACTUS_EMAIL';

//Send "Report a bug" email
export const SEND_REPORT_BUG_EMAIL = 'SEND_REPORT_BUG_EMAIL';

//Fetch user settings
export const FETCH_USER_SETTINGS = 'FETCH_USER_SETTINGS';
export const FETCH_USER_SETTINGS_PENDING = 'FETCH_USER_SETTINGS_PENDING';
export const FETCH_USER_SETTINGS_SUCCESS = 'FETCH_USER_SETTINGS_SUCCESS';
export const FETCH_USER_SETTINGS_FAILURE = 'FETCH_USER_SETTINGS_FAILURE';

//Update user display settings
export const UPDATE_USER_SETTINGS = 'UPDATE_USER_SETTINGS';
export const UPDATE_USER_SETTINGS_PENDING = 'UPDATE_USER_SETTINGS_PENDING';
export const UPDATE_USER_SETTINGS_SUCCESS = 'UPDATE_USER_SETTINGS_SUCCESS';
export const UPDATE_USER_SETTINGS_FAILURE = 'UPDATE_USER_SETTINGS_FAILURE';

//Fetch user Preferences
export const FETCH_USER_PREFERENCES = 'FETCH_USER_PREFERENCES';
export const FETCH_USER_PREFERENCES_PENDING = 'FETCH_USER_PREFERENCES_PENDING';
export const FETCH_USER_PREFERENCES_SUCCESS = 'FETCH_USER_PREFERENCES_SUCCESS';
export const FETCH_USER_PREFERENCES_FAILURE = 'FETCH_USER_PREFERENCES_FAILURE';


//Update user preferences
export const UPDATE_USER_PREFERENCES = 'UPDATE_USER_PREFERENCES';
export const UPDATE_USER_PREFERENCES_PENDING = 'UPDATE_USER_PREFERENCES_PENDING';
export const UPDATE_USER_PREFERENCES_SUCCESS = 'UPDATE_USER_PREFERENCES_SUCCESS';
export const UPDATE_USER_PREFERENCES_FAILURE = 'UPDATE_USER_PREFERENCES_FAILURE';

//Fetch user permissions
export const FETCH_USER_PERMISSIONS = 'FETCH_USER_PERMISSIONS';
export const FETCH_USER_PERMISSIONS_SUCCESS = 'FETCH_USER_PERMISSIONS_SUCCESS';


//Verify activate account token
export const VERIFY_ACTIVATION_TOKEN = 'VERIFY_ACTIVATION_TOKEN';


const ROOT_URL = API_ROOT;


let token = null;

export function validateEmail(validateEmailToken) {
  token = localStorage.getItem('jwtToken');
  //check if token from welcome email is valid, if so, update email as verified and login the user from response
  const request = axios.get(`${ROOT_URL}/validateEmail/${validateEmailToken}`);

  return {
    type: VALIDATE_EMAIL,
    payload: request
  };
}

export function validateEmailSuccess(currentUser) {
  return {
    type: VALIDATE_EMAIL_SUCCESS,
    payload: currentUser
  };
}

export function validateEmailFailure(error) {
  return {
    type: VALIDATE_EMAIL_FAILURE,
    payload: error
  };
}




export function resetToken() {//used for logout
  return {
    type: RESET_TOKEN
  };
}


export function signUpUser(formValues) {
  token = localStorage.getItem('jwtToken');
  const request = axios.post(`${ROOT_URL}/register`, formValues);

  return {
    type: SIGNUP_USER,
    payload: request
  };
}

export function signUpUserSuccess(user) {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: user
  };
}

export function signUpUserFailure(error) {
  return {
    type: SIGNUP_USER_FAILURE,
    payload: error
  };
}


export function resetUser() {
  return {
    type: RESET_USER,
  };
}

export function signInUser(formValues) {
  //token = localStorage.getItem('jwtToken');
  const request = axios.post(`${ROOT_URL}/admin/login`, formValues);

  return {
    type: SIGNIN_USER,
    payload: request
  };
}

export function signInUserSuccess(user) {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: user
  };
}

export function signInUserFailure(error) {
  return {
    type: SIGNIN_USER_FAILURE,
    payload: error
  };
}



export function forgotPassword(formValues) {
  //token = localStorage.getItem('jwtToken');
  const request = axios.post(`${ROOT_URL}/password/forgot`, formValues);

  return {
    type: FORGOT_PASSWORD,
    payload: request
  };
}

export function forgotPasswordSuccess(user) {
  return {
    type: FORGOT_PASSWORD_FULFILLED,
    payload: user
  };
}

export function forgotPasswordFailure(error) {
  return {
    type: FORGOT_PASSWORD_FAILURE,
    payload: error
  };
}


export function forgotSubdomain(formValues) {
  //token = localStorage.getItem('jwtToken');
  const request = axios.post(`${ROOT_URL}/user/subdomains`, formValues);

  return {
    type: FORGOT_SUBDOMAIN,
    payload: request
  };
}

export function forgotSubdomainSuccess(user) {
  return {
    type: FORGOT_SUBDOMAIN_FULFILLED,
    payload: user
  };
}

export function forgotSubdomainFailure(error) {
  return {
    type: FORGOT_SUBDOMAIN_FAILURE,
    payload: error
  };
}

export function resetPassword(formValues) {

  const request = axios.post(`${ROOT_URL}/password/reset`, formValues);

  return {
    type: PASSWORD_RESET,
    payload: request
  };
}

export function resetPasswordSuccess(user) {
  return {
    type: PASSWORD_RESET_FULFILLED,
    payload: user
  };
}

export function resetPasswordFailure(error) {
  return {
    type: PASSWORD_RESET_FAILURE,
    payload: error
  };
}

export function activateAccount(formValues) {

  const request = axios.post(`${ROOT_URL}/user/activate`, formValues);

  return {
    type: ACTIVATE_ACCOUNT,
    payload: request
  };
}

export function activateAccountSuccess(user) {
  return {
    type: ACTIVATE_ACCOUNT_FULFILLED,
    payload: user
  };
}

export function activateAccountFailure(error) {
  return {
    type: ACTIVATE_ACCOUNT_FAILURE,
    payload: error
  };
}


export function logoutUser() {

  return {
    type: LOGOUT_USER
  };
}
export function updateUserEmail(email) {
  return {
    type: UPDATE_USER_EMAIL,
    payload:email
  };
}


export function updateUserProfile(formValues) {
  token = localStorage.getItem('jwtToken');
  const request = axios({
    method: 'PUT',
    data: formValues,
    url: `${ROOT_URL}/user/update`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return {
    type: UPDATE_USER_PROFILE,
    payload: request
  };
}

export function updateUserProfileSuccess(user) {
  return {
    type: UPDATE_USER_PROFILE_SUCCESS,
    payload: user
  };
}

export function updateUserProfileFailure(error) {
  return {
    type: UPDATE_USER_PROFILE_FAILURE,
    payload: error
  };
}

export function updatePassword(formValues) {
  token = localStorage.getItem('jwtToken');
  const request = axios({
    method: 'POST',
    data: formValues,
    url: `${ROOT_URL}/changepassword`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return {
    type: UPDATE_PASSWORD,
    payload: request
  };
}

export function updatePasswordSuccess(user) {
  return {
    type: UPDATE_PASSWORD_SUCCESS,
    payload: user
  };
}

export function updatePasswordFailure(error) {
  return {
    type: UPDATE_PASSWORD_FAILURE,
    payload: error
  };
}

export function fetchLoggedInUser(){
  token = localStorage.getItem('jwtToken');
  const request = axios.get(`${ROOT_URL}/user/details`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return{
    type: FETCH_LOGGED_IN_USER,
    payload: request
  }
}

export function fetchLoggedInUserSuccess(user){

  return{
    type: FETCH_LOGGED_IN_USER_SUCCESS,
    payload: user
  }
}

export function fetchLoggedInUserFailure(error){
  return{
    type: FETCH_LOGGED_IN_USER_FAILURE,
    payload: error
  }
}

export function verifySubdomain(subdomain){
  const request = axios({
    method: 'POST',
    data: {sub_domain: subdomain},
    url: `${ROOT_URL}/subdomain/verify`
  });
  return{
    type: VERIFY_SUBDOMAIN,
    payload: request
  }
}

export function sendContactusEmail(formValues) {
  token = localStorage.getItem('jwtToken');
  const request = axios.post(`${ROOT_URL}/super/contact`, formValues);

  return {
    type: SEND_CONTACTUS_EMAIL,
    payload: request
  };
}

export function sendReportBugEmail(formValues) {
  token = localStorage.getItem('jwtToken');
  const request = axios.post(`${ROOT_URL}/super/reportBug`, formValues);

  return {
    type: SEND_REPORT_BUG_EMAIL,
    payload: request
  };
}

export function fetchUserSettings(){
  token = localStorage.getItem('jwtToken');
  const request = axios.get(`${ROOT_URL}/display/settings/get`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return{
    type: FETCH_USER_SETTINGS,
    payload: request
  }
}

export function fetchUserSettingsSuccess(settings){
  return{
    type: FETCH_USER_SETTINGS_SUCCESS,
    payload: settings
  }
}

export function fetchUserSettingsFailure(error){
  return{
    type: FETCH_USER_SETTINGS_FAILURE,
    payload: error
  }
}


export function updateUserSettings(formValues) {
  token = localStorage.getItem('jwtToken');
  const request = axios({
    method: 'PUT',
    data: {settings: formValues},
    url: `${ROOT_URL}/display/settings/update`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return {
    type: UPDATE_USER_SETTINGS,
    payload: request
  };
}

export function updateUserSettingsSuccess(data) {
  return {
    type: UPDATE_USER_SETTINGS_SUCCESS,
    payload: data
  };
}

export function updateUserSettingsFailure(error) {
  return {
    type: UPDATE_USER_SETTINGS_FAILURE,
    payload: error
  };
}


export function updateUserPreferences(formValues) {

  token = localStorage.getItem('jwtToken');
  const request = axios({
    method: 'PUT',
    data: formValues,
    url: `${ROOT_URL}/user/preferences`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return {
    type: UPDATE_USER_PREFERENCES,
    payload: request
  };
}

export function updateUserPreferencesSuccess(data) {
  return {
    type: UPDATE_USER_PREFERENCES_SUCCESS,
    payload: data
  };
}

export function updateUserPreferencesFailure(error) {
  return {
    type: UPDATE_USER_PREFERENCES_FAILURE,
    payload: error
  };
}

export function fetchUserPreferences() {
  //check if the token is still valid, if so, get me from the server
  token = localStorage.getItem('jwtToken');
  const request = axios({
    method: 'get',
    url: `${ROOT_URL}/user/preferences`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: FETCH_USER_PREFERENCES,
    payload: request
  };
}

export function fetchUserPreferencesSuccess(data) {
  return {
    type: FETCH_USER_PREFERENCES_SUCCESS,
    payload: data
  };
}

export function verifyActivationToken(data){
  const request = axios({
    method: 'POST',
    data: data,
    url: `${ROOT_URL}/token/vetify`
  });
  return{
    type: VERIFY_ACTIVATION_TOKEN,
    payload: request
  }
}

export function fetchUserPermissions(data){
  token = localStorage.getItem('jwtToken');
  const request = axios({
    method: 'GET',
    data: data,
    url: `${ROOT_URL}/fetchpermission`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return{
    type: FETCH_USER_PERMISSIONS,
    payload: request
  }
}

export function fetchUserPermissionsSuccess(data) {
  return {
    type: FETCH_USER_PERMISSIONS_SUCCESS,
    payload: data
  };
}
