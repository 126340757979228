import React, {Component} from 'react';
import {Route, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import BigCalendar from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import {yyyymmdd, mysqlDateToJavascript} from "../../../constants";
import $ from 'jquery';
import { fetchCalendarEvents } from '../../../actions/quotes';
const localizer = BigCalendar.momentLocalizer(moment)


const events = [
    {
      allDay: false,
      end: new Date('January 11, 2019 20:00:00'),
      start: new Date('January 09, 2019 06:00:00'),
      title: 'hi',
    }

];

class Calendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      events: []
    };
    this.onNavigate = this.onNavigate.bind(this);
  }

  onNavigate(date){
    console.log('aaaaa', yyyymmdd(date));
    var d = yyyymmdd(date);
    this.fetchRecords(d[0])
  }

  fetchRecords(date){
    this.setState({events: []});
    this.props.fetchCalendarEvents({date: date}).then((response) => {
      console.log(response);
      var events = response.value.data.payload;
      var eventsArray = [];
      if(events.length > 0){
        for(var i=0; i < events.length; i++){
          var obj = events[i];
          obj = Object.assign(obj, {allDay: true, start: mysqlDateToJavascript(obj.start), end: mysqlDateToJavascript(obj.end)});
          eventsArray.push(obj);
        }
      }
      console.log('lllllll', eventsArray);
      this.setState({events: eventsArray});
    })
  }

  componentDidMount() {
    var current_date = yyyymmdd(new Date());
    this.fetchRecords(current_date[0]);
    /*var objThis = this
    setTimeout(function(){
      objThis.setState({events: [
          {
            allDay: true,
            start: new Date('February 24, 2019 20:00:00'),
            end: new Date('March 1, 2019 06:00:00'),
            title: 'Name: Neeraj Thakur<br />here',

          },
          {
            allDay: true,
            start: new Date('February 25, 2019 20:00:00'),
            end: new Date('March 2, 2019 06:00:00'),
            title: 'Name: Neeraj',

          },
          {
            allDay: true,
            start: new Date('February 25, 2019 20:00:00'),
            end: new Date('March 2, 2019 06:00:00'),
            title: 'Name: Neeraj',

          },
          {
            allDay: true,
            start: new Date('February 25, 2019 20:00:00'),
            end: new Date('March 2, 2019 06:00:00'),
            title: 'Name: Neeraj',

          },
          {
            allDay: true,
            start: new Date('February 25, 2019 20:00:00'),
            end: new Date('March 2, 2019 06:00:00'),
            title: 'Name: Neeraj',

          }
      ]})
    },1000)*/
  }

  componentDidUpdate() {}

  componentWillReceiveProps(nextProps) {}

  render() {
    return (<div className="dashboard-content">
      <div className="content sm-gutter">
        <div className="container-fluid p-t-0 p-b-25 sm-padding-10 ">
          <div className="row">
            <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
              <BigCalendar
                popup
                localizer={localizer}
                events={this.state.events}
                startAccessor="start"
                endAccessor="end"
                onNavigate={this.onNavigate}
                views={{ month: true, week: true }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>);
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
		fetchCalendarEvents: (data) => {
      return dispatch(fetchCalendarEvents(data));
    }


  };
}

export default connect(null,mapDispatchToProps)(Calendar);
