import React, {Component} from 'react';
import {Route, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import queryString from 'query-string';
import Moment from 'react-moment';
import 'moment-timezone';
import $ from 'jquery';
import { fetchNotifications, resetNotificationCounter } from '../../../actions/quotes';
import CurrencyFormat from 'react-currency-format';
import {objToQueryParams, formatDayNight, mysqlDateToJavascript, yyyymmdd, API_ROOT} from '../../../constants';

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
      allNotificationData: null
    };
  }

  componentDidMount() {
    this.fetchNotifications({page: 1});
  }

  componentDidUpdate() {

  }

  fetchNotifications(params){
		if(!params){
      params = {};
    }
    this.setState({fetchingNotifications: true});
		this.props.fetchNotifications(params).then((response) => {
      this.setState({fetchingNotifications: false});
			if(response.value.data.result === 'success'){
        var notifications = response.value.data.payload.data;
        var allNotificationData = response.value.data.payload;
				this.setState({notifications: notifications, allNotificationData:allNotificationData});
			}
		})
	}

  resetNotificationCounter(e){
    e.preventDefault();
    this.props.resetNotificationCounter().then((response) => {
      if(response.value.data.result === 'success'){
        $("#notification_counter").removeClass('online-icon');
      }
    });
  }

  markAsRead(e, notification_id){
    e.preventDefault();
    $("#notification_id_"+notification_id).removeClass('mark');
  }


  componentWillReceiveProps(nextProps) {}

  render() {
    return (
      <div className="notifications-block notification-list dropdown">
        <a href="javascript:;" className="nav-link-dropdown"  data-toggle="dropdown" onClick = {(e) => this.resetNotificationCounter(e)}>
            <ion-icon name="notifications-outline"></ion-icon>
            <span id="notification_counter" className={(this.state.notifications.length > 0 && this.state.notifications[0].notification_cnt > 0)?"online-icon":""}></span>
        </a>
        <div className="dropdown-menu notification-toggle" role="menu" aria-labelledby="notification-center">
          <div className="notification-panel">
            <div className="notification-body scrollable">
              {
                this.state.notifications.length > 0 && this.state.notifications.map((obj, index) => {
                  return(
                    <div className="notification-item  clearfix" key = {obj.id}>
                      <div className="heading">
                        <a href={obj.type_url} target="_blank" className=" pull-left" onClick = {(e) => this.markAsRead(e, obj.id)}>

                          <span className="" dangerouslySetInnerHTML = {{__html: obj.message}} />
                        </a>
                        <span className="pull-right time"><Moment format="DD MMM, YY h:mm a" fromNow fromNowDuring={36000000}>{obj.created_at+'-0000'}</Moment></span>
                      </div>
                      <div className="option">
                        <a href="#" id = {"notification_id_"+obj.id} className={obj.read_status == 0?"mark":''} onClick = {(e) => this.markAsRead(e, obj.id)}></a>
                      </div>
                    </div>
                  )
                })
              }
            </div>
            {/*<div className="notification-footer text-center">
              <a href="#" className="">Load more</a>

            </div>*/}
          </div>
        </div>
      </div>
    );
  }
}



const mapDispatchToProps = (dispatch) => {
  return {
    fetchNotifications: (data) => {
      return dispatch(fetchNotifications(data));
    },
    resetNotificationCounter: (data) => {
      return dispatch(resetNotificationCounter(data));
    }
  };
}

export default connect(null, mapDispatchToProps)(Notifications);
