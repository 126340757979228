import React, { Component } from 'react';
import { Route, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Moment from 'react-moment';
import 'moment-timezone';
import {objToQueryParams, WEBSITE_TITLE, yyyymmdd} from "../../../constants";
import { cancelledTripsReport} from '../../../actions/quotes';
import MetaTags from 'react-meta-tags';
import Loader from '../../../components/Loader';
import ReactPaginate from 'react-paginate';
import $ from 'jquery';
import CurrencyFormat from 'react-currency-format';



class CancelledTripsTab extends Component {
  constructor(props){
    super(props);
    var startDate = yyyymmdd(this.props.startDate);
    var endDate = yyyymmdd(this.props.endDate);
    this.state = {
      fetchingReport: false,
      record: null,
      startDate: startDate[0],
      endDate: endDate[0],
      selectedDestination: this.props.selectedDestination
    }
  }

  componentDidMount() {
    $("body").addClass('nav-webe-hide');
    this.fetchCancelledTripsReport({from: this.state.startDate, to: this.state.endDate, destinations: this.state.selectedDestination});
  }
  fetchCancelledTripsReport(params){
    if(!params){
      params = {};
    }
    this.setState({fetchingReport: true});
    this.props.cancelledTripsReport(params).then((response) => {
      this.setState({fetchingReport: false});
      if(response.value.data.result === 'success'){
        var record = response.value.data.payload;
        this.setState({record});
      }
    });
  }

  componentWillReceiveProps(nextProps){
    var startDateOld = yyyymmdd(this.props.startDate);
    var endDateOld = yyyymmdd(this.props.endDate);
    var startDate = yyyymmdd(nextProps.startDate);
    var endDate = yyyymmdd(nextProps.endDate);
    if(startDateOld[0] != startDate[0] || endDateOld[0] != endDate[0] || this.props.selectedDestination.length != nextProps.selectedDestination.length){
      this.setState({startDate: startDate[0], endDate: endDate[0], selectedDestination: nextProps.selectedDestination});
      this.fetchCancelledTripsReport({from: startDate[0], to: endDate[0], destinations: nextProps.selectedDestination});
    }
  }




	render() {
    return (
      <div className="tab-pane active" id="cancel-trips">
        {this.state.fetchingReport && <Loader />}
        <div className="cancel-trip-block">

          {/*<div className="converted-tips-block">
            <div className="row">
              <div className="col-sm-4">
                <div className="trip-block">
                  <h4> Canceled due to personal reasons</h4>
                  <p> <span>19</span> Trips</p>
                </div>
              </div>

              <div className="col-sm-4">
                <div className="trip-block">
                  <h4>Traveler is not going due to misc reasons</h4>
                  <p> <span>06</span> Trips</p>
                </div>
              </div>

              <div className="col-sm-4">
                <div className="trip-block">
                  <h4> Others</h4>
                  <p> <span>02</span> Trips</p>
                </div>
              </div>


            </div>
          </div>*/}
          <div className="converted-tips-block">
            <div className="row">
              <div className="col-lg-3 col-md-4">
                {
                  this.state.record &&
                  <div className="trip-block">

                    <h4>Cancelled Trips</h4>
                    <p> <span>{this.state.record.length}</span> Trips</p>
                  </div>
                }
              </div>
            </div>
          </div>
          <h3 className="sub-heading">Trips Cancelled After Booking:</h3>
          <div className="converted-table table-space">
            <table className="cstm-table">
              <thead>
                <tr>
                  <th>Trip ID</th>
                  <th>Destination</th>
                  <th>Travellers Detail</th>
                  <th>Given Quotation Price</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.record && this.state.record.map((obj, index) => {
                    return (
                      <tr key={obj.id}>
                        <td><a target="_blank" href={"/admin/quotes/follow_up/detail/"+obj.id} className="text-link">{obj.id}</a></td>
                        <td>{obj.destination}</td>
                        <td>{obj.name}<p>{obj.adults} {obj.adults==1?"Adult":"Adults"} & {obj.children} {obj.children == 1?"Child":"Children"}</p></td>
                        <td><span><CurrencyFormat value={obj.total_package_price} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={obj.currency+" "} /></span></td>

                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>


    );
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
		cancelledTripsReport: (data) => {
      return dispatch(cancelledTripsReport(data));
    }
  }
}
export default connect(null, mapDispatchToProps)(CancelledTripsTab);
