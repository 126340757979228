import React, { Component } from 'react';
import { Route, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { QUERY_STATUSES } from '../../../constants';
import $ from 'jquery';

class AllQuotesFilters extends Component {
  constructor(props){
    super(props);
    var defaultColClass = "col-sm-4";
    var specialContentClass = "";
    var quickView = false;


    this.state = {
				quoteFilterData: props.quoteFilterData,
        filterSelectedValues:{
          destination: props.filterDefaultValues.destination?props.filterDefaultValues.destination:'',
          query_status: props.filterDefaultValues.query_status?props.filterDefaultValues.query_status:'',
        },
        fetchingRecords: props.fetchingRecords
	    };
  }

  componentDidMount() {
    var widthFilters = $('#filters-form-div').innerWidth();

    var heightFilters = $('#filters-form-div').innerHeight();
    $('#filters-form-div').css('width',widthFilters);
    $('.filter-height').css('height',heightFilters);

    var fixmeTop = $('.form-block').offset().top;
    if(fixmeTop){
      $(window).scroll(function() {
          var currentScroll = $(window).scrollTop();
          if (currentScroll >= fixmeTop) {
              $('body').addClass("content-fixed");
              //setTimeout(function(){

            //}, 100);
          } else {
             $('body').removeClass("content-fixed");
          }
        });
      }
  }

  applyFilters(e){
    if(e){
      e.preventDefault();
    }
    var destination = $('#destination_city').val();
    var query_status = $('#query_status').val();
    var params = {destination,query_status};
    this.props.applyFilters(params);

  }

  clearAllFilters(e){
    e.preventDefault();
    this.props.applyFilters({destination:'', query_status:''});
    document.getElementById('filters-form').reset();
  }




  componentWillReceiveProps(nextProps){
    if(!this.props.quoteFilterData && nextProps.quoteFilterData){
      this.setState({quoteFilterData: nextProps.quoteFilterData});
    }
    if(this.props.fetchingRecords != nextProps.fetchingRecords){
      this.setState({fetchingRecords: nextProps.fetchingRecords});
    }
    if(this.props.filterDefaultValues.destination != nextProps.filterDefaultValues.destination){
      this.setState({destination: nextProps.filterDefaultValues.destination});
    }
    if(this.props.filterDefaultValues.query_status != nextProps.filterDefaultValues.query_status){
      this.setState({query_status: nextProps.filterDefaultValues.query_status});
    }
  }

	render() {
    console.log(this.state.quoteFilterData);
    return (
      <div className = "row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="card info-card card-borderless tab-ui-block shadow-card filter-block-right">
            <div className="head-block-filter">
              <h2>Filters</h2>
              <a href="javascript:void(0);" onClick={(e) => this.clearAllFilters(e)}>CLEAR ALL</a>
            </div>
            <div className="form-block" id="filters-form-div">
              <form  id="filters-form">
                <div className = "row">
                  <div className="col-lg-2 col-md-2 col-sm-12 col-xs-2">
                  <div className="form-group">
                    <label>Destination</label>
                    <div className="controls custom-select">
                      <select className=" cs-select cs-skin-slide " id = "destination_city" onChange={(e) => this.applyFilters()} defaultValue={this.state.filterSelectedValues.destination}>
                        <option value="" key='-1' >Select</option>
                        {
                          this.state.quoteFilterData && this.state.quoteFilterData.length > 0
                          && this.state.quoteFilterData.map((obj, index) => (
                            <option value={obj.destination} key={index}>{obj.destination}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12 col-xs-2">
                  <div className="form-group">
                    <label>Status</label>
                    <div className="controls custom-select">
                      <select className="cs-select cs-skin-slide" data-init-plugin="select2" id = "query_status" onChange = {(e) => this.applyFilters()} defaultValue={this.state.filterSelectedValues.query_status}>
                        <option value="" key='-1' >Select</option>
                        {
                          QUERY_STATUSES.map((obj, index) => (
                            index > 3 && <option value={index} key={index}>{obj}</option>
                          ))
                        }

                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {/*<div className="submit-filter">
                <button  className="btn btn-complete" onClick = {(e) => this.applyFilters(e)}>Apply Filters {this.state.fetchingRecords && <i className="fa fa-spinner fa-spin"></i>}</button>
              </div>*/}
            </form>
            </div>
          </div>
        </div>
      </div>
    );
	}
}

const mapStatesToProps = (state, ownProps) => {
  return {
    quoteFilterData: state.quote.all_quotes_filters?state.quote.all_quotes_filters:null,
	};
}
export default connect(mapStatesToProps,null)(AllQuotesFilters);
