import React, { Component } from 'react';
import { Route, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Moment from 'react-moment';
import 'moment-timezone';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import {objToQueryParams, WEBSITE_TITLE, yyyymmdd} from "../../../constants";
import { getDestinationFilterValues} from '../../../actions/quotes';
import MetaTags from 'react-meta-tags';
import Loader from '../../../components/Loader';
import ReactPaginate from 'react-paginate';
import $ from 'jquery';
import PerformanceTab from './PerformanceTab';
import RevenueTab from './RevenueTab';
import ConvertedTripsTab from './ConvertedTripsTab';
import CancelledTripsTab from './CancelledTripsTab';


class Stats extends Component {
  constructor(props){
    super(props);
    var endDate = new Date();
    var startDate = new Date(endDate.getTime() - (30) * 24 * 60 * 60 * 1000);
    this.state = {
      currentTab: this.props.match.params.tab,
      endDate: endDate,
      startDate: startDate,
      destinations: null,
      selectedDestination: [],
      destinationArray: []
    }

    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
  }

  componentDidMount() {
    $("body").addClass('nav-webe-hide');
    this.fetchDestinations();
  }

  fetchDestinations(){
    this.props.getDestinationFilterValues().then((response) => {
      if(response.value.data.result == 'success'){
        this.setState({destinations: response.value.data.payload});
      }
    });
  }

  componentWillReceiveProps(nextProps){
    if(this.props.match.params.tab != nextProps.match.params.tab){
      this.setState({currentTab: nextProps.match.params.tab})
    }
  }

  applyDateFilter(e, date_type){
    console.log(e);
  }

  handleStartDateChange(date) {
    if(date.getTime() > this.state.endDate.getTime()){
      return;
    }
    this.setState({
      startDate: date
    });
  }
  handleEndDateChange(date) {
    if(date.getTime() < this.state.endDate.getTime()){
      return;
    }
    this.setState({
      endDate: date
    });
  }

  handleDestinationChange = (selectedDestination) => {
    if(selectedDestination.length > 6){
      selectedDestination.pop();
    }
    var destinationArray = [];
    for(var i=0; i < selectedDestination.length; i++){
      destinationArray.push(selectedDestination[i].value);
    }
    this.setState({selectedDestination, destinationArray});
  }


  render() {
    return (
      <div className="dashboard-content">
        <div className="content sm-gutter">
          <div className="container-fluid p-t-0 p-b-25 sm-padding-10 quotes-request-title  view-report-block">
            <div className="header-block-ui">
              <h3>Statistics</h3>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card info-card card-borderless shadow-card tab-ui-block">
                <ul className="nav nav-tabs nav-tabs-simple" role="tablist" data-init-reponsive-tabs="dropdownfx">
                  <li className="nav-item">
                    <Link className={this.state.currentTab=='performance'?'active':''} to="/admin/stats/performance">Performance</Link>
                  </li>
                  <li className="nav-item">
                    <Link className={this.state.currentTab=='revenue'?'active':''} to="/admin/stats/revenue">Revenue</Link>
                  </li>
                  <li className="nav-item">
                    <Link className={this.state.currentTab=='converted-trips'?'active':''} to="/admin/stats/converted-trips">Converted Trips</Link>
                  </li>
                  <li className="nav-item">
                    <Link className={this.state.currentTab=='cancelled-trips'?'active':''} to="/admin/stats/cancelled-trips">Cancelled Trips</Link>
                  </li>
                </ul>
                <div className="datepicker-filter">
                  <div className="datepicker-block">
                    <label>From</label>
                    <div className="custom-datepicker  days-input">
                      <div className="input-group date">

                        <DatePicker
                          selected={this.state.startDate}
                          onChange={this.handleStartDateChange}
                          className="form-control textfield"
                        />
                        <div className="input-group-append ">
                          <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="datepicker-block">
                    <label>To</label>
                    <div className=" custom-datepicker days-input">
                      <div className="input-group date">
                        <DatePicker
                          selected={this.state.endDate}
                          onChange={this.handleEndDateChange}
                          className="form-control textfield"
                        />
                        <div className="input-group-append ">
                          <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="destination-field" style = {{width: '600px'}}>

                      {this.state.destinations && this.state.currentTab != 'revenue' &&
                      <Select
                        placeholder='You can select upto 6 destinations...'
                        name='destination'
                        className = ""
                        closeMenuOnSelect={true}
                        value={this.state.selectedDestination}
                        onChange={this.handleDestinationChange}
                        options={this.state.destinations}
                        isMulti={true}/> }
                      {/*<select className=" cs-select cs-skin-slide " >
                          <option>Select Destination</option>
                          <option>Switzerland</option>
                          <option>Goa</option>
                          <option>Dubai</option>
                          <option>Shimla</option>
                          <option>Manali</option>
                      </select>*/}

                  </div>

                </div>
                <div className="tab-content p-0">
                  {this.state.currentTab == 'performance' && <PerformanceTab selectedDestination = {this.state.destinationArray} startDate = {this.state.startDate} endDate = {this.state.endDate}/>}
                  {this.state.currentTab == 'revenue' && <RevenueTab selectedDestination = {this.state.destinationArray} startDate = {this.state.startDate} endDate = {this.state.endDate} />}
                  {this.state.currentTab == 'converted-trips' && <ConvertedTripsTab selectedDestination = {this.state.destinationArray} startDate = {this.state.startDate} endDate = {this.state.endDate} />}
                  {this.state.currentTab == 'cancelled-trips' && <CancelledTripsTab selectedDestination = {this.state.destinationArray} startDate = {this.state.startDate} endDate = {this.state.endDate} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
	}
}

const mapStatesToProps = (state, ownProps) => {
  return {
    quotes: state.quote.new_quotes,
		status: state.quote.status,
    rejection_reasons: state.quote.rejection_reasons?state.quote.rejection_reasons:null,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
		getDestinationFilterValues: (data) => {
      return dispatch(getDestinationFilterValues(data));
    }
  }
}
export default connect(null, mapDispatchToProps)(Stats);
