import React, { Component } from 'react';
import { Route, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Moment from 'react-moment';
import CurrencyFormat from 'react-currency-format';
import 'moment-timezone';
import {objToQueryParams, WEBSITE_TITLE, yyyymmdd, numberWithCommas} from "../../../constants";
import { revenueReport } from '../../../actions/quotes';
import MetaTags from 'react-meta-tags';
import Loader from '../../../components/Loader';
import ReactPaginate from 'react-paginate';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import $ from 'jquery';

const monthsArray = ["Jan","Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

class RevenueTab extends Component {
  constructor(props){
    super(props);
    var startDate = yyyymmdd(this.props.startDate);
    var endDate = yyyymmdd(this.props.endDate);
    this.state = {
      totalRevenue: 0,
      totalConversions: 0,
      fetchingReport: false,
      record: null,
      startDate: startDate[0],
      endDate: endDate[0],
      graphData: null
    }
  }

  componentDidMount() {
    $("body").addClass('nav-webe-hide');
    this.fetchRevenueReport({from: this.state.startDate, to: this.state.endDate});
  }

  fetchRevenueReport(params){
    if(!params){
      params = {}
    }
    this.setState({fetchingReport: true});
    this.props.revenueReport(params).then((response) => {
      this.setState({fetchingReport: false});
      if(response.value.data.result === 'success'){
        var record = response.value.data.payload;
        var recordSet = [];
        var totalConversions = 0;
        var totalRevenue = 0;
        if(record.revenue && record.revenue.length > 0){
          for(var i=0; i < record.revenue.length; i++){
            totalRevenue += record.revenue[i].total;
            totalConversions += record.converted[i].total;
            recordSet.push({name: monthsArray[record.revenue[i].month - 1]+", "+record.revenue[i].YEAR, Revenue: record.revenue[i].total, total_revenue: numberWithCommas(record.revenue[i].total), Conversions: record.converted[i].total})
          }
        }
        this.setState({record, graphData: recordSet, totalRevenue, totalConversions});
      }
    })
  }

  componentWillReceiveProps(nextProps){
    var startDateOld = yyyymmdd(this.props.startDate);
    var endDateOld = yyyymmdd(this.props.endDate);
    var startDate = yyyymmdd(nextProps.startDate);
    var endDate = yyyymmdd(nextProps.endDate);
    if(startDateOld[0] != startDate[0] || endDateOld[0] != endDate[0]){
      this.setState({startDate: startDate[0], endDate: endDate[0]});
      this.fetchRevenueReport({from: startDate[0], to: endDate[0]});
    }
  }




	render() {
    return (
      <div className="tab-pane active " id="revenue">
        {this.state.fetchingReport && <Loader />}
        <div className="revenue-block">
          <div className="graph-block" >
            <div className="graph-container">{this.state.graphData && <LineChart
              width={1000}
              height={400}
              data={this.state.graphData}
              margin={{
                top: 5, right: 30, left: 20, bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis yAxisId="left" />
              <YAxis yAxisId="right" orientation="right" />
              <Tooltip />
              <Legend />
              <Line yAxisId="left" type="monotone" dataKey="Revenue" stroke="#8884d8" activeDot={{ r: 8 }} />
              <Line yAxisId="right" type="monotone" dataKey="Conversions" stroke="#82ca9d" />
            </LineChart>
          }
        </div>
          </div>

            {
              this.state.graphData &&
              <div className="reward-block">
                <h3 className="sub-heading">Revenue</h3>
                <div className="reward-table">
                  <table className="cstm-table">
                    <thead className="">
                      <tr>
                        <th>Conversions</th>
                        <th>Revenue</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{this.state.totalConversions}</td>
                        <td><CurrencyFormat value={this.state.totalRevenue} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix="INR "/></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            }
          </div>
        </div>
    );
	}
}



const mapDispatchToProps = (dispatch) => {
  return {
		revenueReport: (data) => {
      return dispatch(revenueReport(data));
    }
  }
}
export default connect(null, mapDispatchToProps)(RevenueTab);
