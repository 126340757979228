import React, { Component } from 'react';
import { Route, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import {objToQueryParams, WEBSITE_TITLE, yyyymmdd} from "../../../constants";
import { convertedTripsReport } from '../../../actions/quotes';
import MetaTags from 'react-meta-tags';
import Loader from '../../../components/Loader';
import ReactPaginate from 'react-paginate';
import $ from 'jquery';
import Moment from 'react-moment';
import 'moment-timezone';
import CurrencyFormat from 'react-currency-format';



class ConvertedTripsTab extends Component {
  constructor(props){
    super(props);
    var startDate = yyyymmdd(this.props.startDate);
    var endDate = yyyymmdd(this.props.endDate);
    this.state = {
      fetchingReport: false,
      record: null,
      startDate: startDate[0],
      endDate: endDate[0],
      selectedDestination: this.props.selectedDestination
    }
  }

  componentDidMount() {
    $("body").addClass('nav-webe-hide');
    this.fetchConvertedTripsReport({from: this.state.startDate, to: this.state.endDate, destinations: this.state.selectedDestination});
  }

  fetchConvertedTripsReport(params){
    if(!params){
      params = {};
    }
    this.setState({fetchingReport: true});
    this.props.convertedTripsReport(params).then((response) => {
      if(response.value.data.result === 'success'){
        var record = response.value.data.payload;
        this.setState({record});
      }
    });
  }

  componentWillReceiveProps(nextProps){
    var startDateOld = yyyymmdd(this.props.startDate);
    var endDateOld = yyyymmdd(this.props.endDate);
    var startDate = yyyymmdd(nextProps.startDate);
    var endDate = yyyymmdd(nextProps.endDate);
    if(startDateOld[0] != startDate[0] || endDateOld[0] != endDate[0] || this.props.selectedDestination.length != nextProps.selectedDestination.length){
      this.setState({startDate: startDate[0], endDate: endDate[0], selectedDestination: nextProps.selectedDestination});
      this.fetchConvertedTripsReport({from: startDate[0], to: endDate[0], destinations: nextProps.selectedDestination});
    }
  }




	render() {
    return (
      <div className="tab-pane active" id="converted-trips">
        <div className="converted-block">
          {/*<div className="filter-block">
            <label className="label-heading">Select view type</label>
            <ul>
              <li>
                <input type="radio" name="trips-type" id="trip1" checked="" />
                <label for="#trip1">Converted by you</label>
              </li>
              <li>
                <input type="radio" name="trips-type" id="trip2" />
                <label for="#trip2">Converted by other</label>
              </li>

            </ul>
          </div>
          */}

          <div className="converted-tips-block">
            <div className="row">
              <div className="col-lg-3 col-md-4">
                {
                  this.state.record &&
                  <div className="trip-block">

                    <h4> converted Trips</h4>
                    <p> <span>{this.state.record.length}</span> Trips</p>
                  </div>
                }
              </div>
            </div>
          </div>

          <div className="converted-table table-space">
            <table className="cstm-table">
              <thead>
                <tr>
                  <th>Trip ID</th>
                  <th>Destination</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Travellers Detail</th>
                  <th>Given Quotation Price</th>

                </tr>
              </thead>
              <tbody>
                {
                  this.state.record && this.state.record.map((obj, index) => {
                    return (
                      <tr>
                        <td><a target="_blank" href={"/admin/quotes/follow_up/detail/"+obj.id} className="text-link">{obj.id}</a></td>
                        <td>{obj.destination}</td>
                        <td><Moment format="DD MMM, YY"  >{obj.start_date}</Moment></td>
                        <td><Moment add={{ days: obj.days }} format="DD MMM, YY"  >{obj.start_date}</Moment></td>
                        <td>{obj.name}<p>{obj.adults} {obj.adults==1?"Adult":"Adults"} & {obj.children} {obj.children == 1?"Child":"Children"}</p></td>
                        <td><span><CurrencyFormat value={obj.total_package_price} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={obj.currency+" "} /></span></td>
                      </tr>
                    )
                  })
                }
                

              </tbody>
            </table>
          </div>

        </div>
      </div>


    );
	}
}


const mapDispatchToProps = (dispatch) => {
  return {
		convertedTripsReport: (data) => {
      return dispatch(convertedTripsReport(data));
    }
  }
}
export default connect(null, mapDispatchToProps)(ConvertedTripsTab);
