import React, { Component } from 'react';
import { Redirect, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import {API_ROOT} from '../../../constants';
import {lastSentQuotations, lastSentQuotesDetail} from "../../../actions/quotes";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import Loader from '../../../components/Loader';


class LastSentQuotations extends Component {
	constructor(props){
	    super(props);
      this.state = {
				trip_id: props.trip_id,
				quoteDetail: null,
				quoteListingData: null,
				quoteListingRequest: false,
				quoteDetailRequest: false,
				quoteDetailData: null,
        lastSentQuotesDestination: props.lastSentQuotesDestination,
        modal: props.openLastSentQuotesPopup,
        text: '',
        picture: '',
        dataLoaded: false,
        redirectBack: false,
        recordData: null,
				selectedTripId: null,

      }
      this.toggle = this.toggle.bind(this);

	}


  componentDidMount(){
		this.setState({quoteListingRequest: true});
    this.props.lastSentQuotations({destination: this.state.lastSentQuotesDestination}).then((response) => {
			this.setState({quoteListingRequest: false});
			if(response.value.data.result === 'success'){
				var records = response.value.data.payload;
				var selectedTripId = null;
				if(records.length > 0){
					selectedTripId = records[0].id;
				}
				this.setState({quoteListingData: records, selectedTripId: selectedTripId});
				if(selectedTripId){
					this.fetchQuoteDetail(selectedTripId);
				}
			}
    })
  }

	fetchQuoteDetail(quote_id){
		this.setState({quoteDetailRequest: true});
    this.props.lastSentQuotesDetail({id: quote_id}).then((response) => {
			this.setState({quoteDetailRequest: false});
			if(response.value.data.result === 'success'){
				var quoteDetail = response.value.data.payload;
				this.setState({quoteDetail: quoteDetail});
			}
    })
	}

  componentWillReceiveProps(nextProps){

	}

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
    this.props.closeLastSentQuotesPopup();

  }

	loadQuoteDetail(e, quote_id){
		e.preventDefault();
		this.setState({selectedTripId: quote_id});
		this.fetchQuoteDetail(quote_id);
	}


  	render() {
      const { handleSubmit, pristine, reset, submitting, change } = this.props;
      const hotelData = this.state.recordData;

      return (

        <Modal isOpen={this.state.modal} toggle={this.toggle} className="last-quotes-modal" >
          <ModalHeader toggle={this.toggle} charCode="X">Last 15 Quotes</ModalHeader>
          <ModalBody className = "modal-body modal-content">
						<div className="lq-block">
							{
								this.state.quoteListingRequest == false && (!this.state.quoteListingData || this.state.quoteListingData.length <= 0) &&
								<div>No quotes found for this destination.</div>
							}
																			<div className="lq-left-bock">
																				<div className="quote-listing">
																						<ul>
																							{
																								this.state.quoteListingRequest == false && this.state.quoteListingData && this.state.quoteListingData.length > 0 && this.state.quoteListingData.map((obj, index) => {
																									return(
																										<li className={(obj.id == this.state.selectedTripId)?"active":""} key = {obj.id}>
																											<a href = "#" onClick = {(e) => this.loadQuoteDetail(e, obj.id)}>
			 																									<h4>Trip Id {obj.id}</h4>
			 																									<div className="detail-tour">
			 																										 <label>Name</label>
			 																										 <span>{obj.name}</span>
			 																									</div>
																												<div className="detail-tour">
			 																										 <label>DESTINATION</label>
			 																										 <span>{obj.destination}</span>
			 																									</div>
																												<div className="detail-tour">
			 																										 <label>TRAVELERS</label>
			 																										 <span>2 Adults · 0 Children</span>
			 																									</div>
			 																									<div className="detail-tour">
			 																										 <label>DURATION</label>
			 																										 <span>{obj.days} days & {obj.days - 1} {obj.days - 1 > 1?"nights":"night"}</span>
			 																									</div>
			 																									<div className="detail-tour">
			 																										 <label>Quoted Price</label>
			 																										 <span><CurrencyFormat value={obj.total_package_price} decimalScale={2} fixedDecimalScale={true} displayType={'text'} suffix={" "+obj.price_type} thousandSeparator={true} prefix={obj.currency + ' '}/></span>
			 																									</div>
																											</a>
		 																							 </li>
																									)
																								})
																							}
																							{
																								(this.state.quoteListingRequest == true) && <li className="relative-loader"><Loader /></li>
																							}

																						</ul>
																				 </div>

																			</div>
																			<div className="lq-right-bock relative-loader">
																				{!this.state.quoteDetailRequest && this.state.quoteDetail &&
																				 <div className="right-content-block">
																					 <div className="lq-top-block">
																							 <div className="top-left-block"> <h4>Trip Id {this.state.quoteDetail.query[0].trip_id}</h4></div>
																							 <div className="top-right-block">
																									<a href="javascript:;" className="btn outline-btn" data-dismiss="modal" onClick={this.toggle}>Cancel</a>
																									<a href={"/admin/quotes/add/"+this.state.trip_id+"/?selected_quote_id="+this.state.quoteDetail.quotation[0].id} className="btn fill-btn">Use this quote</a>
																							 </div>
																						</div>

																						<div className="lg-bottom-block">
																							 <div className="detail-blocks">
																							 <div className="detail-heading">
																									<h5>Basic details</h5>
																							 </div>
																							 <div className="detail-tour">
																									<div className="row">
																										<div className="col-sm-4 tour-view-list">
																											<label>DESTINATION</label>
																											<span>{this.state.quoteDetail.query[0].destination}</span>
																										</div>
																										<div className="col-sm-4 tour-view-list">
																											<label>DURATION</label>
																											<span>{this.state.quoteDetail.quotation[0].days} days & {this.state.quoteDetail.quotation[0].days - 1} {this.state.quoteDetail.quotation[0].days - 1 > 1?"nights":"night"}</span>
																										</div>
																										<div className="col-sm-4 tour-view-list">
																											<label>No. od Adults &amp; Children</label>
																											<span>{this.state.quoteDetail.query[0].adults} {this.state.quoteDetail.query[0].adults == 1?"Adult":"Adults"} · {this.state.quoteDetail.query[0].children} {this.state.quoteDetail.query[0].children == 1?"Child":"Children"}</span>
																										</div>
																										<div className="col-sm-4 tour-view-list">
																											<label>Quotation Price</label>
																											<span><CurrencyFormat value={this.state.quoteDetail.quotation[0].total_package_price} decimalScale={2} fixedDecimalScale={true} displayType={'text'} suffix={" "+this.state.quoteDetail.quotation[0].price_type} thousandSeparator={true} prefix={this.state.quoteDetail.quotation[0].currency + ' '}/></span>
																										</div>
																									</div>
																								</div>
																						 </div>



																						 <div className="detail-blocks">
																							 <div className="detail-heading">
																									<h5>{this.state.quoteDetail.quotation[0].hotels_not_included == 1?"Hotels not included":"Hotel details"}</h5>
																							 </div>
																							 <div className="detail-outer">
																									{
																										this.state.quoteDetail.quotation[0].hotels_not_included == 0 && this.state.quoteDetail.quotation[0].hotels && this.state.quoteDetail.quotation[0].hotels.map((obj,index) => {
																											return(
																												<p>Night(s) {obj.hotel_value_nights}, {obj.hotel_name}</p>
																											)
																										})
																									}
																								</div>


																						 </div>


																						 <div className="detail-blocks">
																							 <div className="detail-heading">
																									<h5>Inclusions/Exclusions </h5>
																							 </div>
																							 <div className="detail-outer">
																									<div className="inclus-exclus-block">
																										 <ul>
																											 {
																												 this.state.quoteDetail.quotation[0].inclusion && this.state.quoteDetail.quotation[0].inclusion.length > 0 && this.state.quoteDetail.quotation[0].inclusion.map((objInclusion, indexInclusion) => (objInclusion.inclusion == 1 && <li key={indexInclusion}>{
																														 objInclusion.category == 'Other Inclusions'
																															 ? "Others"
																															 : objInclusion.category
																													 }:{objInclusion.title}</li>))
																											 }
																										 </ul>
																									</div>

																									 <div className="inclus-exclus-block">
																										 <ul>
																											 {
																												 this.state.quoteDetail.quotation[0].inclusion && this.state.quoteDetail.quotation[0].inclusion.length > 0 && this.state.quoteDetail.quotation[0].inclusion.map((objInclusion, indexInclusion) => (objInclusion.inclusion != 1 && <li key={indexInclusion}>{
																														 objInclusion.category == 'Other Inclusions'
																															 ? "Others"
																															 : objInclusion.category
																													 }:{objInclusion.title}</li>))
																											 }
																										 </ul>
																									</div>
																								</div>
																						 </div>

																						 <div className="detail-blocks">
																							 <div className="detail-heading">
																									<h5>Miscellaneous</h5>
																							 </div>
																							 <div className="detail-outer">
																									<h4> Terms &amp; Conditions</h4>
																									<p dangerouslySetInnerHTML={{__html: this.state.quoteDetail.quotation[0].terms}} />
																								</div>
																						 </div>

																						</div>
																				 </div>
																			 }
																			 {
																				 this.state.quoteDetailRequest && <Loader />
																			 }
																			</div>

																	 </div>
            </ModalBody>

          </Modal>


    	);
  	}
}

const mapStatesToProps = (state) => {
  return {
    quotes: null
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    lastSentQuotations: (data) => {
      return dispatch(lastSentQuotations(data));
    },
		lastSentQuotesDetail: (data) => {
			return dispatch(lastSentQuotesDetail(data));
		}
  }
}

LastSentQuotations = connect(mapStatesToProps, mapDispatchToProps)(LastSentQuotations);
export default LastSentQuotations;
