import React, { Component } from 'react';
import { Route, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { monthsArray, ADD_NOTES_DATA } from '../../../constants';
import $ from 'jquery';

class QuoteFilters extends Component {
  constructor(props){
    super(props);
    var defaultColClass = "col-sm-4";
    var specialContentClass = "";
    var quickView = false;


    this.state = {
				quoteFilterData: props.quoteFilterData,
        filterSelectedValues:{
          departure_city: '',
          source_city: '',
          month_of_travel: '',
          no_adults: '',

        },
        fetchingRecords: props.fetchingRecords,
        listPage: props.listPage?props.listPage:null
	    };
  }

  componentDidMount() {
    var widthFilters = $('#filters-form-div').innerWidth();

    var heightFilters = $('#filters-form-div').innerHeight();
    $('#filters-form-div').css('width',widthFilters);
    $('.filter-height').css('height',heightFilters);

    var fixmeTop = $('.form-block').offset().top;
    if(fixmeTop){
      $(window).scroll(function() {
          var currentScroll = $(window).scrollTop();
          if (currentScroll >= fixmeTop) {
              $('body').addClass("content-fixed");
              //setTimeout(function(){

            //}, 100);
          } else {
             $('body').removeClass("content-fixed");
          }
        });
      }
  }

  applyFilters(e){
    if(e){
      e.preventDefault();
    }
    var destination = $('#destination_city').val();
    var departure = $('#source_city').val();
    var departure_month = $('#month_of_travel').val();
    var adults = $('#no_adults').val();
    var params = {destination,departure,departure_month,adults};
    if(this.state.listPage && this.state.listPage == 'follow_up'){
      var follow_up_status = $('#follow_up_status').val();
      var quotation_status = $('#quotation_status').val();
      params = Object.assign(params, {follow_up_status, quotation_status});
    }
    this.props.fetchRecords(params);

  }

  clearAllFilters(e){
    e.preventDefault();
    this.props.fetchRecords();
    document.getElementById('filters-form').reset();
  }

  componentWillReceiveProps(nextProps){
    if(!this.props.quoteFilterData && nextProps.quoteFilterData){
      this.setState({quoteFilterData: nextProps.quoteFilterData});
    }
    if(this.props.fetchingRecords != nextProps.fetchingRecords){
      this.setState({fetchingRecords: nextProps.fetchingRecords});
    }
  }

	render() {
    //alert(this.state.detailView);
    console.log('aaaaaa', this.state.fetchingRecords);
    return (
      <div className = "row">
        <div className="col-lg-12 col-md-4 col-sm-12 col-xs-12">
          <div className="card info-card card-borderless tab-ui-block shadow-card filter-block-right">
            <div className="head-block-filter">
              <h2>Filters</h2>
              <a href="javascript:void(0);" onClick={(e) => this.clearAllFilters(e)}>CLEAR ALL</a>
            </div>
            <div className="form-block" id = "filters-form-div">
              <form  id="filters-form">
                <div className="row">
                  <div className="col-sm-2">
                    <div className="form-group">
                      <label>Destination</label>
                      <div className="controls custom-select">
                        <select className=" cs-select cs-skin-slide " id = "destination_city" onChange={(e) => this.applyFilters()}>
                          <option value="" key='-1' >Select</option>
                          {
                            this.state.quoteFilterData && this.state.quoteFilterData.destination && this.state.quoteFilterData.destination.length > 0
                          && this.state.quoteFilterData.destination.map((obj, index) => (
                              <option value={obj} key={index}>{obj}</option>
                            ))
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="form-group">
                      <label>Source City</label>
                      <div className="controls custom-select">
                        <select className="cs-select cs-skin-slide" data-init-plugin="select2" id = "source_city" onChange = {(e) => this.applyFilters()}>
                          <option value="" key='-1' >Select</option>
                          {
                            this.state.quoteFilterData && this.state.quoteFilterData.departure && this.state.quoteFilterData.departure.length > 0
                            && this.state.quoteFilterData.departure.map((obj, index) => (
                              <option value={obj} key={index}>{obj}</option>
                            ))
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="form-group">
                      <label>Month of Travel</label>
                      <div className="controls custom-select">
                        <select className="cs-select cs-skin-slide" data-init-plugin="select2" id = "month_of_travel" onChange = {(e) => this.applyFilters()}>
                          <option value="" key='-1' >Select</option>
                          {
                            this.state.quoteFilterData && this.state.quoteFilterData.departure_month && this.state.quoteFilterData.departure_month.length > 0
                            && this.state.quoteFilterData.departure_month.map((obj, index) => (
                              obj > 0 && <option value={obj} key={index}>{monthsArray[parseInt(obj) - 1].fullName}</option>
                            ))
                          }

                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="form-group">
                      <label>No. of Adults</label>
                      <div className="controls custom-select">
                        <select className="cs-select cs-skin-slide" data-init-plugin="select2" id = "no_adults" onChange = {(e) => this.applyFilters()}>
                          <option value="" key='-1' >Select</option>
                          {
                            this.state.quoteFilterData && this.state.quoteFilterData.adults && this.state.quoteFilterData.adults.length > 0
                            && this.state.quoteFilterData.adults.map((obj, index) => (
                              <option value={obj} key={index}>{obj}</option>
                            ))
                          }
                        </select>
                      </div>
                    </div>
                  </div>

            {
              this.state.listPage && this.state.listPage == 'follow_up' &&

                <div className="col-sm-2">
                  <div className="form-group">
                    <label>Follow Up Status</label>
                    <div className="controls custom-select">
                      <select className="cs-select cs-skin-slide" data-init-plugin="select2" id = "follow_up_status" onChange = {(e) => this.applyFilters()}>
                        <option value="" key='-1' >Select</option>
                        {
                          ADD_NOTES_DATA.map((obj, index) => {
                            return(
                              <option key={index} value={obj.id}>{obj.label}</option>
                            )
                          })
                        }

                      </select>
                    </div>
                  </div>
                </div>
              }
              {
                this.state.listPage && this.state.listPage == 'follow_up' &&
                <div className="col-sm-2">
                  <div className="form-group">
                    <label>Quote status</label>
                    <div className="controls custom-select">
                      <select className="cs-select cs-skin-slide" data-init-plugin="select2" id = "quotation_status" onChange = {(e) => this.applyFilters()}>
                        <option value="">Select</option>
                        <option value="seen">Seen</option>
                        <option value="not-seen">Not seen</option>
                      </select>
                    </div>
                  </div>
                </div>

            }

          </div>
            </form>
            </div>
          </div>
        </div>
      </div>
    );
	}
}



export default QuoteFilters;
