import React, { Component } from 'react';
import { Redirect, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { ROUTES } from '../../../routesList';
import renderQuill from '../../FormFields/renderQuill';
import { Field, reduxForm, change, SubmissionError, FormSection } from 'redux-form';
import renderField from '../../FormFields/renderField';
import GoogleMaps from '../../../maps/dragDropMarker2';
import renderSelect from '../../FormFields/renderSelect';
import { stripHtmlTags, validateEmail, API_ROOT } from '../../../constants';
import { listCountries, listCountriesSuccess } from '../../../actions/cities';
// Import React FilePond
import { FilePond, File, registerPlugin } from 'react-filepond';
import { hotelAdd } from "../../../actions/quotes";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.name || values.name.trim() === '') {
    errors.name = "Enter name";
    hasErrors = true;
  }
  if (!values.city || values.city.trim() === '') {
    errors.city = "Enter city";
    hasErrors = true;
  }
  if (!values.country || values.country == '-1') {
    errors.country = "Select country";
    hasErrors = true;
  }
  return hasErrors && errors;
}

class HotelAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: props.openHotelPopup,
      text: '',
      picture: '',
      dataLoaded: false,
      redirectBack: false,
      recordData: null,
      destinationLocation: {
        latitude: null,
        longitude: null,
        address: null
      },
      countries: props.countries
    }
    this.modifyAddress = this.modifyAddress.bind(this);
    this.modifyLatLong = this.modifyLatLong.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  fetchCountries() {
    this.props.listCountries().then((response) => {
      if (response.value.data.result === 'success') {
        this.props.listCountriesSuccess(response.value.data.data);
      }
    })
  }

  handleInit() {
    console.log('FilePond instance has initialised', this.pond);
  }

  /*
  * function that modify address if address empty
  */
  modifyAddress = (address) => {
    if (address != "") {
      this.modifyState('address', address);
      this.props.change('map_address', address);
    }
  }
  /*funtion that update draft parking state
  * @param name, target name
  * @param value
  */

  modifyState(name, value) {
    //update state
    let destinationLocation = Object.assign(this.state.destinationLocation, { [name]: value }); //creating copy of object
    this.setState({ destinationLocation });
  }

  /*
  * function that modify latitude and longitude
  */
  modifyLatLong = (lat, long) => {
    if (lat !== '' && long !== '') {
      this.modifyState('latitude', lat);
      this.modifyState('longitude', long);
      this.props.change('latitude', lat);
      this.props.change('longitude', long);
    }
  }

  submitForm(values, dispatch, props) {
    var dataHotel = null;
    return this.props.hotelAdd(values)
      .then((result) => {
        if (result.value.data.result == 'success') {
          this.props.updateHotelForm(result.value.data.payload);
          this.toggle();
        }
      })
  }

  componentDidMount() {
    this.fetchCountries();
  }

  componentWillReceiveProps(nextProps) {
    //console.log('kkkkkkk-----', this.props.openHotelPopup +"-----"  + nextProps.openHotelPopup);
    if (this.props.status != nextProps.status) {
      this.setState({ countries: nextProps.countries })
    }
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
    this.props.closeHotelPopup();
  }

  render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    const hotelData = this.state.recordData;

    return (
      <Modal isOpen={this.state.modal} toggle={this.toggle} className="add-hotel-block mt-120">
        <ModalHeader toggle={this.toggle} charCode="X">Add Hotel</ModalHeader>
        <form onSubmit={handleSubmit(this.submitForm.bind(this))}>
          <ModalBody>
            <div className="content ">

              <div className="container-fluid container-fixed-lg white-bg add-hotel-block">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card card-transparent">
                      <div className="card-body">

                        <div className="row">
                          <Field
                            type="text"
                            name='name'
                            fieldClass="form-control"
                            placeholder="Hotel Name"
                            label="Name"
                            component={renderField}
                            parentDivClass="form-group col-md-6"
                          />
                          {/*<Field
                                type = "text"
                                name = 'address'
                                fieldClass = "form-control"
                                placeholder = "Address"
                                label = "Address"
                                component = {renderField}
                                parentDivClass = "form-group col-md-6"
                              />*/}

                          <Field name="star_rating" component={renderSelect} label="Hotel Rating" parentDivClass="form-group col-md-6" fieldClass="form-control" selectDataObject={[{ id: 1, value: "1 Star" }, { id: 2, value: "2 Stars" }, { id: 3, value: "3 Stars" }, { id: 4, value: "4 Stars" }, { id: 5, value: "5 Stars" }]} fieldSet={{
                            id: 'id',
                            value: 'value'
                          }} dont_show_select_option={true} />
                          <div className="col-md-6 cs-block">
                            <div className="row no-margin padding-field">
                              <div className="col-md-6">
                                {this.state.countries &&
                                  <Field name="country" id="country_id" ref='country' component={renderSelect} label="Country" parentDivClass="form-group" fieldClass="" selectDataObject={this.state.countries} fieldSet={{
                                    id: 'id',
                                    value: 'country'
                                  }} dont_show_select_option={false} />}

                              </div>
                              <Field
                                type="text"
                                name='city'
                                fieldClass="form-control"
                                placeholder="City"
                                label="City"
                                component={renderField}
                                parentDivClass="form-group col-md-6"
                              />
                            </div>
                          </div>
                          {/*<div className="form-group col-md-6 location-block">
                                  <FormSection name="googleMap">
                                    {
                                      ((this.state.id && this.state.dataLoaded) || !this.state.id) &&

                                      <GoogleMaps location={this.state.destinationLocation
                                        ? (
                                          this.state.destinationLocation.location
                                          ? this.state.destinationLocation.location
                                          : '')
                                        : ''} callbackForAddress={this.modifyAddress} callbackForLatLong={this.modifyLatLong} lat={this.state.destinationLocation
                                        ? this.state.destinationLocation.latitude
                                        : ''} long={this.state.destinationLocation
                                        ? this.state.destinationLocation.longitude
                                        : ''}
                                        longitude={this.state.destinationLocation.longitude?this.state.destinationLocation.longitude:''}
                                        latitude={this.state.destinationLocation.latitude?this.state.destinationLocation.latitude:''}
                                      />}
                                  </FormSection>
                                  <Field id="map_address" type="hidden" name="map_address" component="input"/>
                                  <Field id="latitude" type="hidden" name="latitude" component="input"/>
                                  <Field id="longitude" type="hidden" name="longitude" component="input"/>
                                </div>*/}
                          <div className="col-md-6 ">
                            <div className="row hotel-image-block">
                              <div className="form-group col-md-12 landscape_image">
                                <label>Image (Landscape)</label>
                                <FilePond ref={ref => this.pond = ref}
                                  name="file"
                                  allowMultiple={false}
                                  maxFiles={1}
                                  server={{
                                    url: API_ROOT + "/uploadattachment?type=things_to_do_landscape_image",
                                    process: {
                                      onload: (res) => {
                                        res = JSON.parse(res);
                                        if (res.result == 'success') {
                                          change('landscape_image', res.data.id);
                                          return res.data.id
                                        }
                                      }
                                    }
                                  }}
                                  allowReplace={true}
                                  oninit={() => this.handleInit()}
                                >
                                </FilePond>
                                <div>
                                  {
                                    hotelData && hotelData.landscape_image && hotelData.landscape_image.id &&
                                    <img src={hotelData.landscape_image.path} style={{ width: 100 + "%" }} />
                                  }
                                </div>
                              </div>

                            </div>
                            <Field
                              type="hidden"
                              name="landscape_image"
                              component="input"
                            />
                          </div>
                          {/*<div className = "col-md-6">
                                <Field
                                  type = "text"
                                  name = 'hotel_link'
                                  fieldClass = "form-control"
                                  placeholder = "Hotel Detail Link"
                                  label = "Hotel Detail Link"
                                  component = {renderField}
                                  parentDivClass = "form-group"
                                />
                            </div>*/}
                          <div className="form-group col-md-12 m-none">
                            <div className="checkbox check-success" style={{ visibility: 'hidden' }}>
                              <Field
                                name="status"
                                id="status_checkbox"
                                checked={true}
                                component="input"
                                type="checkbox"
                              />
                              <label htmlFor="status_checkbox">Status</label>
                            </div>
                          </div>
                          {/* <div className="form-group col-md-12">
                              <button type="submit" disabled={submitting} className="btn btn-primary btn-cons">Save {submitting && <i className="fa fa-spinner fa-spin"></i>}</button>
                            </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button type="submit" disabled={submitting} className="btn btn-primary btn-cons">Save {submitting && <i className="fa fa-spinner fa-spin"></i>}</button>
            <Button color="secondary" onClick={this.toggle}>Cancel</Button>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    countries: state.cities.countries ? state.cities.countries : null,
    status: state.cities.status ? state.cities.status : null,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    hotelAdd: (data) => {
      return dispatch(hotelAdd(data));
    },
    listCountries: (data) => {
      return dispatch(listCountries(data));
    },
    listCountriesSuccess: (data) => {
      return dispatch(listCountriesSuccess(data));
    },

  }
}

HotelAdd = connect(mapStatesToProps, mapDispatchToProps)(HotelAdd);

HotelAdd = reduxForm({
  form: 'HotelAdd',  // a unique identifier for this form
  validate,
  enableReinitialize: true
})(HotelAdd);

export default HotelAdd;
