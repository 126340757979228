import React, { Component } from 'react';
import { Route, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Moment from 'react-moment';
import 'moment-timezone';
import {objToQueryParams, WEBSITE_TITLE, inArray, mysqlDateToJavascript, yyyymmdd} from "../../../constants";
import { performanceReport } from '../../../actions/quotes';
import MetaTags from 'react-meta-tags';
import Loader from '../../../components/Loader';


class PerformanceByWeek extends Component {
  constructor(props){
    super(props);
    this.state = {
      records: null,
    }
  }

  componentDidMount() {
    this.setState({records: this.props.records, fetchingReport: this.props.fetchingReport});
  }
  componentWillReceiveProps(nextProps) {
    if(!this.props.records && nextProps.records){
      this.setState({records: nextProps.records});
    }
    this.setState({fetchingReport: nextProps.fetchingReport});

  }

  render() {
    return (
            <div className="table-right-block">
              {this.state.fetchingReport && <Loader />}
              <table className="week-table-block">
                <thead>
                  <tr>
                    {
                      this.state.records && this.state.records.week && this.state.records.week.length > 0 && this.state.records.week.map((obj, index) => {
                        var jsDate1 = mysqlDateToJavascript(obj);
                        var jsDate2 = new Date(jsDate1.getTime() + 6 * 24 * 60 * 60 * 1000 );
                        var date1 = yyyymmdd(jsDate1);
                        var date2 = yyyymmdd(jsDate2);
                        return(
                          <th key={obj}>Week{(index + 1)}<br/><small>{date1[3]} To {date2[3]}</small></th>
                        )
                      })
                    }
                  </tr>
                </thead>
                <tbody>

                  <tr>
                    <td height="52" className="no-border"></td>
                  </tr>
                  <tr>
                    {
                      this.state.records && this.state.records.week && this.state.records.week.length > 0 && this.state.records.week.map((obj, index) => {
                        var count = 0;
                        this.state.records.primary_metric && this.state.records.primary_metric.length > 0 && this.state.records.primary_metric.map((obj1, index1) => {
                          if(obj == obj1.DATE){
                            count = obj1.leads;
                          }
                        })
                        return(
                          <td key={obj}>{count}</td>
                        )
                      })
                    }
                  </tr>
                  <tr>
                    {
                      this.state.records && this.state.records.week && this.state.records.week.length > 0 && this.state.records.week.map((obj, index) => {
                        var count = 0;
                        this.state.records.primary_metric && this.state.records.primary_metric.length > 0 && this.state.records.primary_metric.map((obj1, index1) => {
                          if(obj == obj1.DATE){
                            count = obj1.quoted_trips;
                          }
                        })
                        return(
                          <td key={obj}>{count}</td>
                        )
                      })
                    }
                  </tr>
                  <tr>
                    {
                      this.state.records && this.state.records.week && this.state.records.week.length > 0 && this.state.records.week.map((obj, index) => {
                        var count = 0;
                        this.state.records.primary_metric && this.state.records.primary_metric.length > 0 && this.state.records.primary_metric.map((obj1, index1) => {
                          if(obj == obj1.DATE){
                            count = obj1.converted_trips;
                          }
                        })
                        return(
                          <td key={obj}>{count}</td>
                        )
                      })
                    }
                  </tr>

                  <tr>
                    <td height="52" className="no-border"></td>
                  </tr>
                  <tr>
                    {
                      this.state.records && this.state.records.week && this.state.records.week.length > 0 && this.state.records.week.map((obj, index) => {
                        var count = 0;
                        this.state.records.result_notes && this.state.records.result_notes.length > 0 && this.state.records.result_notes.map((obj1, index1) => {
                          if(obj == obj1.DATE){
                            count = obj1.notes;
                          }
                        })
                        return(
                          <td key={obj}>{count}</td>
                        )
                      })
                    }
                  </tr>

                  <tr>
                    {
                      this.state.records && this.state.records.week && this.state.records.week.length > 0 && this.state.records.week.map((obj, index) => {
                        var count = 0;
                        this.state.records.result_messages && this.state.records.result_messages.length > 0 && this.state.records.result_messages.map((obj1, index1) => {
                          if(obj == obj1.DATE){
                            count = obj1.traveler;
                          }
                        })
                        return(
                          <td key={obj}>{count}</td>
                        )
                      })
                    }
                  </tr>



                  <tr>
                    {
                      this.state.records && this.state.records.week && this.state.records.week.length > 0 && this.state.records.week.map((obj, index) => {
                        var count = 0;
                        this.state.records.result_messages && this.state.records.result_messages.length > 0 && this.state.records.result_messages.map((obj1, index1) => {
                          if(obj == obj1.DATE){
                            count = obj1.agents;
                          }
                        })
                        return(
                          <td key={obj}>{count}</td>
                        )
                      })
                    }
                  </tr>
                </tbody>
              </table>
            </div>



    );
	}
}

const mapStatesToProps = (state, ownProps) => {
  return {
    records: ownProps.records
  };
}


export default connect(null, null)(PerformanceByWeek);
