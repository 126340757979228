const INITIAL_STATE = {rehydrated: false};
export default function( state = INITIAL_STATE, action ) {
  switch (action.type) {
    case "persist/REHYDRATE": {
      return { ...state, rehydrated: true }
    }
    default: {
      return state;
    }
  }
}
