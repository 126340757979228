import React, { Component } from 'react';
import { Route, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Moment from 'react-moment';
import ReactPaginate from 'react-paginate';
import 'moment-timezone';
import {objToQueryParams, WEBSITE_TITLE, QUERY_STATUSES} from "../../../constants";
import { fetchDuringStayQuotes } from '../../../actions/quotes';
import TravelerInfo from './TravelerInfo';
import AllQuotesFilter from './AllQuotesFilter';
import RejectQuote from './RejectQuote';
import MetaTags from 'react-meta-tags';
import Loader from '../../../components/Loader';


class DuringStayQuotes extends Component {
  constructor(props){
    super(props);
    var page = 1;
		var searchQuery = '';
    var destination = '';
    var query_status = '';
		if(props.location.search){
			const values = queryString.parse(this.props.location.search);
			if(values.page){
				page = parseInt(values.page);
			}
			if(values.search){
				searchQuery = values.search;
			}
      if(values.destination){
				destination = values.destination;
			}
      if(values.query_status){
				query_status = values.query_status;
			}
		}
    this.state = {

				page: page,
        allData: null,
				searchQuery: searchQuery,
        destination: destination,
        query_status: query_status,
				records: null,
	      quoteFilterData:props.all_quotes_filters?props.all_quotes_filters:null,
        rejection_reasons: props.rejection_reasons?props.rejection_reasons:null,
        fetchingRecords: false,
      };
      //this.applyFilters = this.applyFilters.bind(this);
  }

  componentDidMount() {
    this.fetchRecords({page: this.state.page, searchQuery: this.state.searchQuery, destination: this.state.destination, query_status: this.state.query_status});
    //this.fetchRejectionReasons();
    //this.fetchQuoteFilters();
  }

  fetchRecords(params){

    this.setState({fetchingRecords: true});
		this.props.fetchDuringStayQuotes(params).then((response) => {
      this.setState({fetchingRecords: false});
			if(response.value.data.result === 'success'){
				this.setState({records: response.value.data.payload.data, allData:response.value.data.payload});
			}
		})
	}

  /*applyFilters(params){
    var page = 1;
    var queryParams = this.props.location.search;
		var path = this.props.location.pathname;
		var values = queryString.parse(this.props.location.search);
    values = Object.assign(values, {page:page, searchQuery:values.searchQuery?values.searchQuery:'', destination:params.destination, query_status: params.query_status});
		this.props.history.push(path+"?"+objToQueryParams(values));
  }*/

  /*fetchQuoteFilters(){
    this.props.fetchAllQuoteListingFilters().then((response) => {
			if(response.value.data.result === 'success'){
				this.props.fetchAllQuoteListingFiltersSuccess(response.value.data.payload);
			}
		})
  }*/

  /*fetchRejectionReasons(){
    this.props.listQuoteRejectionReasons().then((response) => {
			if(response.value.data.result === 'success'){
				this.props.listQuoteRejectionReasonsSuccess(response.value.data.payload);
			}
		})
  }*/

  componentWillReceiveProps(nextProps){
		if(this.props.location.search != nextProps.location.search){
			var queryParams = nextProps.location.search;
			var path = nextProps.location.pathname;
			var values = queryString.parse(nextProps.location.search);
			this.setState({page: values.page?values.page:1, searchQuery: values.search?values.search:''});
			this.fetchRecords({page: values.page?values.page:1, searchQuery: values.search?values.search:'', destination: values.destination?values.destination:'', query_status: values.query_status?values.query_status:''});
		}
  }
  handlePageClick = (data) => {
		var page = parseInt(data.selected) + 1;
    var queryParams = this.props.location.search;
		var path = this.props.location.pathname;
		var values = queryString.parse(this.props.location.search);

		values = Object.assign(values, {page:page, searchQuery:values.searchQuery?values.searchQuery:''});
		this.props.history.push(path+"?"+objToQueryParams(values));
  }

	render() {
    return (
        <div className="dashboard-content">
          <MetaTags>
            <title>{WEBSITE_TITLE} – During Stay</title>
          </MetaTags>
          <div className="content sm-gutter">
            <div className="container-fluid p-t-0 p-b-25 sm-padding-10 new-quotes">
              <div className="row">
                {
                  this.state.fetchingRecords &&  <Loader />
                }
                <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 new-quotes-left">
                  <div className="header-block-ui no-border">
                    <h3>During Stay</h3>
                    {/*<div className="filter-sort">
                      <label>Sort by </label>
                      <select className="cs-select cs-skin-slide select-style-ui" data-init-plugin="cs-select">
                        <option value="sightseeing">Latest</option>
                        <option value="business">Popular</option>
                      </select>
                    </div>*/}
                  </div>
                  <div className="card info-card card-borderless tab-ui-block shadow-card">
                    {
                      this.state.records && this.state.records.length > 0 &&
                    this.state.records.map((obj, inxex) => {
                      var obj = Object.assign(obj, {departure_date: obj.start_date, no_of_days: obj.days});
                      return(
                      <div key={obj.id} className="quotes-status-block active-quotes">
                        <div className="quotes-id-block">
                          <a target="_blank" href={"/admin/quotes/follow_up/detail/"+obj.id}><label>ID {obj.id}</label></a>
                          <div className="id-status">{QUERY_STATUSES[obj.status]} {obj.query_cancelled_time && <Moment format="DD MMM, YY h:mm a" fromNow fromNowDuring={36000000}>{obj.query_cancelled_time+'-0000'}</Moment>}</div>
                          <time className="cls-time"><Moment format="DD MMM, YY h:mm a" fromNow fromNowDuring={36000000}>{obj.created_at+'-0000'}</Moment></time>
                        </div>
                        <div className="content-quotes">
                          <h3>Quote request by {obj.name} from {obj.departure} to {obj.destination}</h3>
                        </div>
                        <TravelerInfo quickView={true} travelerInfo={obj} />

                        <div className="qutoes-block-bottom">
                          <div className="quote-user">
                            <figure>
                              {/*<img src="/assets/img/alexa.png" />*/}
                            </figure>
                            <figcaption>
                              <label>{obj.name}</label>
                              <label>{obj.country_code+" "+obj.contact_number}</label>
                            </figcaption>
                          </div>
                          {/*<div className="new-quote-action">
                            <Link to={"/admin/quotes/add/"+obj.id} className="btn btn-primary">GIVE QUOTATION</Link>
                            { this.state.rejection_reasons && this.state.rejection_reasons.length > 0 && <RejectQuote rejection_reasons={this.state.rejection_reasons} quote_id = {obj.id} fetchRecords = {this.fetchRecords} />}
                          </div>*/}

                        </div>
                      </div>
                    )})
                  }
                  {
                    !this.state.fetchingRecords && (!this.state.records || this.state.records.length <= 0) &&
                    <div className="quotes-unavailable">
                      <figure className="icon-block"><ion-icon name="list-box"></ion-icon></figure>
                      <figcaption>
                        <p>Quote Requests are not available at this time</p>
                      </figcaption>
                    </div>
                  }
                  </div>
                  {
                    this.state.allData && this.state.allData.total > 0 &&
                  <ReactPaginate previousLabel={"previous"}
                     nextLabel={"next"}
                     breakLabel={"..."}
										 pageClassName="page-item"
										 pageLinkClassName="page-link"
										 previousClassName="page-item"
										 nextClassName="page-item"
										 previousLinkClassName="page-link"
										 nextLinkClassName="page-link"
										 initialPage={parseInt(this.state.page)-1}
                     breakClassName={"break-me"}
                     pageCount={this.state.allData.last_page}
                     onPageChange={this.handlePageClick}
                     marginPagesDisplayed={2}
                     pageRangeDisplayed={5}
                     containerClassName={"pagination"}
                     subContainerClassName={"pages pagination"}
										 activeClassName={"active"} />
                   }
                </div>

              </div>
            </div>
          </div>
        </div>

    );
	}
}

const mapStatesToProps = (state, ownProps) => {
  return {
    quotes: state.quote.new_quotes,
		status: state.quote.status,
    rejection_reasons: state.quote.rejection_reasons?state.quote.rejection_reasons:null,
    all_quotes_filters: state.quote.all_quotes_filters?state.quote.all_quotes_filters:null
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
		fetchDuringStayQuotes: (data) => {
      return dispatch(fetchDuringStayQuotes(data));
    }


  };
}

export default connect(null,mapDispatchToProps)(DuringStayQuotes);
