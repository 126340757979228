
import {
  VALIDATE_EMAIL, VALIDATE_EMAIL_SUCCESS, VALIDATE_EMAIL_FAILURE,
  ME_FROM_TOKEN, ME_FROM_TOKEN_SUCCESS, ME_FROM_TOKEN_FAILURE, RESET_TOKEN,
	SIGNUP_USER, SIGNUP_USER_SUCCESS, SIGNUP_USER_FAILURE, RESET_USER,
	SIGNIN_USER, SIGNIN_USER_PENDING, SIGNIN_USER_SUCCESS,  SIGNIN_USER_FAILURE,
	LOGOUT_USER, UPDATE_USER_EMAIL,
  FORGOT_PASSWORD, FORGOT_PASSWORD_PENDING, FORGOT_PASSWORD_FULFILLED, FORGOT_PASSWORD_FAILURE,
  FORGOT_SUBDOMAIN, FORGOT_SUBDOMAIN_PENDING, FORGOT_SUBDOMAIN_FULFILLED, FORGOT_SUBDOMAIN_FAILURE,
  PASSWORD_RESET, PASSWORD_RESET_PENDING, PASSWORD_RESET_FULFILLED, PASSWORD_RESET_FAILURE,
  UPDATE_USER_PROFILE_FAILURE,
  FETCH_LOGGED_IN_USER_PENDING, FETCH_LOGGED_IN_USER_SUCCESS, FETCH_LOGGED_IN_USER_FAILURE,
  TWITTER_LOGIN, TWITTER_LOGIN_PENDING, TWITTER_LOGIN_SUCCESS, TWITTER_LOGIN_FAILURE,
  FETCH_USER_PERMISSIONS_SUCCESS
} from '../actions/users';

import {FromatValidationErrors} from "../constants";

//user = userobj,
// status can be:
// 1. 'storage' ie. localstorage / sessionstorage)
// 2. 'signup' (signing up)
// 3. 'signin' (signing in)
// 4. 'validate'(validate fields)
// 5. 'validate_email' (validating email token)
// 5. 'authenticated'(after signin)
// 6. 'logout' (after logout)

const INITIAL_STATE = {user: null,status:null, error:null, loading: false};

export default function(state = INITIAL_STATE, action) {
  let error;
  let keys;
  switch(action.type) {

    case VALIDATE_EMAIL://check email verification token
      return { ...state, user: null, status:'validate_email', error:null, loading: true};

    case VALIDATE_EMAIL_SUCCESS:
      return { ...state, user: action.payload.data.user, status:'authenticated', error:null, loading: false}; //<-- authenticated & email verified

    case VALIDATE_EMAIL_FAILURE:
      error = action.payload.data || {message: action.payload.message};//2nd one is network or server down errors
      return { ...state, user:null, status:'validate_email', error:error, loading: false}; //<-- authenticated



    case RESET_TOKEN:// remove token from storage make loading = false
      return { ...state, user: null, status:'storage', error:null, loading: false};

    case SIGNUP_USER:// sign up user, set loading = true and status = signup
      return { ...state, user: null, status:'signup', error:null, loading: true};
    case SIGNUP_USER_SUCCESS://return user, status = authenticated and make loading = false
      return { ...state, user: null, status:'redirect_to_signin', error:null, loading: false}; //<-- authenticated
    case SIGNUP_USER_FAILURE:// return error and make loading = false
      keys = {name: '', email: '', password: '', c_password: '', sub_domain: ''};
      error = FromatValidationErrors(action.payload, keys);
      return { ...state, user: null, status:'signup', error:error, loading: false};

    case SIGNIN_USER_PENDING:// sign in user,  set loading = true and status = signin
      return { ...state, user: null, status:'signin', error:null, loading: true};

    case SIGNIN_USER_SUCCESS://return authenticated user,  make loading = false and status = authenticated
      return { ...state, user: action.payload, status:'authenticated', error:null, loading: false}; //<-- authenticated

    case SIGNIN_USER_FAILURE:// return error and make loading = false
      keys = {email: '', password: '', sub_domain: ''};
      error = FromatValidationErrors(action.payload, keys);
      return { ...state, user: null, status:'signin', error:error, loading: false};

    case UPDATE_USER_EMAIL:
      return{...state, user:{...state.user, email:action.payload.email}};

    case LOGOUT_USER:

      return INITIAL_STATE;

    case RESET_USER:// reset authenticated user to initial state
      return { ...state, user: null, status:null, error:null, loading: false};

    case FORGOT_PASSWORD_PENDING:

      return { ...state, user: null, status:null, error:null, loading: true};

    case FORGOT_PASSWORD_FULFILLED:

      return { ...state, user: null, status:null, error:null, loading: false};

    case FORGOT_PASSWORD_FAILURE:

      keys = {email: '', sub_domain: ''};
      error = FromatValidationErrors(action.payload, keys);
      return { ...state, user: null, status:null, error:error, loading: false};

    case PASSWORD_RESET_PENDING:
      return { ...state, user: null, status:null, error:null, loading: true};

    case PASSWORD_RESET_FULFILLED:
      return { ...state, user: null, status:null, error:null, loading: false};

    case PASSWORD_RESET_FAILURE:
      keys = {password: '', reset_password_token: ''};
      error = FromatValidationErrors(action.payload, keys);
      return { ...state, user: null, status:null, error:error, loading: false};

    case FORGOT_SUBDOMAIN_PENDING:
      return { ...state, user: null, status:null, error:null, loading: true};

    case FORGOT_SUBDOMAIN_FULFILLED:
      return { ...state, user: null, status:null, error:null, loading: false};

    case FORGOT_SUBDOMAIN_FAILURE:
      keys = {password: '', reset_password_token: ''};
      error = FromatValidationErrors(action.payload, keys);
      return { ...state, user: null, status:null, error:error, loading: false};

    case FETCH_LOGGED_IN_USER_PENDING:

      return { ...state, user: state.user, error:null, status: "authenticated", loading: true};

    case FETCH_LOGGED_IN_USER_SUCCESS:
      return { ...state, user: action.payload.user, status:"authenticated", error:null, loading: false};

    case FETCH_USER_PERMISSIONS_SUCCESS:
    
      return { ...state, user: action.payload.user, status:"authenticated", error:null, loading: false};

    case FETCH_LOGGED_IN_USER_FAILURE:
      return { ...state, user: state.user, error:action.payload, status: "authenticated", loading: false};

    default:
      return state;
  }
}
