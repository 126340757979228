import React, { Component } from 'react';
import { Route, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Moment from 'react-moment';
import { Field, reduxForm, change, SubmissionError, FormSection } from 'redux-form';

import renderSelect from '../../FormFields/renderSelect';
import 'moment-timezone';
import {objToQueryParams, WEBSITE_TITLE} from "../../../constants";
import { listUnassignedLeads, listAgents, assignAgent } from '../../../actions/quotes';
import TravelerInfo from './TravelerInfo';
import LeadsFilter from './LeadsFilter';
import RejectQuote from './RejectQuote';
import MetaTags from 'react-meta-tags';
import Loader from '../../../components/Loader';
import ReactPaginate from 'react-paginate';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.tripadvisor_id || values.tripadvisor_id == '-1') {
    errors.tripadvisor_id = "Select Agent/Supplier";
    hasErrors = true;
  }

  return hasErrors && errors;
}

class UnassignedLeads extends Component {
  constructor(props){
    super(props);
    var page = 1;
    if(props.location.search){
			const values = queryString.parse(this.props.location.search);
			if(values.page){
				page = values.page;
			}
    }
    this.state = {
				page: page,
        agents: null,
        records: [],
        allData: null,
	      openEditForm : false,
        quoteFilterData:props.quote_filters?props.quote_filters:null,
        rejection_reasons: props.rejection_reasons?props.rejection_reasons:null,
        fetchingRecords: false,
        modalStatus: false,
        selectedTripId: '',

      };
    this.toggle = this.toggle.bind(this);
  }

  submitForm(values, dispatch, props){
      values = Object.assign(values, {quote_id: this.state.selectedTripId});
      //console.log(values); return;
      return this.props.assignAgent(values)
      .then((result) => {
        if (result.value.data.result == 'success') {
          this.props.reset();
          this.fetchRecords();
          this.toggle();
        }
      })

  }

  componentDidMount() {
    this.fetchRecords({page: this.state.page });
    this.fetchAgents();
  }

  fetchRecords(params){
    if(!params){
      params = {type:"verified"};
    }else{
      params = Object.assign(params,{type:"verified"});
    }
    this.setState({fetchingRecords: true});
		this.props.listUnassignedLeads(params).then((response) => {
      this.setState({fetchingRecords: false});
			if(response.value.data.result === 'success'){
				this.setState({records: response.value.data.payload.data, allData:response.value.data.payload});
			}
		})
	}

  fetchAgents(){
    this.props.listAgents().then((response) => {
      if(response.value.data.result === 'success'){
        var agents = response.value.data.payload;
        var arrayAgents = [];
        if(agents.length > 0){
          for(var i=0; i<agents.length; i++){
            arrayAgents.push({id: agents[i].id, name: agents[i].name+"["+agents[i].usertype+"]"});
          }
        }
				this.setState({agents: arrayAgents});
			}
		})
  }






  componentWillReceiveProps(nextProps){
    if(this.props.location.search != nextProps.location.search){

			var path = nextProps.location.pathname;
			var values = queryString.parse(nextProps.location.search);
			this.setState({page: values.page?values.page:1});
			this.fetchRecords({
        page: values.page?values.page:1,
      });
		}

  }

  assignAgent(e, quote_id){
    e.preventDefault();
    this.setState({modalStatus: true, selectedTripId: quote_id});
  }

  handlePageClick = (data) => {
		var page = parseInt(data.selected) + 1;
    var queryParams = this.props.location.search;
		var path = this.props.location.pathname;
		var values = queryString.parse(this.props.location.search);
    values = Object.assign(values, {page:page});
		this.props.history.push(path+"?"+objToQueryParams(values));
  }

  toggle() {
    this.props.reset();
    this.setState({
      modalStatus: !this.state.modalStatus
    });


  }



	render() {
    console.log('aaaaa', this.state.agents);
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    return (
        <div className="dashboard-content">
          <MetaTags>
            <title>{WEBSITE_TITLE} – verified leads</title>
          </MetaTags>
          <div className="content sm-gutter">
            <div className="container-fluid p-t-0 p-b-25 sm-padding-10 new-quotes">
              <div className="row">
                {
                  this.state.fetchingRecords && <Loader />
                }
                <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 new-quotes-left">
                  <div className="header-block-ui no-border">
                    <h3>Assign Leads</h3>
                    {/*<div className="filter-sort">
                      <label>Sort by </label>
                      <select className="cs-select cs-skin-slide select-style-ui" data-init-plugin="cs-select">
                        <option value="sightseeing">Latest</option>
                        <option value="business">Popular</option>
                      </select>
                    </div>*/}
                  </div>
                  <div className="card info-card card-borderless tab-ui-block shadow-card">
                    {
                      this.state.records && this.state.records.length > 0 &&
                    this.state.records.map((obj, inxex) => (
                      <div key={obj.id} className="quotes-status-block active-quotes">
                        <div className="quotes-id-block">
                          <a target = "_blank" href={"/admin/quotes/detail/"+obj.id}><label>ID {obj.id}</label></a>
                          {/*<div className="id-status">Active</div>*/}
                          <time className="cls-time"><Moment format="DD MMM, YY h:mm a" fromNow fromNowDuring={36000000}>{obj.created_at+'-0000'}</Moment></time>
                        </div>
                        <div className="content-quotes">
                          <h3>Quote request by {obj.name} from {obj.departure} to {obj.destination}</h3>
                        </div>
                        <TravelerInfo quickView={true} travelerInfo={obj} />
                        <div className="qutoes-block-bottom">
                          <div className="quote-user">
                            <figure>
                              {/*<img src="/assets/img/alexa.png" />*/}
                            </figure>
                            <figcaption>
                              <label>{obj.name}</label>
                            </figcaption>
                          </div>
                          <div className="new-quote-action">
                            <a href="#" onClick={(e) => this.assignAgent(e,obj.id)} className="btn btn-primary">Assign Agent/Supplier</a>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                  {
                    !this.state.fetchingRecords && (!this.state.records || this.state.records.length <= 0) &&
                    <div className="quotes-unavailable">
                      <figure className="icon-block"><ion-icon name="list-box"></ion-icon></figure>
                      <figcaption>
                        <p>There are no Unassigned Leads available at this time</p>
                      </figcaption>
                    </div>
                  }
                  </div>
                  {
                    this.state.allData && this.state.allData.total > 0 &&
                    <ReactPaginate previousLabel={"previous"}
                       nextLabel={"next"}
                       breakLabel={"..."}
  										 pageClassName="page-item"
  										 pageLinkClassName="page-link"
  										 previousClassName="page-item"
  										 nextClassName="page-item"
  										 previousLinkClassName="page-link"
  										 nextLinkClassName="page-link"
  										 initialPage={parseInt(this.state.page)-1}
                       breakClassName={"break-me"}
                       pageCount={this.state.allData.last_page}
                       onPageChange={this.handlePageClick}
                       marginPagesDisplayed={2}
                       pageRangeDisplayed={5}
                       containerClassName={"pagination"}
                       subContainerClassName={"pages pagination"}
  										 activeClassName={"active"} />
                  }
                </div>
              </div>
            </div>
          </div>

            <Modal isOpen={this.state.modalStatus} toggle={this.toggle} className="add-hotel-block" style = {{width: 400+'px'}}>
              <ModalHeader toggle={this.toggle}>Trip ID - {this.state.selectedTripId}</ModalHeader>
              <form onSubmit={ handleSubmit(this.submitForm.bind(this)) }>
              <ModalBody>
                <div className="content ">
                  <div className="container-fluid container-fixed-lg white-bg add-hotel-block">
                    <div className = "row">
                      <div className = "col-md-12">
                        <div className = "card card-transparent">
                          <div className="card-body">
                            <div className="row">
                                <Field name="tripadvisor_id" component={renderSelect} label="Agent/Supplier" parentDivClass="form-group col-md-12" fieldClass="form-control" selectDataObject={this.state.agents} fieldSet={{
                                    id: 'id',
                                    value: 'name'
                                  }} dont_show_select_option={false}/>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <button type="submit" disabled={submitting} className="btn btn-primary btn-cons">Save {submitting && <i className="fa fa-spinner fa-spin"></i>}</button>
                <Button color="secondary" onClick={this.toggle}>Cancel</Button>
              </ModalFooter>
            </form>

          </Modal>

      </div>

    );
	}
}



const mapDispatchToProps = (dispatch) => {
  return {
		listUnassignedLeads: (data) => {
      return dispatch(listUnassignedLeads(data));
    },
    listAgents: (data) => {
      return dispatch(listAgents(data));
    },
    assignAgent: (data) => {
      return dispatch(assignAgent(data));
    }
  }
}
UnassignedLeads = connect(null,mapDispatchToProps)(UnassignedLeads);
UnassignedLeads = reduxForm({
  form: 'AssignAgent',  // a unique identifier for this form
  validate,
  enableReinitialize: true
})(UnassignedLeads);

export default UnassignedLeads;
