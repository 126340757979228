import React, { Component } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { fetchSettings, fetchSettingsSuccess, searchQuotes } from '../../actions/quotes';
import { fetchUserPermissions, fetchUserPermissionsSuccess } from '../../actions/users';

import Notifications from './notifications/Notifications';
import { QUERY_STATUSES } from '../../constants';


class Header extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      user: props.user,
      searchResults: [],
      fetchingSearchResults: false,
    };
  }

  componentDidMount() {
    $(document).click(function (e) {
      if (!$(e.target).parents('#search-results-dropdown').length) {
        $('#search-results-dropdown').removeClass('active');
      };
    });
    this.props.fetchUserPermissions().then((response) => {
      if (response.value.data.result === 'success') {
        this.props.fetchUserPermissionsSuccess(response.value.data.data);
      }
    });
    this.props.fetchSettings().then((response) => {
      if (response.value.data.result === 'success') {
        this.props.fetchSettingsSuccess(response.value.data.payload);
      }
    });

    $('.hamburger').on('click', function (event) {
      $('body').toggleClass('menu-open');
    });

    /*$('.radio-active .checkbox input[type="checkbox"]').change(function () {

        if ($(this).is(":checked")) {
           $(".include-hide").slideUp();
       } else {
           $(".include-hide").slideDown();
       }
    });*/
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  searchQuotes(e) {
    e.preventDefault();
    //alert($("#search_input").val());
    var search = $("#search_input").val();
    this.setState({ fetchingSearchResults: true });
    this.props.searchQuotes({ search: search }).then((response) => {
      this.setState({ fetchingSearchResults: false });
      if (response.value.data.result === 'success') {
        $('#search-results-dropdown').addClass('active');
        this.setState({ searchResults: response.value.data.payload });
      }
    });
  }

  getLink(status, id) {
    var link = "#";
    if (status == 0) {
      link = "/admin/quotes/detail/" + id;
    } else if (status == 1) {
      link = "/admin/quotes/follow_up/detail/" + id;
    } else if (status == 3) {
      link = "/admin/payments-vouchers/?quote_id=" + id;
    }
    return link;
  }

  render() {
    return (
      <div className="header ">
        <a href="#" className="btn-link toggle-sidebar d-lg-none pg pg-menu" data-toggle="sidebar">
        </a>
        <div className="header-left-block">
          {/*<button className="hamburger">
              <span className="first"></span>
              <span className="second"></span>
              <span className="third"></span>
            </button>*/}
          <div className="brand inline">
            <a href="/">Travalot</a>
          </div>
          <div className="search-link d-none d-lg-inline-block d-xl-inline-block search-block" id="search-results-dropdown">
            <form method="GET" onSubmit={(e) => this.searchQuotes(e)}>
              <input id="search_input" type="text" name="search" placeholder="Search here" />
              <button className="search-btn" type="submit">{this.state.fetchingSearchResults == false && <ion-icon name="search"></ion-icon>}{this.state.fetchingSearchResults == true && <i className="fa fa-spinner fa-spin"></i>}</button>
            </form>
            <div className="search-dropdown">
              <ul>
                {
                  this.state.searchResults.length > 0 && this.state.searchResults.map((obj, index) => {
                    return (
                      <li>
                        <a target="_blank" href={this.getLink(obj.status, obj.id)}>
                          <div className="search-left-block">
                            <h3>{obj.name}</h3>
                            <p className="trip-id">Trip ID {obj.id}</p>
                            <p className="status-text">Status: {QUERY_STATUSES[obj.status]}</p>
                          </div>
                        </a>
                      </li>
                    )
                  })
                }
                {
                  this.state.searchResults.length <= 0 &&
                  <li>
                    <a href="javascript:;">
                      <div className="search-left-block">
                        <h3>No results</h3>
                      </div>
                    </a>
                  </li>
                }
              </ul>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <Notifications />
          <div className="header-login-profile dropdown mob-none dropdown">
            <a href="javascript:;" className="profile-btn" data-toggle="dropdown">
              <span className="user-image">{this.state.user.profile_image && <img src={this.state.user.profile_image} alt="" />}</span>
              <span className="user-name">
                {this.state.user && this.state.user.name}
                <ion-icon name="ios-arrow-down"></ion-icon>
              </span>
            </a>
            <div className="dropdown-menu dropdown-menu-right profile-dropdown" role="menu">
              <Link to="/admin/change-password" className="dropdown-item"><i className="pg-settings_small"></i>Change Password</Link>
              <Link to="/logout" className="clearfix bg-master-lighter dropdown-item">
                <span className="pull-left">Logout</span>
                <span className="pull-right"><i className="pg-power"></i></span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    user: state.user.user,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSettings: (params) => {
      return dispatch(fetchSettings(params));
    },
    fetchSettingsSuccess: (params) => {
      return dispatch(fetchSettingsSuccess(params));
    },
    searchQuotes: (params) => {
      return dispatch(searchQuotes(params));
    },
    fetchUserPermissions: (params) => {
      return dispatch(fetchUserPermissions(params));
    },
    fetchUserPermissionsSuccess: (params) => {
      return dispatch(fetchUserPermissionsSuccess(params));
    }
  }
}

export default connect(mapStatesToProps, mapDispatchToProps)(Header);
