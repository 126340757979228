import React, {Component} from 'react';
// import { posts } from '../config';
import {Field, reduxForm, change, FieldArray} from 'redux-form';

import {GoogleApiWrapper} from 'google-maps-react';
import BasicMapWrapper from './map.style';
import {GOOGLE_MAP_API_KEY} from '../constants';
import renderField from '../components/FormFields/renderField';

class DragDropMarker extends Component {
  constructor(props) {
    super(props);
    this.loadMap = this.loadMap.bind(this);
    var longitude = 76.7794;
    var latitude = 30.7333;
    if(props.latitude){
      latitude = parseFloat(props.latitude)
    }
    if(props.longitude){
      longitude = parseFloat(props.longitude);
    }
    this.state = {
      location: props.location,
      center: {
        lat: latitude,
        lng: longitude
      },
      lat: parseFloat(props.lat),
      long: parseFloat(props.long),
      zoom: 13,
      extraClass: '',
      /* If extra class props exist, then that class added to map div */
      mapStyle: 'standard',
      /* Default map style is standand, if user pass any other style then according to style map execute */
    };

    if (this.state.lat === '' || this.state.lat === "undefined" || isNaN(this.state.lat)) {
      this.state.lat = 30.7333;
    }
    if (this.state.long === '' || this.state.long === "undefined" || isNaN(this.state.long)) {
      this.state.long = 76.7794
    }

    //check props exist
    if (typeof this.state.lat !== "undefined" && typeof this.state.long !== "undefined" && this.state.lat !== null && this.state.lat !== "" && this.state.long !== null && this.state.long !== "" && !isNaN(this.state.lat)) {
      this.state.center = {
        lat: parseFloat(this.state.lat),
        lng: parseFloat(this.state.long)
      };
    }

    if (typeof props.extraClass !== "undefined" && props.extraClass !== null && props.extraClass !== "") {
      this.state.extraClass = props.extraClass;
    }
    if (typeof props.mapStyle !== "undefined" && props.mapStyle !== null && props.mapStyle !== "") {
      this.state.mapStyle = props.mapStyle;
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({location: nextProps.location, lat: nextProps.lat, long: nextProps.long});
  }

  loadMap(element) {
    const {google} = this.props;
    if (!element || !google)
      return;
    const self = this;
    let geocoder = new google.maps.Geocoder();
    var input =/** @type {!HTMLInputElement} */
    (document.getElementById('location'));

    //default map style
    let mapStyleArray = [
      {
        "featureType": "poi",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      }
    ];
    // Create a new StyledMapType object, passing it an array of styles,
    // and the name to be displayed on the map type control.
    if (this.state.mapStyle === 'silver') {
      mapStyleArray = [
        {
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#f5f5f5"
            }
          ]
        }, {
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        }, {
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#616161"
            }
          ]
        }, {
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#f5f5f5"
            }
          ]
        }, {
          "featureType": "administrative.land_parcel",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#bdbdbd"
            }
          ]
        }, {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        }, {
          "featureType": "poi",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#757575"
            }
          ]
        }, {
          "featureType": "poi.park",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e5e5e5"
            }
          ]
        }, {
          "featureType": "poi.park",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        }, {
          "featureType": "road",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        }, {
          "featureType": "road.arterial",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#757575"
            }
          ]
        }, {
          "featureType": "road.highway",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#dadada"
            }
          ]
        }, {
          "featureType": "road.highway",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#616161"
            }
          ]
        }, {
          "featureType": "road.local",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        }, {
          "featureType": "transit.line",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e5e5e5"
            }
          ]
        }, {
          "featureType": "transit.station",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        }, {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#c9c9c9"
            }
          ]
        }, {
          "featureType": "water",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        }
      ];
    }

    let styledMapType = new google.maps.StyledMapType(mapStyleArray, {name: 'Styled Map'});

    let Map = new google.maps.Map(element, {
      zoom: this.state.zoom,
      center: this.state.center,
      scrollwheel: false,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: google.maps.ControlPosition.TOP_RIGHT
      }
    });

    //Associate the styled map with the MapTypeId and set it to display.
    Map.mapTypes.set('styled_map', styledMapType);
    Map.setMapTypeId('styled_map');

    const marker = new google.maps.Marker({
      map: Map,
      anchorPoint: new google.maps.Point(0, -29),
      draggable: true
    });

    marker.setIcon(/** @type {google.maps.Icon} */
    ({
      url: 'http://maps.google.com/mapfiles/ms/icons/red.png',
      size: new google.maps.Size(71, 71),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(17, 34),
      scaledSize: new google.maps.Size(35, 35)
    }));
    //default Marker position
    let defaultlatlng = new google.maps.LatLng(this.state.lat, this.state.long);
    marker.setPosition(defaultlatlng);
    marker.setVisible(true);

    let autocomplete = new google.maps.places.Autocomplete(input);
    autocomplete.bindTo('bounds', Map);

    google.maps.event.addListener(marker, 'dragend', () => {
      let latlng = {
        lat: marker.getPosition().lat(),
        lng: marker.getPosition().lng()
      };
      let address = '',
        country = '';
      geocoder.geocode({
        'location': latlng
      }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            address = results[0].formatted_address;
            this.refs.map_location_input.value = address;
            this.props.callbackForAddress(address);
            this.props.callbackForLatLong(latlng.lat, latlng.lng);
            for (var i = 0; i < results.length; i++) {
              if (results[i].types[0] === "country") {
                country = results[i].address_components[0].long_name;
                break;
              }
            }
          } else {
            console.log('No results found');
          }
        } else {
          console.log('Geocoder failed due to: ' + status);
        }
      });
    });

    autocomplete.addListener('place_changed', () => {
      marker.setVisible(true);
      let place = autocomplete.getPlace();
      //this.props.change('latitude', place.geometry.location.lat());
      //this.props.change('longitude', place.geometry.location.lng());
      this.setState({latitude: place.geometry.location.lat()});
      this.setState({longitude: place.geometry.location.lng()});
      this.props.callbackForLatLong(place.geometry.location.lat(), place.geometry.location.lng());
      let address = '',
        country = '';
      if (!place.geometry) {
        window.alert("Autocomplete's returned place contains no geometry");
        return;
      }

      // If the place has a geometry, then present it on a map.
      if (place.geometry.viewport) {
        Map.fitBounds(place.geometry.viewport);
      } else {
        Map.setCenter(place.geometry.location);
        Map.setZoom(13); // Why 17? Because it looks good.
      }

      marker.setPosition(place.geometry.location);
      marker.setVisible(true);

      if (place.address_components) {
        // console.log(place);
        //get address
        address = place.formatted_address;

        //this.props.change('location', address);
        //alert(address);
        //this.setState({location: address});

        this.props.callbackForAddress(address);

        for (var i = 0; i < place; i++) {
          if (place[i].types[0] === "country") {
            country = place[i].address_components[0].long_name;
            this.props.callbackForCountry(country);
            break;
          }
        }

      }
    });

    marker.addListener('click', function() {
      if (self.infowindow) {
        self.infowindow.close();
      }
    });
  }

  render() {
    const {loaded} = this.props;
    //const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: {
          span: 24
        },
        sm: {
          span: 16,
          offset: 4
        }
      },
      wrapperCol: {
        xs: {
          span: 24
        },
        sm: {
          span: 16,
          offset: 4
        }
      }
    };
    return (<div>
      {
        loaded
          ? (<BasicMapWrapper>

              <label>Location</label>

            <input id="location" ref="map_location_input" type="text" name="location" defaultValue={this.state.location} className = " form-control " />

            <div className={`isoGoogleMap ${this.props.extraClass}`} style={{
                height: '250px',
                width: '100%'
              }} ref={this.loadMap}/> {/* <Field id="latitude" type="hidden" name="latitude" component="input" defaultValue={this.state.latitude} /> */}

            {/* <Field id="longitude" type="hidden" name="longitude" component={"input"} defaultValue={this.state.longitude} /> */}
          </BasicMapWrapper>)
          : (<span>API is Loading</span>)
      }
    </div>);
  }
}

//const DragDropMarkerContent = reduxForm({form: 'GoogleMapForm'})(DragDropMarker);
export default GoogleApiWrapper({apiKey: GOOGLE_MAP_API_KEY})(DragDropMarker);
