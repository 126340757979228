import {
  _dispatch, LIST_COUNTRIES_SUCCESS, LIST_STATES_SUCCESS, LIST_CITIES_SUCCESS
} from '../actions/cities';

const INITIAL_STATE = { countries:[], status:null };


export default function(state = INITIAL_STATE, action) {
  
  switch(action.type) {

    case LIST_COUNTRIES_SUCCESS:
      return (() => {
        const countries = action.payload;
        return _dispatch({ ...state, countries}, true);
      })();

    case LIST_STATES_SUCCESS:
     return (() => {
       const states = action.payload;
       return _dispatch({ ...state, states}, true);
     })();

     case LIST_CITIES_SUCCESS:
      return (() => {
        const cities = action.payload;
        return _dispatch({ ...state, cities}, true);
      })();

    default:
      return state;
  }
}
