import React, {Component} from 'react';
import ReactLoader from 'react-loader-spinner';

export default class Loader extends Component {
  //other logic
    render() {
     return(
       <div className = "page-loader-parent">
         <div className = "page-loader-child">
           <ReactLoader
             type="Oval"
             color="#0C6BB7"
             height="80"
             width="80"
          />
        </div>
      </div>
     );
    }
 }
