import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, SubmissionError, reset } from 'redux-form';
import renderFieldLabelTransition from '../FormFields/renderFieldLabelTransition';
import renderField from '../FormFields/renderField';
import {Redirect, BrowserRouter, Link} from 'react-router-dom';
import { signInUser, signInUserSuccess } from '../../actions/users';
import createHistory from 'history/createBrowserHistory';
import {toast} from 'react-toastify';
import $ from 'jquery';

const history = createHistory();

//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.email || values.email.trim() === '') {
    errors.email = 'Enter email';
    hasErrors = true;
  }
  if (!values.password || values.password.trim() === '') {
    errors.password = 'Enter password';
    hasErrors = true;
  }
  return hasErrors && errors;
}

//submit login form
const submitLoginForm = (values, dispatch, props) => {

  return dispatch(signInUser(values))
    .then((result) => {
      //console.log('timing', result.value.data);
      if (result.value.data.result == 'failure') {
        
        toast.error(result.value.data.flash_message);
      } else {
        dispatch(reset("SignInForm"));
        localStorage.setItem('jwtToken', result.value.data.data.token);
        dispatch(signInUserSuccess(result.value.data.data.user)); //ps: this is same as dispatching RESET_USER_FIELDS
      }
    });
  };


class SignInForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      is_submitting: false,
      isLoggedIn: typeof props.user.status === 'undefined' ? false : props.user.status
    }
  }

  componentDidMount(){
    if($('.onclick').length > 0){
      $('.onclick').find('input[type=text],input[type=email], input[type=password], textarea').each(function(){
        if($(this).val().replace(/\s+/, '') != '')
          $(this).addClass('active');
      });
      $('.onclick').find('input[type=text],input[type=email], input[type=password], textarea').change(function(){
        if($(this).val().replace(/\s+/, '') == ''){
          $(this).val('').removeClass('active');
        }else
          $(this).addClass('active');
      });
    }

    $('.show-password-option').click(function(){
      $(this).toggleClass('active');
      //$('#password-field').attr("type", 'text');
      var input = $($(this).attr("toggle"));
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
      if ($('.eye-icon').attr("name") == "md-eye-off") {
        $('.eye-icon').attr("name", "md-eye");
      } else {
        $('.eye-icon').attr("name", "md-eye-off");
      }

    })
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.user.status === 'authenticated'){
      this.setState({
        isLoggedIn: true
      });
    }
  }

  render() {
    if (this.state.isLoggedIn === true) {
      return <Redirect to={{
        pathname: '/',
        state: { from: this.props.location }
      }}/>
    }
    const {handleSubmit, pristine, submitting} = this.props;
    return (
      <div className="form-block-section">
        <form onSubmit={handleSubmit(submitLoginForm)} className = "p-t-15">

            <Field
              name="email"
              component={renderFieldLabelTransition}
              type="text"
              className="form-control"
              label="Email"
              placeholder="Email address"
            />


            <Field
              name="password"
              component={renderFieldLabelTransition}
              type="password"
              className="form-control"
              placeholder="Password"
              label="Password"
            />


          <button type="submit" disabled={submitting} className="btn-primary-full">LOGIN NOW {submitting && <i className="fa fa-spinner fa-spin"></i>}</button>
          <div className="action-login-btm">

            <div className="forgot-psd">
              <Link to="/forgotpassword">FORGOT PASSWORD?</Link>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

SignInForm = reduxForm({
  form: 'SignInForm',
  validate
})(SignInForm)

const mapStateToProps = (state, ownProps) => {
  return {
    user: {...state.user}
  }
}

export default connect(mapStateToProps)(SignInForm);
