import React, { Component } from 'react';
import { Redirect, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, SubmissionError, FormSection, FieldArray } from 'redux-form';
import renderField from '../FormFields/renderFieldLabelTransition';
import {stripHtmlTags, validateEmail, API_ROOT} from '../../constants';
import {updatePassword} from "../../actions/users";
import $ from "jquery";
import { toast } from 'react-toastify';

//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.oldPassword || values.oldPassword.trim() === '') {
    errors.oldPassword = "Enter old password";
    hasErrors = true;
  }
  if (!values.password || values.password.trim() === '') {
    errors.password = "Enter new password";
    hasErrors = true;
  }else if(values.password.length < 6){
    errors.password = "Password must be at least 6 characters long";
    hasErrors = true;
  }
  if (!values.confirmPassword || values.confirmPassword.trim() === '') {
    errors.confirmPassword = "Enter password";
    hasErrors = true;
  }else if(values.password != values.confirmPassword){
    errors.confirmPassword = "Passwords do not match";
    hasErrors = true;
  }
  return hasErrors && errors;
}

class ChangePassword extends Component {
	constructor(props){
    super(props);
    this.state = {
      dataLoaded: false,

    }
  }

  submitForm(values, dispatch, props){
    return this.props.updatePassword(values)
    .then((result) => {
      if (result.value.data.result == 'failure') {
        toast.error(result.value.data.flash_message);
        if(result.value.data.error){
          throw new SubmissionError(result.value.data.error);
        }
      }else{
        this.props.reset('ChangePassword');
        toast.success(result.value.data.flash_message);
        this.setState({redirectBack: true});
      }
    })
  }

  componentDidMount(){
    $("body").addClass('nav-webe-hide');
    if($('.onclick').length > 0){
      $('.onclick').find('input[type=text],input[type=email], input[type=password], textarea').each(function(){
        if($(this).val().replace(/\s+/, '') != '')
          $(this).addClass('active');
      });
      $('.onclick').find('input[type=text],input[type=email], input[type=password], textarea').change(function(){

        if($(this).val().replace(/\s+/, '') == ''){
          $(this).val('').removeClass('active');
        }else
          $(this).addClass('active');
      });
    }

  
  }



  componentWillReceiveProps(nextProps){
    /*if(this.props.status != nextProps.status){

    }*/
	}




	render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    const hotelData = this.state.recordData;
    if(this.state.redirectBack) {

      //return <Redirect to='/admin/hotel/list'/>
    }
    return (
      <div className="login-page change-password-block">

    		<div className="container-fluid container-fixed-lg white-bg add-hotel-block">
          <div className = "row">
            <div className = "col-md-12">
              <div className = "card card-transparent">
                <div className="card-body">
                  <div className="login-head"><h2>Change Password</h2><p>Please fill the details to continue</p></div>
                  <div className="form-block-section">
                    <form onSubmit={ handleSubmit(this.submitForm.bind(this)) } className="p-t-15">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <Field type="password" name='oldPassword' className="form-control" label="Old Password" component={renderField} />
                            <Field type="password" name='password' className="form-control" label="New Password" component={renderField} />
                            <Field type="password" name='confirmPassword' className="form-control"  label="Confirm Password" component={renderField} />
                            <button type="submit" disabled={submitting} className="btn-primary-full">Submit {submitting && <i className="fa fa-spinner fa-spin"></i>}</button>
                          </div>
                        </div>
                      </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  	);
	}
}



const mapDispatchToProps = (dispatch) => {
  return {
    updatePassword: (data) => {
      return dispatch(updatePassword(data));
    }
  }
}

ChangePassword = connect(null, mapDispatchToProps)(ChangePassword);

ChangePassword = reduxForm({
  form: 'ChangePassword',  // a unique identifier for this form
  validate,
  enableReinitialize: true
})(ChangePassword);



ChangePassword = connect((state, props) => ({
  initialValues: {password: '', oldPassword: '', confirmPassword: ''}
}))(ChangePassword);

export default ChangePassword;
