import React, { Component } from 'react';
import { Route, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Moment from 'react-moment';
import 'moment-timezone';
import { objToQueryParams, QUOTE_NUMBERS, WEBSITE_TITLE, QUERY_STATUSES } from "../../../constants";
import { quotationDetail, quotationDetailSuccess } from '../../../actions/quotes';
import TravelerInfo from './TravelerInfo';
import QuoteComments from './QuoteComments';
import QuoteNotesNew from './QuoteNotesNew';
import QuotationDetail from './QuotationDetail';
import MetaTags from 'react-meta-tags';
import { PermissibleRender } from '@brainhubeu/react-permissible';
import $ from 'jquery';

class FollowUpQuoteDetail extends Component {
  constructor(props) {
    super(props);
    var section = null;
    if (props.location.search) {
      const values = queryString.parse(props.location.search);
      if (values.section) {
        section = values.section;
      }
    }
    this.state = {
      permissions: props.user && props.user.user ? props.user.user.permissions : [],
      section: section,
      quote_id: this.props.match.params.quote_id,
      travelerInfo: null,
      record: null,
      invoice_quotation_data: null,
      current_quotation_id: null,
      quotation_index: 0,
      quote_status: null
    };
  }

  componentDidMount() {
    $("body").addClass('nav-webe-hide');
    this.fetchRecord(this.state.quote_id);
    /*$('.pd-btn').on('click', function(){
      $(this).parent('.Package-full-details').toggleClass('sec-hide');
      $(this).parent('.Package-full-details').find('.full-details').slideToggle();
      $(this).text($(this).text() == 'Show Less' ? 'Show More' : 'Show Less');

    });*/
    /*$('.create-invoice').on('click', function(){
      $('.create-invoice-block').slideDown();
      $('html, body').animate({
         scrollTop: $(".create-invoice-block").offset().top
    }, 500);

    });*/
  }

  componentDidUpdate() {
  }

  fetchRecord() {
    this.props.quotationDetail({ id: this.state.quote_id }).then((response) => {
      if (response.value.data.result === 'success') {
        var record = response.value.data.payload;
        var initial_requirement = null;
        if (record.query.length > 0) {
          //Get initial requirement
          for (var i = 0; i < record.query.length; i++) {
            if (record.query[i].id == record.query[i].trip_id) {
              initial_requirement = record.query[i];
              break;
            }
          }
        }
        this.setState({ record: response.value.data.payload, travelerInfo: initial_requirement, quote_status: response.value.data.payload.query[0].status });
        if (this.state.section == 'comments') {
          $('html, body').animate({
            scrollTop: $("#comments-section").offset().top
          }, 2000);
        } else if (this.state.section == 'notes') {
          $('html, body').animate({
            scrollTop: $("#section-quote-note").offset().top
          }, 2000);
        }
      }
    })
  }

  componentWillReceiveProps(nextProps) { }

  updateQuotationIndex(e, index) {
    e.preventDefault();
    this.setState({ quotation_index: index });
  }


  render() {

    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    return (<div className="dashboard-content">
      <MetaTags>
        <title>{WEBSITE_TITLE} – ID {this.state.quote_id}</title>
      </MetaTags>
      <div className="content sm-gutter">
        <div className="container-fluid p-t-0 p-b-25 sm-padding-10 new-quotes">
          <div className="row">
            <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 new-quotes-left">
              <div className="card info-card card-borderless tab-ui-block shadow-card quotes-detail-single pr-top20">
                <div className="quotes-status-block active-quotes">
                  {
                    this.state.record &&
                    <div className="quotes-id-block">
                      <label>ID {this.state.record.query[0].trip_id}</label>
                      <div className="id-status">{QUERY_STATUSES[this.state.quote_status]}</div>
                      {/*<time className="cls-time">20 mins ago</time>*/}
                    </div>
                  }
                  <div className="content-quotes">
                    <h3>Quote request by {this.state.travelerInfo && this.state.travelerInfo.name} from <i>{this.state.travelerInfo && this.state.travelerInfo.departure} to {this.state.travelerInfo && this.state.travelerInfo.destination}</i>
                    </h3>
                  </div>
                  <div className="primary-accordian">
                    <div className="card-group horizontal" id="accordion" role="tablist" aria-multiselectable="true">
                      <div className="card card-default m-b-0">
                        <div className="card-header " role="tab" id="traveler-requirements">
                          <h4 className="card-title">
                            <a data-toggle="collapse" data-parent="accordion" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne" className="">
                              <span className="card-title-inner">Traveller Requirements</span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseOne" className="collapse" role="tabcard" aria-labelledby="traveler-requirements">
                          <div className="card-body">
                            {this.state.record && <TravelerInfo travelerInfo={this.state.travelerInfo} />}
                          </div>
                        </div>
                      </div>
                      <div className="card card-default m-b-0">
                        <div className="card-header " role="tab" id="headingOne">
                          <h4 className="card-title">
                            <a data-toggle="collapse" data-parent="accordion" href="#quotations" aria-expanded="true" aria-controls="quotations" className="">
                              <span className="card-title-inner">Quotations</span>
                            </a>
                          </h4>
                        </div>
                        <div id="quotations" className="collapse show reject-ui" role="tabcard" aria-labelledby="headingOne">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-8">
                                <div className="sec-title  twt">
                                  <h6>Select Quotation</h6>
                                  <p>Choose quote from following</p>
                                </div>
                              </div>
                              <div className="col-md-4 text-right repo-text-left">
                                {this.state.record && (this.state.quote_status == 0 || this.state.quote_status == 1 || this.state.quote_status == 3) &&
                                  <Link to={"/admin/quotes/add/" + this.state.quote_id} className="btn btn-primary">GIVE NEW QUOTE</Link>
                                }
                              </div>
                            </div>
                            <div className="primary-tabs">
                              <ul className="nav nav-tabs" data-init-reponsive-tabs="dropdownfx">
                                {
                                  this.state.record && this.state.record.quotation && this.state.record.quotation.length > 0 && this.state.record.quotation.map((obj, index) => (<li className="nav-item" key={index}>
                                    <a href="#" className={index == this.state.quotation_index ? 'active' : ''} onClick={(e) => this.updateQuotationIndex(e, index)} >
                                      <span>Quotation {index + 1}</span>
                                      <span className="quotations-price">{obj.currency} {parseInt(obj.flight_cost ? obj.flight_cost : 0) + parseInt(obj.visa_cost ? obj.visa_cost : 0) + parseInt(obj.package_cost ? obj.package_cost : 0)}/<small>{obj.price_type}</small>
                                      </span>
                                      {obj.accepted_quote == 0 && <small className="active_quote">Active</small>}
                                      {obj.accepted_quote == 1 && <small className="accepted_quote">Accepted</small>}
                                      {obj.accepted_quote == 2 && <small className="rejected_quote">Rejected</small>}
                                    </a>
                                  </li>))
                                }

                              </ul>
                              {
                                this.state.record && this.state.record.quotation && this.state.record.quotation.length > 0 &&
                                <QuotationDetail quote_status={this.state.quote_status} travelerInfo={this.state.travelerInfo} record={this.state.record.quotation[this.state.quotation_index]} quotation_index={this.state.quotation_index} />
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="comments-section">
                        {
                          this.state.quote_id && <QuoteComments quote_id={this.state.quote_id} />
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
              <div className="card info-card card-borderless tab-ui-block shadow-card">
                <div className="head-block-filter">
                  <h2>Quote Information</h2>
                </div>
                <div className="quotes-list">
                  <h5 className="heading-sm-block">Quotes</h5>
                  <div className="quotes-bar">
                    <a href="#" className="active">{this.state.record && this.state.record.active} Active</a>
                    <a href="#" className="active">{this.state.record && this.state.record.in_active} Declined</a>

                  </div>
                </div>
                <div className="tranvallor-detail">
                  <h5 className="heading-sm-block">TRAVELER DETAILS</h5>
                  <div className="quote-user">
                    <figure>
                      {/*<img src="/assets/img/alexa.png"/>*/}
                    </figure>
                    <figcaption>
                      <label>{this.state.travelerInfo && this.state.travelerInfo.name}</label>
                      <PermissibleRender userPermissions={this.state.permissions} requiredPermissions={['view_client_info']} >
                        <span>{this.state.travelerInfo && this.state.travelerInfo.country_code + ' ' + this.state.travelerInfo.contact_number}</span>
                        <p>{this.state.travelerInfo && this.state.travelerInfo.email_id}</p>
                      </PermissibleRender>
                    </figcaption>
                  </div>
                </div>

              </div>
              <div id="section-quote-note">
                <QuoteNotesNew trip_id={this.state.quote_id} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>);
  }
}

const mapStatesToProps = (state, ownProps) => {
  return { quotes: state.quote.new_quotes, status: state.quote.status, user: { ...state.user } };
}

const mapDispatchToProps = (dispatch) => {
  return {
    quotationDetail: (params) => {
      return dispatch(quotationDetail(params));
    },
    quotationDetailSuccess: (payload) => {
      dispatch(quotationDetailSuccess(payload));
    }

  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(FollowUpQuoteDetail);
