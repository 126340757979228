import React, {Component} from 'react';
import {Route, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import Moment from 'react-moment';
import 'moment-timezone';
import $ from 'jquery';
import {quoteMessageSend, quoteMessageListing} from '../../../actions/quotes';

class QuoteComments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quote_id: props.quote_id,
      messages: null,
      submitting: false,

    };
  }

  componentDidMount() {
    var objClass = this;
    this.intervalId = setInterval(this.fetchMessages.bind(this), 10000);

  }
  componentWillUnmount(){
    clearInterval(this.intervalId);
  }

  fetchMessages(){
    this.props.quoteMessageListing({quote_id: this.state.quote_id}).then((response) => {
      if(response.value.data.result == 'success'){
        var messages = response.value.data.payload;
        this.setState({messages: messages});
      }
    });
  }

  componentWillReceiveProps(nextProps) {}

  postComment(e){
    e.preventDefault();
    var comment = $("#comment-textarea").val();
    if(comment){
      this.setState({submitting: true});
      this.props.quoteMessageSend({quote_id: this.state.quote_id, message: comment}).then((response) => {
        this.setState({submitting: false});
        if(response.value.data.result == 'success'){
          document.getElementById("comment-form").reset();
          this.fetchMessages();
        }
      });
    }
  }

  render() {
    return (<div>
      <div className="heading-in-card margin-top10">
        <h2>Conversation</h2>
      </div>
      <form id = "comment-form">
        <div className="form-group">
          <textarea className="form-control" placeholder="Please write your comments" id = "comment-textarea"></textarea>
        </div>
        <div className="qutation-btn-group text-right">
          <button disabled={this.state.submitting} className="btn btn-primary" onClick = {(e) => this.postComment(e)}>POST COMMENT {this.state.submitting && <i className="fa fa-spinner fa-spin"></i>}</button>
        </div>
      </form>
      {
        this.state.messages && this.state.messages.length > 0 &&
        this.state.messages.map((obj, index) => (
          <div className="comment-user" key={obj.id}>
            <div className="quote-user">
              <figure>
                {obj.user_image && <img src = {obj.user_image} /> }
              </figure>
              <figcaption>
                <label>{obj.name}</label>
              </figcaption>
              <Moment format="DD MMM, YY &nbsp; h:mm a" >{obj.created_at+'-0000'}</Moment>
            </div>
            <p>{obj.message}</p>
            {/*<span className="seen-msg">Seen . 30 min ago</span>*/}
          </div>
        ))
      }
    </div>);
  }
}



const mapDispatchToProps = (dispatch) => {
  return {
    quoteMessageSend: (data) => {
      return dispatch(quoteMessageSend(data));
    },
    quoteMessageListing: (data) => {
      return dispatch(quoteMessageListing(data));
    },
  };
}

export default connect(null, mapDispatchToProps)(QuoteComments);
