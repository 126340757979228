import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Layout from './admin/Layout';


class EnsureNotLoggedIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permissions: props.user && props.user.user?props.user.user.permissions:[],
      user: props.user
    };
  }
  componentWillReceiveProps(nextProps){
    if(this.props.user.status != nextProps.user.status){
      this.setState({user: nextProps.user.status})
    }
  }

  render() {
    if (this.props.user.status === 'authenticated') {
      //console.log(this.props);
      return <Layout permissions={this.state.permissions} user = {this.state.user} />
    } else {
      //console.log('asdf');
      return <Redirect to={{
        pathname: '/signin',
        state: { from: this.props.location }
      }}/>
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: {...state.user}
  }
}

export default connect(mapStateToProps)(EnsureNotLoggedIn);
