import axios from 'axios';
import { API_ROOT, confirmDelete, _dispatch } from '../constants';

export {confirmDelete};
export {_dispatch};

export const NEW_QUOTE_LISTING = 'NEW_QUOTE_LISTING';
export const NEW_QUOTE_LISTING_SUCCESS = 'NEW_QUOTE_LISTING_SUCCESS';

export const LIST_LEADS = 'LIST_LEADS';

export const QUOTE_DETAIL_SUCCESS = 'QUOTE_DETAIL_SUCCESS';
export const QUOTE_DETAIL = 'QUOTE_DETAIL';

export const SAVE_QUOTE = 'SAVE_QUOTE';


export const QUOTATION_DETAIL = 'QUOTATION_DETAIL';
export const QUOTATION_DETAIL_SUCCESS = 'QUOTATION_DETAIL_SUCCESS';

export const INCLUSIONS_LIST = 'INCLUSIONS_LIST';
export const INCLUSIONS_LIST_SUCCESS = 'INCLUSIONS_LIST_SUCCESS';

export const QUOTATION_DETAIL_FOR_EDIT = 'QUOTATION_DETAIL_FOR_EDIT';
export const UPDATE_QUOTE = 'UPDATE_QUOTE';

export const QUOTE_REJECTION_REASONS_LIST = 'QUOTE_REJECTION_REASONS_LIST';
export const QUOTE_REJECTION_REASONS_LIST_SUCCESS = 'QUOTE_REJECTION_REASONS_LIST_SUCCESS';

export const REJECT_QUOTE = 'REJECT_QUOTE';

export const FETCH_QUOTE_LISTING_FILTERS = 'FETCH_QUOTE_LISTING_FILTERS';
export const FETCH_QUOTE_LISTING_FILTERS_SUCCESS = 'FETCH_QUOTE_LISTING_FILTERS_SUCCESS';

export const FETCH_LEAD_LISTING_FILTERS = 'FETCH_LEAD_LISTING_FILTERS';
export const FETCH_LEAD_LISTING_FILTERS_SUCCESS = 'FETCH_LEAD_LISTING_FILTERS_SUCCESS';

export const FETCH_AlL_QUOTES_LISTING_FILTERS = 'FETCH_AlL_QUOTES_LISTING_FILTERS';
export const FETCH_AlL_QUOTES_LISTING_FILTERS_SUCCESS = 'FETCH_AlL_QUOTES_LISTING_FILTERS_SUCCESS';

export const QUOTE_MESSAGE_SEND = 'QUOTE_MESSAGE_SEND';
export const QUOTE_MESSAGE_LISTING = "QUOTE_MESSAGE_LISTING";

export const QUOTE_NOTE_LISTING = 'QUOTE_NOTE_LISTING';
export const QUOTE_ADD_NOTE = 'QUOTE_ADD_NOTE';

export const INVOICE_REQUIREMENTS = 'INVOICE_REQUIREMENTS';

export const CREATE_INVOICE = 'CREATE_INVOICE';
export const UPDATE_INVOICE = 'UPDATE_INVOICE';

export const FETCH_PAYMENTS = 'FETCH_PAYMENTS';
export const FETCH_PAYMENTS_SUCCESS = 'FETCH_PAYMENTS_SUCCESS';

export const MARK_PAYMENT_RECEIVED = 'MARK_PAYMENT_RECEIVED';
export const MARK_PAYMENT_MADE = 'MARK_PAYMENT_MADE';

export const DELETE_DOC = 'DELETE_DOC';
export const QUOTE_INVOICE_DETAIL = 'QUOTE_INVOICE_DETAIL';

export const FETCH_SETTINGS = 'FETCH_SETTINGS';
export const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS';

export const SAVE_HOTEL = 'SAVE_HOTEL';
export const MARK_VERIFIED = 'MARK_VERIFIED';

export const FETCH_ALL_QUOTES = 'FETCH_ALL_QUOTES';

export const LAST_SENT_QUOTATIONS = 'LAST_SENT_QUOTATIONS';
export const LAST_SENT_QUOTATIONS_DETAIL = 'LAST_SENT_QUOTATIONS_DETAIL';

export const VIEW_PHONE_NUMBER = 'VIEW_PHONE_NUMBER';

export const FETCH_PERFORMANCE_REPORT = 'FETCH_PERFORMANCE_REPORT';
export const FETCH_REPORTS_DESTINATION_FILTER_VALUES = 'FETCH_REPORTS_DESTINATION_FILTER_VALUES';

export const FETCH_REVENUE_REPORT = 'FETCH_REVENUE_REPORT';

export const FETCH_CONVERTED_REPORTS = 'FETCH_CONVERTED_REPORTS';

export const FETCH_CANCELLED_REPORTS = 'FETCH_CANCELLED_REPORTS';

export const FETCH_DURING_STAY_QUOTES = 'FETCH_DURING_STAY_QUOTES';
export const FETCH_POST_STAY_QUOTES = 'FETCH_POST_STAY_QUOTES';

export const FETCH_CALENDAR_EVENTS = 'FETCH_CALENDAR_EVENTS';

export const LIST_UNASSIGNDED_LEADS = 'LIST_UNASSIGNDED_LEADS';

export const LIST_AGENTS = 'LIST_AGENTS';

export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';

export const RESET_NOTIFICATION_COUNTER = 'RESET_NOTIFICATION_COUNTER';

export const SEARCH_QUOTES = 'SEARCH_QUOTES';


const ROOT_URL = API_ROOT;
var token = "";


export function createInvoice(data) {
  token = localStorage.getItem('jwtToken');
  const request = axios({
    method: 'POST',
    data: data,
    url: `${ROOT_URL}/saveinvoicedetail`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: CREATE_INVOICE,
    payload: request
  };
}

export function updateInvoice(data) {
  token = localStorage.getItem('jwtToken');
  //alert(token);
  const request = axios({
    method: 'POST',
    data: data,
    url: `${ROOT_URL}/updateinvoicedetail`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: UPDATE_INVOICE,
    payload: request
  };
}


export function invoiceRequirements(params) {
  token = localStorage.getItem('jwtToken');
  //alert(token);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/invoicerequirement`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: INVOICE_REQUIREMENTS,
    payload: request
  };
}

export function quoteMessageSend(params) {
  token = localStorage.getItem('jwtToken');
  //alert(token);
  const request = axios({
    method: 'POST',
    params: params,
    url: `${ROOT_URL}/messaging`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: QUOTE_MESSAGE_SEND,
    payload: request
  };
}

export function quoteMessageListing(params) {
  token = localStorage.getItem('jwtToken');
  //alert(token);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/quotemessagelist`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: QUOTE_MESSAGE_LISTING,
    payload: request
  };
}

export function quoteAddNote(params) {
  token = localStorage.getItem('jwtToken');
  //alert(token);
  const request = axios({
    method: 'POST',
    params: params,
    url: `${ROOT_URL}/savequotenote`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: QUOTE_ADD_NOTE,
    payload: request
  };
}

export function quoteNotesListing(params) {
  token = localStorage.getItem('jwtToken');
  //alert(token);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/noteslist`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: QUOTE_NOTE_LISTING,
    payload: request
  };
}

export function fetchQuoteListingFilters(params) {
  token = localStorage.getItem('jwtToken');
  //alert(token);
  const request = axios({
    method: 'POST',
    params: params,
    url: `${ROOT_URL}/userquotefilter`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: FETCH_QUOTE_LISTING_FILTERS,
    payload: request
  };
}

export function fetchQuoteListingFiltersSuccess(payload){
  return {
    	type: FETCH_QUOTE_LISTING_FILTERS_SUCCESS,
    	payload: payload
  	}
}

export function fetchLeadListingFilters(params) {
  token = localStorage.getItem('jwtToken');
  //alert(token);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/userquotefilterbystatus`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: FETCH_LEAD_LISTING_FILTERS,
    payload: request
  };
}

export function fetchLeadListingFiltersSuccess(payload, lead_type){
  return {
    	type: FETCH_LEAD_LISTING_FILTERS_SUCCESS,
    	payload: payload,
      lead_type: lead_type
  	}
}

export function fetchAllQuoteListingFilters(params) {
  token = localStorage.getItem('jwtToken');
  //alert(token);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/getquotesdestination`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: FETCH_AlL_QUOTES_LISTING_FILTERS,
    payload: request
  };
}

export function fetchAllQuoteListingFiltersSuccess(payload){
  return {
    	type: FETCH_AlL_QUOTES_LISTING_FILTERS_SUCCESS,
    	payload: payload
  	}
}

export function listQuoteRejectionReasons(params) {
  token = localStorage.getItem('jwtToken');
  //alert(token);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/reasontoreject`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: QUOTE_REJECTION_REASONS_LIST,
    payload: request
  };
}

export function listQuoteRejectionReasonsSuccess(payload){
  return {
    	type: QUOTE_REJECTION_REASONS_LIST_SUCCESS,
    	payload: payload
  	}
}

export function rejectQuote(props){
  token = localStorage.getItem('jwtToken');
  const request = axios({
    method: 'POST',
    data: props,
    url: `${ROOT_URL}/rejectquote`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return {
    type: REJECT_QUOTE,
    payload: request
  };
}

export function listNewQuotes(params) {
  token = localStorage.getItem('jwtToken');
  //alert(token);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/managequotelist`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: NEW_QUOTE_LISTING,
    payload: request
  };
}

export function listNewQuotesSuccess(payload){
	return {
    	type: NEW_QUOTE_LISTING_SUCCESS,
    	payload: payload
  	}
}

export function listLeads(params) {
  token = localStorage.getItem('jwtToken');
  //alert(token);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/getleadsbystatus`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: LIST_LEADS,
    payload: request
  };
}

export function quoteDetail(params) {
  token = localStorage.getItem('jwtToken');
  //alert(token);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/quotedetail`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: QUOTE_DETAIL,
    payload: request
  };
}


export function quoteDetailSuccess(payload){
	return {
    	type: QUOTE_DETAIL_SUCCESS,
    	payload: payload
  	}
}

export function inclusionsList(params) {
  token = localStorage.getItem('jwtToken');
  //alert(token);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/inclusions`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: INCLUSIONS_LIST,
    payload: request
  };
}
export function inclusionsListSuccess(payload) {
  return {
    type: INCLUSIONS_LIST_SUCCESS,
    payload: payload
  };
}

export function saveQuote(props){
  token = localStorage.getItem('jwtToken');
  const request = axios({
    method: 'POST',
    data: props,
    url: `${ROOT_URL}/quotereply`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return {
    type: SAVE_QUOTE,
    payload: request
  };
}

export function updateQuote(props){
  token = localStorage.getItem('jwtToken');
  const request = axios({
    method: 'POST',
    data: props,
    url: `${ROOT_URL}/editquotereply`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return {
    type: UPDATE_QUOTE,
    payload: request
  };
}


export function quotationDetail(params){
  token = localStorage.getItem('jwtToken');
  //alert(token);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/quotedetailbyid`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: QUOTATION_DETAIL,
    payload: request
  };
}

export function quotationDetailSuccess(data){
  return {
    type: QUOTATION_DETAIL_SUCCESS,
    payload: data
  };
}

export function quotationDetailForEdit(params) {
  token = localStorage.getItem('jwtToken');
  //alert(token);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/editquotationdetail`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: QUOTATION_DETAIL_FOR_EDIT,
    payload: request
  };
}

export function fetchPayments(params) {
  token = localStorage.getItem('jwtToken');
  //alert(token);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/getfinalquotewithinvoicedetail`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: FETCH_PAYMENTS,
    payload: request
  };
}

export function fetchPaymentsSuccess(payload) {
  return {
    type: FETCH_PAYMENTS_SUCCESS,
    payload: payload
  };
}

export function markPaymentReceived(payload){
  token = localStorage.getItem('jwtToken');
  const request = axios({
    method: 'POST',
    data: payload,
    url: `${ROOT_URL}/updatepaymentreceivestatus`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return {
    type: MARK_PAYMENT_RECEIVED,
    payload: request
  };
}
export function markPaymentMade(payload){
  token = localStorage.getItem('jwtToken');
  const request = axios({
    method: 'POST',
    data: payload,
    url: `${ROOT_URL}/updatepaymentmadestatus`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return {
    type: MARK_PAYMENT_MADE,
    payload: request
  };
}

export function deleteInvoiceUploads(payload){
  token = localStorage.getItem('jwtToken');
  const request = axios({
    method: 'DELETE',
    data: payload,
    url: `${ROOT_URL}/deletedocuments`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return {
    type: DELETE_DOC,
    payload: request
  };
}

export function getQuoteInvoiceDetail(params) {
  token = localStorage.getItem('jwtToken');
  //alert(token);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/finalizequotedetailbyid`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: QUOTE_INVOICE_DETAIL,
    payload: request
  };
}



export function fetchSettings(params) {
  token = localStorage.getItem('jwtToken');
  //alert(token);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/getsettings`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: FETCH_SETTINGS,
    payload: request
  };
}

export function fetchSettingsSuccess(payload){
	return {
    	type: FETCH_SETTINGS_SUCCESS,
    	payload: payload
  	}
}

export function hotelAdd(props){
  token = localStorage.getItem('jwtToken');
  const request = axios({
    method: 'POST',
    data: props,
    url: `${ROOT_URL}/hotel/add`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return {
    type: SAVE_HOTEL,
    payload: request
  };
}

export function markVerified(props){
  token = localStorage.getItem('jwtToken');
  const request = axios({
    method: 'POST',
    data: props,
    url: `${ROOT_URL}/queryconfirmbytripadvisor`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return {
    type: MARK_VERIFIED,
    payload: request
  };
}

export function fetchAllQuotes(params) {
  token = localStorage.getItem('jwtToken');
  //alert(token);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/getquerylist`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: FETCH_ALL_QUOTES,
    payload: request
  };
}

export function lastSentQuotations(params) {
  token = localStorage.getItem('jwtToken');
  //alert(token);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/getaccepteddestinationquote`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: LAST_SENT_QUOTATIONS,
    payload: request
  };
}

export function lastSentQuotesDetail(params) {
  token = localStorage.getItem('jwtToken');
  //alert(token);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/acceptedquotedetailbyid`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: LAST_SENT_QUOTATIONS_DETAIL,
    payload: request
  };
}

export function viewPhoneNumber(payload){
  token = localStorage.getItem('jwtToken');
  const request = axios({
    method: 'POST',
    data: payload,
    url: `${ROOT_URL}/updatecontactaccess`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return {
    type: VIEW_PHONE_NUMBER,
    payload: request
  };
}

export function getDestinationFilterValues(params){
  token = localStorage.getItem('jwtToken');
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/getreportsdestinationfilter`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: FETCH_REPORTS_DESTINATION_FILTER_VALUES,
    payload: request
  };
}

export function performanceReport(params){
  token = localStorage.getItem('jwtToken');
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/getperformancestatistics`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: FETCH_PERFORMANCE_REPORT,
    payload: request
  };
}

export function revenueReport(params){
  token = localStorage.getItem('jwtToken');
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/getrevenuestatistics`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: FETCH_REVENUE_REPORT,
    payload: request
  };
}

export function convertedTripsReport(params){
  token = localStorage.getItem('jwtToken');
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/getconvertedtripstatistics`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: FETCH_CONVERTED_REPORTS,
    payload: request
  };
}

export function cancelledTripsReport(params){
  token = localStorage.getItem('jwtToken');
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/getcancelledtripstatistics`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: FETCH_CANCELLED_REPORTS,
    payload: request
  };
}

export function fetchDuringStayQuotes(params) {
  token = localStorage.getItem('jwtToken');
  //alert(token);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/getduringstayquotes`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: FETCH_DURING_STAY_QUOTES,
    payload: request
  };
}

export function fetchPostStayQuotes(params) {
  token = localStorage.getItem('jwtToken');
  //alert(token);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/getpoststayquotes`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: FETCH_POST_STAY_QUOTES,
    payload: request
  };
}


export function fetchCalendarEvents(params) {
  token = localStorage.getItem('jwtToken');
  //alert(token);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/getcalendarevents`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: FETCH_CALENDAR_EVENTS,
    payload: request
  };
}

export function listUnassignedLeads(params) {
  token = localStorage.getItem('jwtToken');
  //alert(token);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/getverifiedunassignedleads`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: LIST_UNASSIGNDED_LEADS,
    payload: request
  };
}

export function listAgents(params) {
  token = localStorage.getItem('jwtToken');
  //alert(token);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/getagentswithquotaionpermission`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: LIST_AGENTS,
    payload: request
  };
}

export function assignAgent(data) {
  token = localStorage.getItem('jwtToken');
  const request = axios({
    method: 'POST',
    data: data,
    url: `${ROOT_URL}/assignagent`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: CREATE_INVOICE,
    payload: request
  };
}

export function fetchNotifications(data){
  token = localStorage.getItem('jwtToken');
  const request = axios({
    method: 'GET',
    data: data,
    url: `${ROOT_URL}/notifications`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return {
    type: FETCH_NOTIFICATIONS,
    payload: request
  };
}

export function resetNotificationCounter(data) {
  token = localStorage.getItem('jwtToken');
  const request = axios({
    method: 'POST',
    data: data,
    url: `${ROOT_URL}/setnotificationcountread`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: RESET_NOTIFICATION_COUNTER,
    payload: request
  };
}

export function searchQuotes(data){
  token = localStorage.getItem('jwtToken');
  const request = axios({
    method: 'GET',
    params: data,
    url: `${ROOT_URL}/doquerysearch`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return {
    type: SEARCH_QUOTES,
    payload: request
  };
}
