import React, { Component } from 'react';
import { Route, Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import queryString from 'query-string';
import {objToQueryParams} from "../../../constants";

import $ from 'jquery';

import { rejectQuote } from '../../../actions/quotes';

class RejectQuote extends Component {
  constructor(props){
    super(props);
    this.state = {
			rejection_reasons: props.rejection_reasons,
      quote_id: props.quote_id,
      list_type: props.list_type?props.list_type:null

	  };
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps){
  }

  rejectQuote(e, reason_id, quote_id){
    var comments = '';
    confirmAlert({
      title: 'Confirm!',
      message: 'Are you sure to reject this lead?',
      buttons: [
        {
          label: 'Cancel'
        }, {
          label: 'Confirm',
          onClick: () => {
            this.props.rejectQuote({quote_id: quote_id, reason_id: reason_id,reject_comments: comments }).then((response) => {
              if(response.value.data.result === 'success'){
                this.refreshList();
              }
            });
          }
        }
      ]
    });

  }

  rejectQuoteComment(e, quote_id){
    var comments = document.getElementById('reject-quote-textarea-'+quote_id).value;
    if(!comments){
      return;
    }
    confirmAlert({
      title: 'Confirm!',
      message: 'Are you sure to reject this lead?',
      buttons: [
        {
          label: 'Cancel'
        }, {
          label: 'Confirm',
          onClick: () => {
            this.props.rejectQuote({quote_id: quote_id, reason_id: 0,reject_comments: comments }).then((response) => {
              $('#reject_options_div_'+quote_id).removeClass('show');
              $('#reject_options_div_'+quote_id).find("div.dropdown-list-accept").removeClass('show');
              if(response.value.data.result === 'success'){
                this.refreshList();
              }
            });
          }
        }
      ]
    });

  }

  refreshList(){
      if(this.state.list_type == '1'){
      var queryParams = this.props.location.search;
      var path = this.props.location.pathname;
      var values = queryString.parse(this.props.location.search);
      values = Object.assign(values, {refresh_time:new Date().getTime()});
      this.props.history.push(path+"?"+objToQueryParams(values));
    }else{
      this.props.fetchRecords();
    }
  }

	render() {
    console.log('ppppp', this.props);
    return (
      <div className="dropdown-style dropdown" id = {"reject_options_div_"+this.state.quote_id}>
        <a href="javascript:void(0);" data-toggle="dropdown">{(this.state.list_type && this.state.list_type == '1')?"Reject Lead":"Reject"}</a>
        <div className="dropdown-menu dropdown-list-accept" role="menu" aria-labelledby="notification-center">
          <h3>Reason for Rejection</h3>
          <ul>
            {
              this.state.rejection_reasons && this.state.rejection_reasons.length > 0 &&
              this.state.rejection_reasons.map((objReasons, index1) => (
                (!this.state.list_type || (this.state.list_type && this.state.list_type == objReasons.display_on_page)) && <li key={index1}>
                  <a href="javascript:void(0);" onClick = {(e) => this.rejectQuote(e, objReasons.id, this.state.quote_id)}>{objReasons.reason}</a>
                </li>
              ))
            }
          </ul>
          <div className="other-comments">
            <textarea id = {"reject-quote-textarea-"+this.state.quote_id} placeholder="Other issue? post comment..."></textarea>
            <input type="button"  value="POST" className="btn btn-primary" onClick = {(e) => this.rejectQuoteComment(e, this.state.quote_id)} />
          </div>
        </div>
      </div>

    );
	}
}
const mapDispatchToProps = (dispatch) => {
  return {
		rejectQuote: (data) => {
      return dispatch(rejectQuote(data));
    }
  };
}
RejectQuote = connect(null,mapDispatchToProps)(RejectQuote);
export default withRouter(RejectQuote);
