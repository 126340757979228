import React, { Component } from 'react';
import { Route, Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import Loader from '../../../components/Loader';
import { connect } from 'react-redux';
import renderField from '../../FormFields/renderField';
import renderRadio from '../../FormFields/renderRadio';
import renderTextarea from '../../FormFields/renderTextarea';
import renderCKEditor from '../../FormFields/renderCKEditor';
import renderSelect from '../../FormFields/renderSelect';
import queryString from 'query-string';
import Moment from 'react-moment';
import Countdown from 'react-countdown-now';
import Select from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import "react-datepicker/dist/react-datepicker.css";
import CurrencyFormat from 'react-currency-format';
import HotelAdd from './HotelAdd';
import LastSentQuotations from './LastSentQuotations';
import MetaTags from 'react-meta-tags';
import { PermissibleRender } from '@brainhubeu/react-permissible';
import { CURRENCY, NIGHTS, API_ROOT, ROOM_TYPES, yyyymmdd, removeDuplicates, isNormalInteger, stripHtmlTags, WEBSITE_TITLE, objToQueryParams } from '../../../constants';
import { Field, reduxForm, change, FieldArray, FormSection, SubmissionError } from 'redux-form';
import 'moment-timezone';
import $ from 'jquery';
import { quoteDetail, quoteDetailSuccess, inclusionsList, inclusionsListSuccess, saveQuote, quotationDetailForEdit, updateQuote } from '../../../actions/quotes';
import TravelerInfo from './TravelerInfo';

const promiseHotelOptions = (inputValue) => {
  return (new Promise(resolve => {
    if (inputValue.length < 1) {
      resolve([]);
      return;
    }
    axios.get(`${API_ROOT}/hotelsearch?hotel=${inputValue}`).then((res) => {
      if (res.data.result === 'success') {
        resolve(res.data.payload);
      } else {
        return { options: [] }
      }
    })
  }))
}

function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.days || values.days == '') {
    errors.days = "Enter no. of days";
    hasErrors = true;
  } else if (!isNormalInteger(values.days)) {
    errors.days = "Enter a number";
    hasErrors = true;
  }
  if (!values.nights || values.nights == '') {
    errors.nights = "Enter no. of nights";
    hasErrors = true;
  } else if (!isNormalInteger(values.nights)) {
    errors.nights = "Enter a number";
    hasErrors = true;
  }
  if (!values.price_type) {
    errors.price_type = "Select price type";
    hasErrors = true;
  }

  if (!(!values.flight_cost || values.flight_cost == '')) {
    if (!isNormalInteger(values.flight_cost)) {
      errors.flight_cost = "Enter a number";
      hasErrors = true;
    }
  }
  if (!(!values.visa_cost || values.visa_cost == '')) {
    if (!isNormalInteger(values.visa_cost)) {
      errors.visa_cost = "Enter a number";
      hasErrors = true;
    }
  }

  if (!values.package_cost || values.package_cost == '') {
    errors.package_cost = "Enter land package cost";
    hasErrors = true;
  } else if (!isNormalInteger(values.package_cost)) {
    errors.package_cost = "Enter a number";
    hasErrors = true;
  }
  if (!values.currency || values.currency == '-1') {
    errors.currency = "Select currency";
    hasErrors = true;
  }
  if (!values.terms || values.terms == '') {
    errors.terms = "Please enter terms & conditions";
    hasErrors = true;
  }

  if (!values.itinerary || !values.itinerary.length) {
    errors.itinerary = {
      _error: 'At least one package price must be entered'
    };
    hasErrors = true;
  } else {
    const membersArrayErrors = [];
    values.itinerary.forEach((member, memberIndex) => {
      const memberErrors = {};
      if (!member || !member.title) {
        memberErrors.title = 'Required';
        membersArrayErrors[memberIndex] = memberErrors;
        hasErrors = true;
      }
      if (!member || !member.description) {
        memberErrors.description = 'Required';
        membersArrayErrors[memberIndex] = memberErrors;
        hasErrors = true;
      }
    })
    if (membersArrayErrors.length) {
      errors.itinerary = membersArrayErrors
    }
  }
  if (!(values.cabs_not_included && (values.cabs_not_included === true || values.cabs_not_included === 1))) {
    if (!stripHtmlTags(values.cab_details ? values.cab_details : '')) {
      errors.cab_details = "Enter cab details";
      hasErrors = true;
    }
  }
  if (!(values.flights_not_included && (values.flights_not_included === true || values.flights_not_included === 1))) {
    if (!stripHtmlTags(values.flight_details ? values.flight_details : '')) {
      errors.flight_details = "Enter flight details";
      hasErrors = true;
    }
  }
  return hasErrors && errors;
}

class QuoteForm extends Component {
  constructor(props) {
    super(props);
    var inclusions = null;
    var selected_quote_id = null;
    var quotation_id = '';
    var last_quotes_page = false;
    var last_link = '';
    if (!props.match.params.quote_id) {
      inclusions = props.inclusions ? props.inclusions : null;
    }
    if (props.location.search) {
      const values = queryString.parse(this.props.location.search);
      if (values.selected_quote_id) {
        selected_quote_id = values.selected_quote_id;
        quotation_id = values.selected_quote_id;
      }
      if (values.last_quotes_page) {
        last_quotes_page = true;
      }
      if (values.last_link) {
        last_link = values.last_link;
      }
    }
    if (this.props.match.params.quotation_id) {
      quotation_id = this.props.match.params.quotation_id;
    }
    this.state = {
      permissions: props.user && props.user.user ? props.user.user.permissions : [],
      last_link: last_link,
      last_quotes_page: last_quotes_page,
      selected_quote_id: selected_quote_id,
      lastSentQuotesDestination: null,
      openLastSentQuotesPopup: false,
      destination_type: null,
      startTime: Date.now(),
      openHotelPopup: false,
      defaultHotelName: {},
      id: props.match.params.quote_id,
      quotation_id: quotation_id,
      status: props.status,
      record: null,
      openEditForm: false,
      nights: [],
      initial_nights_array: [],
      selectedNight: null,
      //hotelsData: props.formValues?(props.formValues.hotelsData?props.formValues.hotelsData:[]):[],
      hotelsData: [],
      selectedHotel: null,
      startDate: new Date(),
      tripDays: 0,
      tripNights: 0,
      defaultCurrency: 'INR',
      total_package_cost: 0,
      inclusions: inclusions,
      redirectBack: false,
      dataQuotation: null,
      settings: props.settings,
      time_start: new Date(),
      hotel_type: false,
      priceType: ""
    };
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.renderItinerary = this.renderItinerary.bind(this);
    this.renderInclusions = this.renderInclusions.bind(this);
    this.renderInclusionData = this.renderInclusionData.bind(this);
    this.closeHotelPopup = this.closeHotelPopup.bind(this);
    this.closeLastSentQuotesPopup = this.closeLastSentQuotesPopup.bind(this);
    this.updateHotelForm = this.updateHotelForm.bind(this);
  }

  updateDays(e) {
    var days = parseInt(e.target.value.substr(0, 2));
    if (days > 0) {
      this.props.change('nights', days - 1);
      this.setState({ tripNights: days - 1, tripDays: days });
      this.updateNightsDropdown(days - 1);
    }
    this.updateItinerary(days);
  }

  updateNights(e) {
    var no_nights = parseInt(e.target.value.substr(0, 2));
    this.updateNightsDropdown(no_nights);
    if (no_nights > 0) {
      this.props.change('days', no_nights + 1);
      this.setState({ tripDays: no_nights + 1, tripNights: no_nights });
    }
    this.updateItinerary(no_nights + 1);
  }

  updateNightsDropdown(no_nights, edit_case_nights_array) {
    if (!edit_case_nights_array) {
      edit_case_nights_array = [];
    }
    var nights = NIGHTS;
    var nightsArray = [];
    //logic to set the value of nights dropdown
    var hotelsData = this.state.hotelsData;
    var n_nightsArray = [];

    if (hotelsData) {
      for (var i = 0; i < hotelsData.length; i++) {
        n_nightsArray = [...n_nightsArray, ...hotelsData[i].hotel_value_nights.split(",")];
      }
    }
    if (no_nights > 0) {
      for (var i = 0; i < parseInt(no_nights); i++) {
        var copyRecord = true;

        if (n_nightsArray.indexOf((i + 1).toString()) != -1) {
          copyRecord = false;
        }
        if (edit_case_nights_array.indexOf((i + 1).toString()) != -1) {
          copyRecord = true;
        }
        if (copyRecord) {
          nightsArray.push(nights[i]);
        }
      }
    }
    this.setState({ nights: nightsArray, initial_nights_array: nightsArray });
  }

  handleStartDateChange(date) {
    var firstDay = date;
    var days = this.state.tripDays;
    var dates = [];
    var sDate = '';
    for (var i = 0; i < days; i++) {
      var new_date = new Date(firstDay.getTime() + (i) * 24 * 60 * 60 * 1000);
      new_date = yyyymmdd(new_date);
      if (i == 0) {
        sDate = new_date[0];
      }
      $("#itinerary_date_" + i).html(new_date[1]);
      dates.push(new_date[0]);
    }
    this.updateItineraryDates(dates);
    this.setState({
      startDate: date
    });
    this.props.change('start_date', sDate);
  }

  updateItineraryDates(dates) {
    var currentItinerary = this.props.formValues;
    var arrayItinerary = [];
    var days = this.state.tripDays;
    for (var i = 0; i < days; i++) {
      if (currentItinerary && currentItinerary.itinerary && currentItinerary.itinerary.length > 0 && currentItinerary.itinerary[i]) {
        if (dates && dates[i]) {
          var temp = Object.assign(currentItinerary.itinerary[i], { trip_date: dates[i] });
          arrayItinerary.push(temp);
        } else {
          arrayItinerary.push(currentItinerary.itinerary[i]);
        }
      } else {
        arrayItinerary.push({});
      }
    }
    this.props.change('itinerary', arrayItinerary);
  }

  updateItinerary(days) {
    var firstDay = this.state.startDate;
    var currentItinerary = this.props.formValues;
    var arrayItinerary = [];
    for (var i = 0; i < days; i++) {
      var new_date = new Date(firstDay.getTime() + (i) * 24 * 60 * 60 * 1000);
      new_date = yyyymmdd(new_date);
      if (currentItinerary && currentItinerary.itinerary && currentItinerary.itinerary.length > 0 && currentItinerary.itinerary[i]) {
        console.log("inside", currentItinerary.itinerary[i], new_date);
        arrayItinerary.push(currentItinerary.itinerary[i]);
      } else {
        arrayItinerary.push({ trip_date: new_date[0] });
      }
    }
    this.props.change('itinerary', arrayItinerary);
  }

  componentDidMount() {
    var objThis = this;
    $("body").addClass('nav-webe-hide');
    $('.radio-active .checkbox input[type="checkbox"]').change(function () {
      if ($(this).is(":checked")) {
        $(this).closest('.box-hide-wrapper').find('.include-hide').slideUp();
      } else {
        $(this).closest('.box-hide-wrapper').find('.include-hide').slideDown();
      }
    });
    this.fetchQueryDetail();
    if (!this.state.quotation_id) {
      this.fetchInclusions();
      setTimeout(function () {
        objThis.props.change('currency', "INR")
      }, 3000);
    }
    setTimeout(function () {
      objThis.fixTopBar();
    }, 3000)
  }

  fetchQuotationDetail(destination_type) {
    this.props.quotationDetailForEdit({ id: this.state.quotation_id }).then((response) => {

      if (response.value.data.result === 'success') {
        var record = response.value.data.payload.quotation[0];
        this.setState({
          dataQuotation: record, total_package_cost: record.total_package_price, hotelsData: record.hotelsData, tripDays: record.days,
          tripNights: record.nights, inclusions: record.inclusion,
          hotel_type: record.hotels_not_included == 0 ? false : true
        });
        record = Object.assign(record, { destination_type: destination_type });
        this.props.initialize(record);

        this.props.change('inclusions', record.inclusion);
        if (!this.state.selected_quote_id && this.state.quotation_id) {

          this.props.change('quote_reply_id', this.state.quotation_id);
        }
        //this.handleStartDateChange(record.start_date);
        this.updatePackagePrice();
      }
    })
  }

  componentDidUpdate() {
    //this.fixTopBar();
  }

  fixTopBar() {
    if (typeof $('.title-fix') != 'undefined' && typeof $('.title-fix').offset() != 'undefined') {
      var fixmeTop = $('.title-fix').offset().top;
      if (fixmeTop) {
        $(window).scroll(function () {
          var currentScroll = $(window).scrollTop();
          if (currentScroll >= fixmeTop) {
            $('body').addClass("content-fixed");
            //setTimeout(function(){
            $('body').addClass("scroll-fix");
            //}, 100);
          } else {
            $('body').removeClass("scroll-fix");
            $('body').removeClass("content-fixed");
          }
        });

        var fixme_sideTop = $('.right-sidebar').offset().top;
        $(window).scroll(function () {
          var currentScroll_top = $(window).scrollTop() + 5;
          if (currentScroll_top >= fixme_sideTop) {
            $('body').addClass("right-side-fix");
          } else {
            $('body').removeClass("right-side-fix");
          }
        });
      }
    }
  }

  componentWillUnmount() {
    $("body").removeClass('nav-webe-hide');
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.settings && nextProps.settings) {
      this.setState({ settings: nextProps.settings });
    }
  }

  fetchInclusions() {
    var objProps = this.props;
    this.props.inclusionsList({ id: this.state.id }).then((response) => {
      if (response.value.data.result === 'success') {
        var inclusions = response.value.data.payload;
        this.setState({ inclusions: inclusions });
        this.props.inclusionsListSuccess(inclusions);
        setTimeout(function () { objProps.change('inclusions', inclusions) }, 200);

      }
    })
  }

  fetchQueryDetail() {
    this.props.quoteDetail({ id: this.state.id }).then((response) => {
      if (response.value.data.result === 'success') {

        var record = response.value.data.payload[0];
        this.setState({ record: record });
        this.props.quoteDetailSuccess(record);

        if (!this.state.quotation_id) {

          var startDate = new Date(record.departure_date);
          var obj = this;
          setTimeout(function () {
            obj.handleStartDateChange(startDate);
          }, 600);
          if (record.no_of_days && record.no_of_days >= 2) {
            //this.props.change('days', record.no_of_days);
            //this.props.change('nights', record.no_of_days - 1);
            this.updateNightsDropdown(record.no_of_days - 1);
            this.setState({ tripDays: record.no_of_days, tripNights: record.no_of_days - 1, destination_type: record.destination_type.toString() });
            var arrayItinerary = [];
            for (var i = 0; i < record.no_of_days; i++) {
              arrayItinerary.push({});
            }
            //this.props.change('itinerary', arrayItinerary);
            //this.props.change('quote_id', record.id);
            this.props.initialize({ days: record.no_of_days, nights: record.no_of_days - 1, itinerary: arrayItinerary, quote_id: record.id, destination_type: record.destination_type.toString() });
          }
          this.updatePackagePrice();
        } else {
          this.setState({ destination_type: record.destination_type.toString() });
          //this.props.change('destination_type', record.destination_type.toString());
          this.fetchQuotationDetail(record.destination_type.toString());
        }
        if (this.state.last_quotes_page) {
          this.lastSentQuotesPopup();
        }
      }
    })
  }

  handleChange = (selectedNight) => {
    var initial_nights_array = [];
    initial_nights_array = [...initial_nights_array, ...this.state.nights];
    selectedNight.sort(function (a, b) {
      return parseInt(a.value) - parseInt(b.value)
    })
    var nights_value = '';
    var nights_label = '';
    for (var i = 0; i < selectedNight.length; i++) {
      /*for(var k=0; k<initial_nights_array.length; k++){
        if(initial_nights_array[k].value == selectedNight[i].value){
          initial_nights_array.splice(k,1);
        }
      }*/
      if (i == 0) {
        nights_value = selectedNight[i].value;
        nights_label = selectedNight[i].label;
      } else {
        nights_value = nights_value + ',' + selectedNight[i].value;
        if (selectedNight.length > 2 && i == selectedNight.length - 1) {
          nights_label = nights_label + ' & ' + selectedNight[i].label;
        } else {
          nights_label = nights_label + ', ' + selectedNight[i].label;
        }
      }
    }
    //this.setState({nights: initial_nights_array});
    $('#nights_value_input').val(nights_value);
    $('#nights_label_input').val(nights_label);
    this.setState({ selectedNight });
  }

  deleteHotel(e, index) {
    e.preventDefault();
    var hotelsData = this.props.formValues.hotelsData;
    hotelsData.splice(index, 1);
    this.setState({ hotelsData: hotelsData });
    this.props.change('hotelsData', hotelsData);
    this.updateNightsDropdown(this.state.tripNights);
  }

  resetHotelForm(e) {
    e.preventDefault();
    $("#edit_index").val('');
    $("#hotel_name").val('');
    $("#nights_value_input").val('');
    $("#nights_label_input").val('');
    $("#hotel_city").val('');
    $("#hotel_id").val('');
    $("#hotel_rating").val('');
    $("#hotel_room_type").val('-1');
    $("#hotel_comments").val('');
    $("#react-select-2-input").val('');
    this.setState({ selectedNight: null, selectedHotel: null, defaultHotelName: {} });
    this.updateNightsDropdown(this.state.tripNights);
  }

  editHotel(e, index) {
    e.preventDefault();
    /*var initial_nights_array = [];
    initial_nights_array = [...initial_nights_array,...this.state.nights];
    */
    var hotelsData = this.state.hotelsData;
    var hotel = hotelsData[index];
    $("#edit_index").val(index);
    $("#hotel_name").val(hotel.hotel_name);
    $("#nights_value_input").val(hotel.hotel_value_nights);
    $("#nights_label_input").val(hotel.hotel_label_nights);
    $("#hotel_city").val(hotel.hotel_city);
    $("#hotel_id").val(hotel.hotel_id);
    $("#hotel_rating").val(hotel.hotel_rating);
    $("#hotel_room_type").val(hotel.hotel_room_type);
    $("#hotel_comment").val(hotel.hotel_comment);
    var nights_value = hotel.hotel_value_nights.split(",");
    var nights_label = hotel.hotel_label_nights.replace(' & ', ',').split(",");
    var nigtsArray = [];
    if (nights_value.length > 0) {
      for (var i = 0; i < nights_value.length; i++) {
        nigtsArray.push({ value: nights_value[i], label: nights_label[i] });
      }
    }
    this.setState({ defaultHotelName: { value: hotel.hotel_id, label: hotel.hotel_name, city_name: hotel.hotel_city, star_rating: hotel.hotel_rating }, selectedNight: nigtsArray });
    this.updateNightsDropdown(this.state.tripNights, nights_value);
  }

  addHotel() {
    var hotelArray = this.state.hotelsData;
    var edit_index = $("#edit_index").val();
    var hotel_name = $("#hotel_name").val();
    var hotel_value_nights = $("#nights_value_input").val();
    var hotel_label_nights = $("#nights_label_input").val();
    var hotel_city = $("#hotel_city").val();
    var hotel_rating = $("#hotel_rating").val();
    var hotel_room_type = $("#hotel_room_type").val();
    var hotel_comment = $("#hotel_comment").val();
    var hotel_id = $("#hotel_id").val();
    var error_flag = false;
    if (!hotel_value_nights) {
      error_flag = true
      $("#hotel_nights_error").show();
    } else {
      $("#hotel_nights_error").hide();
    }
    if (!hotel_name) {
      error_flag = true;
      $("#hotel_name_error").show();
    } else {
      $("#hotel_name_error").hide();
    }
    if (hotel_room_type == '-1') {
      error_flag = true
      $("#hotel_room_error").show();
    } else {
      $("#hotel_room_error").hide();
    }
    if (error_flag) {
      return;
    }

    if (edit_index) {
      hotelArray[edit_index] = {
        hotel_name,
        hotel_value_nights,
        hotel_label_nights,
        hotel_city,
        hotel_id,
        hotel_rating,
        hotel_room_type,
        hotel_comment
      }
    } else {
      hotelArray.push({
        hotel_name,
        hotel_value_nights,
        hotel_label_nights,
        hotel_city,
        hotel_id,
        hotel_rating,
        hotel_room_type,
        hotel_comment
      });
    }

    this.props.change('hotelsData', hotelArray);
    $("#edit_index").val('');
    $("#hotel_name").val('');
    $("#nights_value_input").val('');
    $("#nights_label_input").val('');
    $("#hotel_city").val('');
    $("#hotel_id").val('');
    $("#hotel_rating").val('');
    $("#hotel_room_type").val('-1');
    $("#hotel_comments").val('');
    $("#react-select-2-input").val('');
    this.setState({ hotelsData: hotelArray, selectedNight: null, selectedHotel: null, defaultHotelName: {} });
    this.updateNightsDropdown(this.state.tripNights);
  }

  updateCurrency(e) {
    this.setState({ defaultCurrency: e.target.value });
  }

  submitForm(values, dispatch, props) {
    if (!values.quote_reply_id) {
      var end = new Date();
      var time_spent = end - this.state.time_start;
      values = Object.assign(values, { time_spent: time_spent });
    }
    if (this.state.selected_quote_id) {
      values = Object.assign(values, { quote_id: this.state.id });
    }
    //console.log('rrrrrrr', values); return;
    if (this.state.quotation_id && !this.state.selected_quote_id) {
      return this.props.updateQuote(values)
        .then((result) => {
          if (result.value.data.result == 'failure') {
            if (result.value.data.error) {
              throw new SubmissionError(result.value.data.error);
            }
            toast.error(result.value.data.flash_message);
          } else {
            toast.success(result.value.data.flash_message);
            this.setState({ redirectBack: true });
          }
        })
    } else {
      return this.props.saveQuote(values)
        .then((result) => {
          if (result.value.data.result == 'failure') {
            if (result.value.data.error) {
              throw new SubmissionError(result.value.data.error);
            }
            toast.error(result.value.data.flash_message);
          } else {
            toast.success(result.value.data.flash_message);
            this.setState({ redirectBack: true });
          }
        })
    }
  }

  renderItinerary({ fields, meta: { touched, error, submitFailed } }) {
    return (<div className="card-group horizontal" id="accordion" role="tablist" aria-multiselectable="true">
      {
        fields.map((member, index) => {
          //alert(fields.get(index).trip_date);
          var trip_date = [null, null];
          if (fields.get(index).trip_date) {
            var dateParts = fields.get(index).trip_date.split("-");
            var jsDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2].substr(0, 2));
            var trip_date = yyyymmdd(jsDate);
            if (index == 0) {
              //this.setState({startDate: jsDate});
            }
          }
          return (<div className="card card-default m-b-0" key={index}>
            <div className="card-header " role="tab" id={"headingOne" + index}>
              <h4 className="card-title">
                {/* {((error && <span className="error">sdfwefwefwef</span>))} */}
                <a data-toggle="collapse" data-parent="#accordion" href={"#collapseOne" + index} aria-expanded="true" aria-controls="collapseOne" className="">
                  <span className="card-title-inner"> Day {(index + 1)} . <span className="theme-text-color" id={"itinerary_date_" + index}>{trip_date[1]}</span></span>
                </a>
              </h4>
            </div>
            <div id={"collapseOne" + index} className="collapse show" role="tabcard" aria-labelledby={"headingOne" + index} >
              <div className="card-body">
                <div className="inclusion-lists-wrapper">
                  <div className="form-block basic-detail-block ">
                    <Field type="text" name={`${member}.title`} component={renderField} parentDivClass="form-group starting-date-input" label='Title' />
                    {/*<Field type="text" name={`${member}.total_duration`} component={renderField} parentDivClass="form-group days-input" label='Total Duration'/>*/}

                    <Field type="hidden" name={`${member}.trip_date`} component={renderField} parentDivClass="form-group days-input" label='Trip date' readOnly={true} />
                  </div>
                  <Field name={`${member}.description`} component={renderCKEditor} parentDivClass="form-group" label='Description' fieldClass="" />
                  {/*<Field type="text" name={`${member}.activities`} component={renderField} parentDivClass="form-group" label='Activities'/>*/}
                  <div className="row">
                    <div className="col-md-6">
                      <Field type="text" name={`${member}.locations`} component={renderField} parentDivClass="form-group" label='Locations' placeholder="Enter Comma Separated Places Eg. Baga Beach, Goa Fort" />
                    </div>
                    <div className="col-md-6">
                      <Field type="text" name={`${member}.optional_activities`} component={renderField} parentDivClass="form-group" label='Optional Activities' placeholder="Eg. Bar, Shopping etc" />
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>)
        }
        )
      }
    </div>)
  }

  renderInclusions({ fields, meta: { touched, error, submitFailed }, destination_type }) {
    return (<div>
      {
        fields.map((obj, index) => {
          return (
            <table className="content-block" key={index}>
              <thead>
                <tr>
                  <th colSpan="3">{fields.get(index).category}</th>
                </tr>
              </thead>
              <FieldArray name={`${obj}.data`} component={this.renderInclusionData} destination_type={destination_type} />
            </table>
          )
        }
        )
      }
    </div>)
  }

  renderInclusionData({ fields, meta: { touched, error, submitFailed }, destination_type }) {
    var inclusions = this.state.inclusions;
    var inclusionsDB = this.state.dataQuotation;
    return (
      <tbody>
        {
          fields.map((obj, index) => {
            return (
              (fields.get(index).inclusion_type == 2 || fields.get(index).inclusion_type == destination_type) &&
              <tr key={index}>
                <td>{fields.get(index).title}
                  <div>
                    <Field type="hidden" component="input" name={`${obj}.id`} />
                    {/* {
                      fields.get(index).info == 1 &&
                      <Field name={`${obj}.comments`} component={renderTextarea} parentDivClass="form-group" label='' fieldClass="form-control" />
                    } */}
                  </div>
                </td>
                <td>
                  <div className="radio radio-success">
                    <Field name={`${obj}.radio`} type="radio" component="input" value="yes" id={"inclusion_radio_yes_" + fields.get(index).id} />
                    <label htmlFor={"inclusion_radio_yes_" + fields.get(index).id}>inclusion</label>
                  </div>

                </td>
                <td>
                  <div className="radio radio-success">
                    <Field name={`${obj}.radio`} type="radio" component="input" value="no" id={"inclusion_radio_no_" + fields.get(index).id} />
                    <label htmlFor={"inclusion_radio_no_" + fields.get(index).id}>Exclusion</label>
                  </div>
                </td>
                {/*<td>
                   <div className="radio radio-success">
                      <Field name={`${obj}.radio`} type="radio" component="input"  value="hide" id = {"inclusion_radio_hide_"+fields.get(index).id}  />
                      <label htmlFor={"inclusion_radio_hide_"+fields.get(index).id}>Do not show</label>
                   </div>
                </td>*/}
              </tr>
            )
          }
          )
        }
      </tbody>
    )
  }

  updatePackagePrice() {
    var commission_percentage = null;
    if (this.state.destination_type == '1') {
      if (this.state.settings && this.state.settings.commission_percentage_domestic) {
        commission_percentage = parseInt(this.state.settings.commission_percentage_domestic);
      }
    } else {
      if (this.state.settings && this.state.settings.commission_percentage_international) {
        commission_percentage = parseInt(this.state.settings.commission_percentage_international);
      }
    }
    var flight_cost = 0;
    var visa_cost = 0;
    var package_cost = 0;
    var total_package_cost = 0;
    var commission = 0;
    if (this.props.formValues) {
      if (this.props.formValues.flight_cost && !isNaN(this.props.formValues.flight_cost)) {
        flight_cost = parseInt(this.props.formValues.flight_cost);
      }
      if (this.props.formValues.visa_cost && !isNaN(this.props.formValues.visa_cost)) {
        visa_cost = parseInt(this.props.formValues.visa_cost);
      }
      if (this.props.formValues.package_cost && !isNaN(this.props.formValues.package_cost)) {
        package_cost = parseInt(this.props.formValues.package_cost);
        if (commission_percentage) {
          commission = package_cost * (commission_percentage / 100);
          package_cost = commission + package_cost;
        }
      }
    }
    var total_package_price = flight_cost + visa_cost + package_cost;
    this.setState({ total_package_cost: total_package_price });
    this.props.change('total_package_price', total_package_price);
    this.props.change('commission', commission);
  }

  updateTerms(e) {
    e.preventDefault();
    if (this.state.settings && this.state.settings.quote_terms_conditions) {
      this.props.change('terms', this.state.settings.quote_terms_conditions);
    }
  }

  addNewHotelPopup(e) {
    e.preventDefault();
    this.setState({ openHotelPopup: true });
  }

  closeHotelPopup() {
    this.setState({ openHotelPopup: false });
  }

  updateHotelForm(record) {
    if (record.value) {
      this.setState({ selectedHotel: record.label, defaultHotelName: record })
      $("#hotel_id").val(record.value);
      $("#hotel_rating").val(record.star_rating);
      $("#hotel_city").val(record.city_name);
      $("#hotel_name").val(record.label);
    }
  }

  renderCountdown({ total, days, hours, minutes, seconds, milliseconds, completed }) {
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return (
      <div className="countdown-timer">
        {hours > 0 && <span className="countdown-timer-hours">{hours}:</span>}
        <span className="countdown-timer-minutes">{minutes}:</span>
        <span className="countdown-timer-seconds">{seconds}</span>
      </div>
    )
  }

  onCountDownComplete() {
    $('#completion_time_message').show();
  }

  updateDestinationType(e, destination_type) {
    var objThis = this;
    this.props.change('visa_cost', '');
    this.setState({ destination_type: destination_type });
    setTimeout(
      function () {
        objThis.updatePackagePrice();
      }, 200);
  }

  updateHotel(e) {
    console.log(e.target.checked)
    this.setState({ hotel_type: e.target.checked });
  }

  lastSentQuotesPopup(e) {
    if (e) {
      e.preventDefault();
    }
    this.setState({ openLastSentQuotesPopup: true, lastSentQuotesDestination: this.state.record.destination });
  }

  closeLastSentQuotesPopup() {
    this.setState({ openLastSentQuotesPopup: false });
  }

  maxLength = max => value => {
    let v;
    let result = value.length > max;
    console.log(result)
    if (result === false) {
      v = value;
    }
    return v;
  };

  render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    const objClass = this;
    if (this.state.redirectBack) {
      return <Redirect to={'/admin/quotes/follow_up/detail/' + this.state.id} />
    }
    // console.log(this.state.destination_type, "this.state.destination_type")
    // console.log(this.state.dataQuotation, "this.state.dataQuotation")
    return (
      <div className="dashboard-content">
        <MetaTags>
          <title>{WEBSITE_TITLE} – {this.state.id} send quotation</title>
        </MetaTags>
        <div className="content sm-gutter">
          <div className="container-fluid p-t-0 p-b-25 sm-padding-10 new-quotes">
            <div className="row">
              <div className="col-xl-8 col-md-12 col-sm-12 col-xs-12 new-quotes-left">
                {!(this.state.record && ((this.state.quotation_id && this.state.dataQuotation) || !this.state.quotation_id)) && <Loader />}
                {
                  this.state.record && ((this.state.quotation_id && this.state.dataQuotation) || !this.state.quotation_id) && this.state.inclusions &&
                  <form onSubmit={handleSubmit(this.submitForm.bind(this))} id="quote-form">
                    <Field name="quote_reply_id" type="hidden" component="input" />
                    <Field name="time_spent" type="hidden" component="input" />

                    <div className="title-fix">
                      <div className="title-fix-inner">
                        <div className="row">
                          <div className="col-lg-5">
                            <div className="quotes-id-block">
                              <h5 className="quotes--head">Give Quotation</h5>
                              <label>· ID {this.state.record && this.state.record.id}</label>
                              <div className="quote-option quote-search-block">
                                <span>Quote Option</span> <label>Last 15 Quotes</label>
                                <a href="#" onClick={(e) => this.lastSentQuotesPopup(e)} className="search-btn">Search</a>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-7">
                            <div className="qutation-btn-right">
                              <div className="time-left-block">
                                <span className="timer-msg" id="completion_time_message" style={{ display: 'none' }}></span>
                                <span className="time-icon"><img src="/assets/img/clock.svg" alt="" /></span>
                                {this.state.settings && this.state.settings.quote_completion_time && <Countdown onComplete={(e) => this.onCountDownComplete()} date={this.state.startTime + (parseInt(this.state.settings.quote_completion_time) * 1000)} renderer={this.renderCountdown.bind(this)} />}
                              </div>
                              <a href={this.state.last_link != "" ? "/admin/quotes/new/list" : "/admin/quotes/follow_up/detail/" + this.state.id} className="btn btn-border-blue">Cancel</a>
                              <button type="submit" disabled={submitting} className="btn btn-primary">SAVE & SEND {submitting && <i className="fa fa-spinner fa-spin"></i>}</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card info-card card-borderless tab-ui-block shadow-card quotes-detail-single sticky">
                      <div className="quotes-status-block active-quotes">
                        <div className="box-hide-wrapper">
                          <div className="box-hide-title">
                            <h6>BASIC DETAILS</h6>
                          </div>

                          <div className="inclusion-lists-wrapper">
                            <div className="form-group">
                              <label>Choose Destination Type</label>
                              <div className="radio radio-success">
                                {
                                  this.state.destination_type == 0 ?
                                    <Field type="radio" onChange={(e) => this.updateDestinationType(e, 0)} value="0" name="destination_type" id="destination_type_international" label="International" component={renderRadio} />
                                    :
                                    <Field type="radio" onChange={(e) => this.updateDestinationType(e, 1)} value="1" name="destination_type" id="destination_type_domestic" label="Domestic" component={renderRadio} />
                                }
                              </div>
                            </div>
                            <div className="form-block basic-detail-block ">
                              <Field type="hidden" name="quote_id" component="input" />
                              <Field type="text" name="days" component={renderField} parentDivClass="days-input" label='No. of Days' normalize={this.maxLength(2)} onChange={(e) => this.updateDays(e)} />
                              <Field type="text" name="nights" component={renderField} onChange={(e) => this.updateNights(e)} normalize={this.maxLength(2)} parentDivClass="nights-input" label='No. of Nights' />
                            </div>
                            <div className="form-block basic-detail-block ">
                              <Field name="currency" component={renderSelect} selectDataObject={CURRENCY} dont_show_select_option={false} fieldSet={{
                                id: 'symbol',
                                value: 'title'
                              }} label="Currency" fieldClass="cs-select cs-skin-slide" parentDivClass="currency form-group days-input" onChange={(e) => this.updateCurrency(e)} />
                              <div className="form-group">
                                <label>Choose Price Type</label>
                                <div className="radio radio-success" onChange={(e) => this.setState({ priceType: e.target.value })}>
                                  <Field type="radio" value="Per Person" name="price_type" id="per_person" label="COST PER PERSON" component={renderRadio} />
                                  <Field type="radio" value="Total Cost" name="price_type" id="whole_trip" label="Total Cost" component={renderRadio} />
                                </div>
                              </div>
                            </div>
                            <div className="form-block basic-detail-block plus-inside">
                              <Field type="text" name="flight_cost" ref="flightCost" component={renderField} parentDivClass="days-input" label='Flight Cost' placeholder="" onBlur={(e) => this.updatePackagePrice(e, 'flight_cost')} />
                              {this.state.destination_type != 1 && <Field type="text" name="visa_cost" ref="visaCost" component={renderField} parentDivClass="days-input" label='Visa Cost' placeholder="" onBlur={(e) => this.updatePackagePrice(e, 'visa_cost')} />}
                              <Field type="text" name="package_cost" ref="packageCost" component={renderField} parentDivClass="days-input" label='Land Package Cost' placeholder="" onBlur={(e) => this.updatePackagePrice(e, 'package_cost')} />
                              <Field type="hidden" name="commission" component={"input"} />
                            </div>
                            <div className="form-group ">
                              <label>Total Cost of Package {this.state.priceType !== "" ? "(" + this.state.priceType + ")" : null}</label>
                              <div className="price">
                                <CurrencyFormat value={this.state.total_package_cost} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={this.state.defaultCurrency + ' '} />
                                <Field type="hidden" name="total_package_price" component="input" />
                              </div>
                              <div className="include-small">
                                <small>(incl. of all taxes and commission fees)</small>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="box-hide-wrapper">
                          <div className="box-hide-title">
                            <h6>HOTEL DETAILS</h6>
                            <div className="radio-active">
                              <div className="checkbox check-primary checkbox-circle">
                                <Field type="checkbox" onChange={(e) => this.updateHotel(e)} id="hotel_details_checkbox" name="hotels_not_included" component="input" />
                                <label htmlFor="hotel_details_checkbox">Not Included</label>
                              </div>
                            </div>
                          </div>
                          {
                            this.state.hotel_type == true ? null :
                              <div className="include-hide">
                                <div className="sec-title">
                                  <h6>Add New Hotel Details</h6>
                                </div>
                                <div className="inclusion-lists-wrapper">
                                  <div className="form-block basic-detail-block ">
                                    <div className="form-group starting-date-input ">
                                      <label>Nights</label>
                                      {
                                        this.state.nights &&
                                        <div className="textfield-block">
                                          <Select name='drp-nights' closeMenuOnSelect={false} value={this.state.selectedNight} onChange={this.handleChange} options={this.state.nights} isMulti={true} />
                                          {/*<Select name='drp-nights' closeMenuOnSelect={false} onChange={this.handleChange} options={this.state.nights} isMulti={true}/>*/}
                                          <input type='hidden' id="nights_value_input" />
                                          <input type='hidden' id="nights_label_input" />
                                          <input type='hidden' id="edit_index" />
                                        </div>
                                      }
                                      <span class="error" style={{ display: 'none' }} id="hotel_nights_error">Please select</span>
                                    </div>
                                    <div className="form-group starting-date-input">
                                      <label>Hotel Name  <PermissibleRender userPermissions={this.state.permissions} requiredPermissions={['create_hotel']} ><a href="#" style={{ float: "right" }} onClick={(e) => this.addNewHotelPopup(e)}>+Add New Hotel</a></PermissibleRender></label>
                                      <div className="textfield-block">
                                        <AsyncSelect value={this.state.defaultHotelName} loadOptions={promiseHotelOptions} onChange={(record) => this.updateHotelForm(record)} />
                                        <span class="error" style={{ display: 'none' }} id="hotel_name_error">Please select</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-block basic-detail-block ">
                                    <div className="form-group starting-date-input">
                                      <label>City</label>
                                      <div className="textfield-block">
                                        <input type="text" className="textfield" placeholder="" id="hotel_city" readOnly="readOnly" />
                                        <input type="hidden" className="textfield" placeholder="" id="hotel_name" readOnly="readOnly" />
                                        <input type="hidden" className="textfield" placeholder="" id="hotel_id" readOnly="readOnly" />
                                      </div>
                                    </div>
                                    <div className="form-group days-input">
                                      <label>Hotel Category</label>
                                      <div className="textfield-block">
                                        <input type="text" className="textfield" placeholder="" id="hotel_rating" readOnly="readOnly" />
                                      </div>
                                    </div>
                                    <div className="form-group nights-input currency ">
                                      <label>Room Type</label>
                                      <div className="controls custom-select">
                                        <select name="hotel_room_type" id="hotel_room_type" className=" cs-select  ">
                                          <option value="-1">Select</option>
                                          {
                                            ROOM_TYPES.map((obj, index) => (
                                              <option key={index} value={obj.id}>{obj.value}</option>
                                            ))
                                          }
                                        </select>
                                      </div>
                                      <span class="error" style={{ display: 'none' }} id="hotel_room_error">Please select</span>
                                    </div>

                                  </div>
                                  <div className="form-block basic-detail-block ">
                                    <div className="form-group starting-date-input">
                                      <label>Comments (Optional)</label>
                                      <div className="textfield-block">
                                        <input type="text" className="textfield" id="hotel_comment" placeholder="Any additional information..." />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="button-group">
                                    <input type="button" className="btn btn-complete" value="SAVE HOTEL" onClick={(e) => { this.addHotel(e) }} />
                                    <input type="button" className="btn btn-reset" value="RESET" onClick={(e) => this.resetHotelForm(e)} />
                                  </div>
                                </div>
                                {
                                  this.state.hotelsData && this.state.hotelsData.length > 0 && this.state.hotelsData.map((obj, index) => (
                                    <div className="border-top-full" key={index}>
                                      <div className="table-normal">
                                        <table className="">
                                          <thead>
                                            <tr>
                                              <th scope="col">Nights</th>
                                              <th scope="col" width="22%">Hotel Name</th>
                                              <th scope="col">City</th>
                                              <th scope="col">Category</th>
                                              <th scope="col">Room Type</th>
                                              <th scope="col">Comments</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>{obj.hotel_label_nights}</td>
                                              <td>{obj.hotel_name}</td>
                                              <td>{obj.hotel_city}</td>
                                              <td>{obj.hotel_rating} Star</td>
                                              <td>{obj.hotel_room_type}</td>
                                              <td>{obj.hotel_comment}</td>
                                            </tr>

                                          </tbody>
                                        </table>
                                      </div>
                                      <div className="form-group">
                                        <div className="edit-btn">
                                          <a href="#" onClick={(e) => this.editHotel(e, index)}>Edit</a>
                                          <a href="#" onClick={(e) => this.deleteHotel(e, index)}>Delete</a>
                                        </div>
                                      </div>
                                    </div>))
                                }
                              </div>
                          }
                        </div>

                        <div className="box-hide-wrapper">
                          <div className="box-hide-title">
                            <h6>FLIGHT DETAILS</h6>
                            <div className="radio-active">
                              <div className="checkbox check-primary checkbox-circle">
                                <Field type="checkbox" id="flights_included_checkbox" name="flights_not_included" component="input" />
                                <label htmlFor="flights_included_checkbox">Not Included</label>
                              </div>
                            </div>
                          </div>
                          <div className="include-hide">
                            <div className="inclusion-lists-wrapper">
                              <Field name="flight_details" component={renderCKEditor} parentDivClass="form-group" label='Flight Details' fieldClass="" />
                            </div>
                          </div>
                        </div>
                        <div className="box-hide-wrapper">
                          <div className="box-hide-title">
                            <h6>CAB DETAILS</h6>
                            <div className="radio-active">
                              <div className="checkbox check-primary checkbox-circle">
                                <Field type="checkbox" id="cabs_included_checkbox" name="cabs_not_included" component="input" />
                                <label htmlFor="cabs_included_checkbox">Not Included</label>
                              </div>
                            </div>
                          </div>
                          <div className="include-hide">
                            <div className="inclusion-lists-wrapper">
                              <Field name="cab_details" component={renderCKEditor} parentDivClass="form-group" label='Cab details' fieldClass="" />
                            </div>
                          </div>
                        </div>
                        <div className="box-hide-wrapper">
                          <div className="box-hide-title">
                            <h6>Inclusions/Exclusions</h6>
                            <div className="radio-active">
                              <div className="checkbox check-primary checkbox-circle">
                                <input type="checkbox" id="checkbox12" />
                              </div>
                            </div>
                          </div>
                          <div className="include-hide">
                            <div className="inclusion-lists-wrapper">
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="inclusion-lists">
                                    <table className="heading-table">
                                      <thead>
                                        <tr>
                                          <th></th>
                                          <th>inclusion</th>
                                          <th>Exclusion</th>
                                          {/*<th>Do Not Show</th>*/}
                                        </tr>
                                      </thead>
                                    </table>
                                    {
                                      !this.state.quotation_id && this.state.inclusions && this.state.inclusions.length > 0 && <FieldArray name="inclusions" component={this.renderInclusions} destination_type={this.state.destination_type} />
                                    }
                                    {
                                      this.state.quotation_id && this.state.dataQuotation && <FieldArray name="inclusions" component={this.renderInclusions} />
                                    }

                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="section-pink">
                              <div className="inclusion-lists-wrapper">
                                <div className="row">
                                  <div className="col-xl-4">
                                    <div className="freebies-left">
                                      <h4>Honeymoon Freebies</h4>
                                      <ul>
                                        <li>
                                          <div className="radio radio-success">
                                            <Field name="honeymoon_freebies_included" type="radio" component="input" value="yes" id="honeymoon_freebies_included_yes" />
                                            <label htmlFor="honeymoon_freebies_included_yes">Included</label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="radio radio-success">
                                            <Field name="honeymoon_freebies_included" type="radio" component="input" value="no" id="honeymoon_freebies_included_no" />
                                            <label htmlFor="honeymoon_freebies_included_no">Not Included</label>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="col-lg-8">
                                    <Field type="text" name="honeymoon_freebies_description" component={renderTextarea} parentDivClass="" fieldClass="form-control" label='' placeholder="Write here..." />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="box-hide-wrapper">
                          <div className="box-hide-title">
                            <h6>Day Wise Itinerary</h6>
                          </div>
                          <div className="form-group days-input">
                            <label>Starting Date</label>
                            <div className="input-group date">
                              <DatePicker
                                selected={this.state.startDate}
                                onChange={this.handleStartDateChange}
                                className="form-control textfield"
                              />
                              <div className="input-group-append ">
                                <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="days-accordian">
                          {this.state.record && <FieldArray component={this.renderItinerary} name="itinerary" />}
                        </div>
                        <div className="box-hide-title">
                          <h6>MISCELLANEOUS</h6>
                        </div>
                        <div className="inclusion-lists-wrapper">
                          <div className="row">
                            <div className="col-md-6">
                              <a href="#" className="default-use" style={{ position: 'absolute', zIndex: 1, right: 15 + 'px' }} onClick={(e) => this.updateTerms(e)}>Use Default</a>
                              <Field type="text" name="terms" component={renderCKEditor} parentDivClass="form-group" fieldClass="" label='Terms & Conditions' placeholder="Write here..." />
                            </div>
                            <div className="col-md-6">
                              <Field type="text" name="other_information" component={renderCKEditor} parentDivClass="form-group" fieldClass="" label='Other Information' placeholder="Write here..." />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                }
              </div>
              {
                this.state.record &&
                <div className="col-xl-4 col-md-12 col-sm-12 col-xs-12 right-sidebar">
                  <div className="card info-card card-borderless tab-ui-block shadow-card right-sidebar-fix">
                    <div className="head-block-filter">
                      <h2>Traveler Requirements</h2>
                    </div>
                    <TravelerInfo travelerInfo={this.state.record} defaultColclassName="col-sm-6" specialContentClass="special--request-block" />
                  </div>
                </div>
              }
            </div>

          </div>
        </div>
        {this.state.openHotelPopup === true && <HotelAdd openHotelPopup={this.state.openHotelPopup} closeHotelPopup={this.closeHotelPopup} updateHotelForm={this.updateHotelForm} />}
        {this.state.openLastSentQuotesPopup === true && <LastSentQuotations trip_id={this.state.id} lastSentQuotesDestination={this.state.lastSentQuotesDestination} openLastSentQuotesPopup={this.state.openLastSentQuotesPopup} closeLastSentQuotesPopup={this.closeLastSentQuotesPopup} />}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  var record = null;
  var inclusions = null;
  if (state.quote.quote_detail) {
    record = state.quote.quote_detail;
  }
  if (state.quote.inclusions) {
    inclusions = state.quote.inclusions;
  }
  return {
    user: { ...state.user },
    record: record,
    inclusions: inclusions,
    status: state.quote.status,
    formValues: state.form.QuoteForm ? state.form.QuoteForm.values : null,
    settings: state.quote.settings ? { ...state.quote.settings } : null
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    quoteDetail: (data) => {
      return dispatch(quoteDetail(data));
    },
    quoteDetailSuccess: (payload) => {
      dispatch(quoteDetailSuccess(payload));
    },
    inclusionsList: () => {
      return dispatch(inclusionsList());
    },
    inclusionsListSuccess: (payload) => {
      dispatch(inclusionsListSuccess(payload));
    },
    saveQuote: (data) => {
      return dispatch(saveQuote(data));
    },
    quotationDetailForEdit: (params) => {
      return dispatch(quotationDetailForEdit(params));
    },
    updateQuote: (params) => {
      return dispatch(updateQuote(params));
    }
  };
}
QuoteForm = connect(mapStateToProps, mapDispatchToProps)(QuoteForm);
QuoteForm = reduxForm({
  form: 'QuoteForm', // a unique identifier for this form
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(QuoteForm);


export default QuoteForm;
