import axios from 'axios';
import { API_ROOT, confirmDelete, _dispatch } from '../constants';

export {confirmDelete};
export {_dispatch};

export const LIST_COUNTRIES = 'LIST_COUNTRIES';
export const LIST_COUNTRIES_SUCCESS = 'LIST_COUNTRIES_SUCCESS';
export const LIST_STATES = 'LIST_STATES';
export const LIST_STATES_SUCCESS = 'LIST_STATES_SUCCESS';
export const LIST_CITIES = 'LIST_CITIES';
export const LIST_CITIES_SUCCESS = 'LIST_CITIES_SUCCESS';
export const SEARCH_CITIES = 'SEARCH_CITIES';



const ROOT_URL = API_ROOT;
var token = "";
export function listCountries(params) {
  token = localStorage.getItem('jwtToken');
  //alert(token);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/countrylist`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: LIST_COUNTRIES,
    payload: request
  };
}

export function listCountriesSuccess(payload){
	return {
    	type: LIST_COUNTRIES_SUCCESS,
    	payload: payload
  	}
}

export function listStates(params) {
  token = localStorage.getItem('jwtToken');
  //alert(token);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/statelist`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: LIST_STATES,
    payload: request
  };
}

export function listStatesSuccess(payload){
	return {
    	type: LIST_STATES_SUCCESS,
    	payload: payload
  	}
}

export function listCities(params) {
  token = localStorage.getItem('jwtToken');
  //alert(token);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/citylist`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: LIST_CITIES,
    payload: request
  };
}

export function listCitiesSuccess(payload){
	return {
    	type: LIST_CITIES_SUCCESS,
    	payload: payload
  	}
}

export function searchCities(params) {
  token = localStorage.getItem('jwtToken');
  //alert(token);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/citysearch`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return {
    type: SEARCH_CITIES,
    payload: request
  };
}
