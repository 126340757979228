
import {
  SAVE_ROLE, SAVE_ROLE_SUCCESS, DELETE_ROLE, ROLE_LIST, UPDATE_ROLE_SUCCESS, UPDATE_ROLE_FAILURE, _dispatch
} from '../actions/roles';

import {FromatValidationErrors} from "../constants";
const INITIAL_STATE = { roles: [],status:null};
export default function(state = INITIAL_STATE, action) {
  let error;
  let keys;
  switch(action.type) {
    case DELETE_ROLE:
      return (() => {
        const { index} = action.payload;
        const roles = [...state.roles];
        roles.splice(index, 1);
        return _dispatch({ ...state, roles}, true);
      })();
    case ROLE_LIST:
      return (() => {
        const roles = action.payload.data;
        return _dispatch({ ...state, roles}, true);
      })();

    default:
      return state;
  }
}
