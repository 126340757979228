import {
  _dispatch, NEW_QUOTE_LISTING_SUCCESS, QUOTE_DETAIL_SUCCESS, INCLUSIONS_LIST, INCLUSIONS_LIST_SUCCESS, QUOTE_REJECTION_REASONS_LIST_SUCCESS,
  FETCH_QUOTE_LISTING_FILTERS_SUCCESS, FETCH_LEAD_LISTING_FILTERS_SUCCESS, FETCH_PAYMENTS_SUCCESS, FETCH_SETTINGS_SUCCESS, FETCH_AlL_QUOTES_LISTING_FILTERS_SUCCESS
} from '../actions/quotes';

const INITIAL_STATE = { new_quotes:[], status:null };

export default function(state = INITIAL_STATE, action) {
  let error;
  let keys;
  switch(action.type) {
    case NEW_QUOTE_LISTING_SUCCESS:
      return (() => {
        const new_quotes = action.payload;
        return _dispatch({ ...state, new_quotes}, true);
      })();

    case QUOTE_DETAIL_SUCCESS:
     return (() => {
       const quote_detail = action.payload;
       return _dispatch({ ...state, quote_detail}, false);
     })();

     case INCLUSIONS_LIST_SUCCESS:
       return (() => {
         const inclusions = action.payload;
         return _dispatch({ ...state, inclusions}, false);
       })();

    case QUOTE_REJECTION_REASONS_LIST_SUCCESS:
      return (() => {
        const rejection_reasons = action.payload;
        return _dispatch({ ...state, rejection_reasons}, false);
      })();

    case FETCH_LEAD_LISTING_FILTERS_SUCCESS:
      return (() => {
        if(action.lead_type === 'verified'){
          const lead_filters_verified = action.payload;
          return _dispatch({ ...state, lead_filters_verified}, true);
        }else if(action.lead_type === 'unverified'){
          const lead_filters_unverified = action.payload;
          return _dispatch({ ...state, lead_filters_unverified}, true);
        }else if(action.lead_type === 'rejected'){
          const lead_filters_rejected = action.payload;
          return _dispatch({ ...state, lead_filters_rejected}, true);
        }
        return _dispatch({ ...state});
      })();

    case FETCH_QUOTE_LISTING_FILTERS_SUCCESS:
      return (() => {
        const quote_filters = action.payload;
        return _dispatch({ ...state, quote_filters}, true);
      })();

    case FETCH_AlL_QUOTES_LISTING_FILTERS_SUCCESS:
      return (() => {
        const all_quotes_filters = action.payload;
        return _dispatch({ ...state, all_quotes_filters}, true);
      })();

    case FETCH_PAYMENTS_SUCCESS:
      return (() => {
        const payments = action.payload;
        return _dispatch({ ...state, payments}, true);
      })();

    case FETCH_SETTINGS_SUCCESS:
      return (() => {
        const settings = action.payload;
        return _dispatch({ ...state, settings}, true);
      })();

    default:
      return state;
  }
}
