import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, SubmissionError, reset } from 'redux-form';
import renderFieldLabelTransition from '../FormFields/renderFieldLabelTransition';
import {Redirect, BrowserRouter, Link} from 'react-router-dom';
import { forgotPassword, forgotPasswordSuccess } from '../../actions/users';
import { NotificationManager} from 'react-notifications';
import createHistory from 'history/createBrowserHistory';
import {toast} from 'react-toastify';
import { validateEmail} from '../../constants';
import $ from 'jquery';

const history = createHistory();

//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.email || values.email.trim() === '') {
    errors.email = 'Enter email';
    hasErrors = true;
  }else{
    var emailId = values.email.trim();
    if(!validateEmail(emailId)){
      errors.email = "Email format is not correct";
      hasErrors = true;
    }
  }

  return hasErrors && errors;
}

//submit login form
const submitForm = (values, dispatch, props) => {
  return dispatch(forgotPassword(values))
    .then((result) => {
      if (result.value.data.result == 'failure') {
        toast.error(result.value.data.flash_message);

      } else {
        dispatch(reset("ForogotPasswordForm"));
        dispatch(forgotPasswordSuccess(result.value.data.data.user));
      }
    });
  };


class ForgotPasswordForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      is_submitting: false,
      isLoggedIn: typeof props.user.status === 'undefined' ? false : props.user.status
    }
  }

  componentDidMount(){
    if($('.onclick').length > 0){
      $('.onclick').find('input[type=text],input[type=email], input[type=password], textarea').each(function(){
        if($(this).val().replace(/\s+/, '') != '')
          $(this).addClass('active');
      });
      $('.onclick').find('input[type=text],input[type=email], input[type=password], textarea').change(function(){
        if($(this).val().replace(/\s+/, '') == ''){
          $(this).val('').removeClass('active');
        }else
          $(this).addClass('active');
      });
    }

    $('.show-password-option').click(function(){
      $(this).toggleClass('active');
      //$('#password-field').attr("type", 'text');
      var input = $($(this).attr("toggle"));
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
      if ($('.eye-icon').attr("name") == "md-eye-off") {
        $('.eye-icon').attr("name", "md-eye");
      } else {
        $('.eye-icon').attr("name", "md-eye-off");
      }

    })
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.user.status === 'authenticated'){
      this.setState({
        isLoggedIn: true
      });
    }
  }

  render() {
    if (this.state.isLoggedIn === true) {
      return <Redirect to={{
        pathname: '/',
        state: { from: this.props.location }
      }}/>
    }
    const {handleSubmit, pristine, submitting} = this.props;
    return (
      <div className="form-block-section">
        <form onSubmit={handleSubmit(submitForm)} className = "p-t-15">

            <Field
              name="email"
              component={renderFieldLabelTransition}
              type="text"
              className="form-control"
              label="Email"
              placeholder="Email address"
            />

          <button type="submit" disabled={submitting} className="btn-primary-full">RECOVER PASSWORD {submitting && <i className="fa fa-spinner fa-spin"></i>}</button>
          <div className="action-login-btm">
            <div className="forgot-psd">
              <Link to="/signin">SIGN IN?</Link>
            </div>

          </div>
        </form>
      </div>
    )
  }
}

ForgotPasswordForm = reduxForm({
  form: 'ForgotPasswordForm',
  validate
})(ForgotPasswordForm)

const mapStateToProps = (state, ownProps) => {
  return {
    user: {...state.user}
  }
}

export default connect(mapStateToProps)(ForgotPasswordForm);
