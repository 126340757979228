import React, { Component } from 'react';
import { Route, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Moment from 'react-moment';
import 'moment-timezone';
import { objToQueryParams, QUOTE_NUMBERS, yyyymmdd, mysqlDateToJavascript } from "../../../constants";
import { quoteDetail, quoteDetailSuccess } from '../../../actions/quotes';
import TravelerInfo from './TravelerInfo';
import QuoteInvoice from './QuoteInvoice';
import { PermissibleRender } from '@brainhubeu/react-permissible';

import $ from 'jquery';

class QuotationDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permissions: props.user && props.user.user ? props.user.user.permissions : [],
      record: props.record,
      travelerInfo: props.travelerInfo,
      quotation_index: props.quotation_index,
      showInvoiceForm: false,
      quote_status: props.quote_status
    };
  }

  componentDidMount() {
    $('.pd-btn').on('click', function () {
      $(this).parent('.Package-full-details').toggleClass('sec-hide');
      $(this).parent('.Package-full-details').find('.full-details').slideToggle();
      $(this).text(
        $(this).text() == 'Show Less'
          ? 'Show More'
          : 'Show Less');

    });
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.quotation_index != nextProps.quotation_index) {
      this.setState({ record: nextProps.record, quotation_index: nextProps.quotation_index, travelerInfo: nextProps.travelerInfo });
    }
  }

  showInvoiceForm(e) {
    this.setState({ showInvoiceForm: true });
    e.preventDefault();
    setTimeout(function () {
      $('.create-invoice-block').slideDown();
      $('html, body').animate({
        scrollTop: $(".create-invoice-block").offset().top
      }, 500);
    }, 500);
  }


  render() {
    var record = this.state.record;
    console.log(record, "record")
    return (
      <div className="tab-content">
        {
          <div className="tab-pane active" id={"tab-fillup"}>
            <div className="row">
              <div className="col-md-6">
                <div className="sec-title  twt">
                  <h6>Quotation Details</h6>
                  <p className="quote-price">{QUOTE_NUMBERS[this.state.quotation_index]} Quote · {record.currency} {parseInt(record.flight_cost ? record.flight_cost : 0) + parseInt(record.visa_cost ? record.visa_cost : 0) + parseInt(record.package_cost ? record.package_cost : 0)}/<small>{record.price_type}</small>
                  </p>
                </div>
              </div>
              <div className="col-md-6 text-right ">
                <div className="qutation-btn-group">
                  {
                    ((this.state.quote_status == 0 || this.state.quote_status == 1 || this.state.quote_status == 3) && (this.state.record.accepted_quote != 2)) &&
                    <Link to={"/admin/quotes/edit/" + record.quote_id + "/" + record.id} className="btn btn-edit-g">
                      <i><img src="/assets/img/ic_edit_white@1x.svg" alt="" /></i>Edit Quote</Link>
                  }
                  <a href="javascript:void(0);" className="btn btn-download icon-btn">
                    <i><img src="/assets/img/ic_download_green@1x.svg" alt="" /></i>
                  </a>
                  <a href="javascript:void(0);" className="btn btn-trash btn-md icon-btn">
                    <i><img src="/assets/img/ic_delete@1x.svg" alt="" /></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="border-top-full">
              <TravelerInfo travelerInfo={this.state.travelerInfo} info={record} quickView={true} />
            </div>
            <div className="Package-full-details sec-hide">
              <div className="full-details" style={{
                display: 'none'
              }}>
                <div className="special-content">
                  <label>Hotel Details</label>
                </div>
                <div className="table-normal small-qa-desc">
                  {
                    record.hotels_not_included == 0 && record.hotels && record.hotels.length > 0 && <table>
                      <thead>
                        <tr>
                          <th scope="col">Nights</th>
                          <th scope="col" width="30%">Hotel Name</th>
                          <th scope="col">City</th>
                          <th scope="col">Type</th>
                          <th scope="col">Comments</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          record.hotels.map((objHotel, indexHotel) => (<tr key={indexHotel}>
                            <td>{objHotel.hotel_label_nights}</td>
                            <td>{objHotel.hotel_name}</td>
                            <td>{objHotel.hotel_city}</td>
                            <td>{objHotel.hotel_rating}
                                Star . {objHotel.hotel_room_type}</td>
                            <td>{objHotel.hotel_comment}</td>
                          </tr>))

                        }
                      </tbody>
                    </table>
                  }
                  {record.hotels_not_included != 0 && <p>Hotels not included</p>}

                </div>
                <div className="special-content pr-top25">
                  <label>Flight Details</label>
                </div>
                <div className="small-qa-desc">
                  {record.flights_not_included == 0 && <p dangerouslySetInnerHTML={{ __html: record.flight_details }} />}
                  {record.flights_not_included != 0 && <p>Flights not included</p>}
                </div>

                <div className="special-content pr-top20">
                  <label>Cab Details</label>
                </div>
                <div className="small-qa-desc">
                  {record.cabs_not_included == 0 && <p dangerouslySetInnerHTML={{ __html: record.cab_details }} />}
                  {record.cabs_not_included != 0 && <p>Cabs not included</p>}
                </div>

                <div className="inclusion-wraooer pr-top20">

                  <div className="inclusion-list">

                    <div className="special-content ">
                      <label>Inclusions</label>
                    </div>
                    <div className="datails-list">
                      <ul>
                        {
                          record.inclusion && record.inclusion.length > 0 && record.inclusion.map((objInclusion, indexInclusion) => (objInclusion.inclusion == 1 && <li key={indexInclusion}>{
                            objInclusion.category == 'Other Inclusions'
                              ? "Others"
                              : objInclusion.category
                          }:{objInclusion.title}</li>))
                        }

                      </ul>
                    </div>
                  </div>
                  <div className="exclusion-list">

                    <div className="special-content">
                      <label className="highlight-red">Exclusions</label>
                    </div>
                    <div className="datails-list">
                      <ul>
                        {
                          record.inclusion && record.inclusion.length > 0 && record.inclusion.map((objInclusion, indexInclusion) => (objInclusion.inclusion != 1 && <li key={indexInclusion}>{
                            objInclusion.category == 'Other Inclusions'
                              ? "Others"
                              : objInclusion.category
                          }:{objInclusion.title}</li>))
                        }
                      </ul>
                    </div>
                  </div>

                </div>

                <div className="special-content pr-top20">
                  <label>DAY WISE ITINERARY</label>
                </div>
                {
                  record.itinerary && record.itinerary.length > 0 && record.itinerary.map((objItinerary, indexItinerary) => {
                    var dayDate = yyyymmdd(mysqlDateToJavascript(objItinerary.trip_date));

                    return (
                      <div className="small-qa-desc" key={indexItinerary}>
                        <h4>Day {indexItinerary + 1}&nbsp;({dayDate[1]})
                        . {objItinerary.title}</h4>
                        <p dangerouslySetInnerHTML={{ __html: objItinerary.description }} />
                        {objItinerary.total_duration != "" && <p><strong>Total Duration: </strong>{objItinerary.total_duration}</p>}
                        {objItinerary.activities != "" && <p><strong>Activities: </strong>{objItinerary.activities}</p>}
                        {objItinerary.optional_activities != "" && <p><strong>Optional Activities: </strong>{objItinerary.optional_activities}</p>}
                        {objItinerary.locations != "" && <p><strong>Locations: </strong>{objItinerary.locations}</p>}
                      </div>)
                  }
                  )
                }

              </div>
              <a href="javascript:void(0);" className="pd-btn">Show More</a>

              <div className="sec-title  twt pr-top20">
                <h6>Quick Actions</h6>
              </div>

              <div className="row">
                <div className="col-md-6">

                  <div className="qutation-btn-group">
                    {
                      ((this.state.quote_status == 0 || this.state.quote_status == 1 || this.state.quote_status == 3) && (this.state.record.accepted_quote != 2)) &&
                      <Link to={"/admin/quotes/edit/" + record.quote_id + "/" + record.id} className="btn btn-edit-tb">
                        <i><img src="/assets/img/ic_edit_blue@1x.svg" alt="" /></i>EDIT QUOTE</Link>
                    }
                    <a href="javascript:void(0);" className="btn btn-edit-tg">
                      <i><img src="/assets/img/ic_download_green@1x.svg" alt="" /></i>
                      DOWNLOAD</a>
                  </div>
                </div>
                <div className="col-md-6">
                  <PermissibleRender userPermissions={this.state.permissions} requiredPermissions={['create_invoice']} >
                    <div className="text-right qutation-btn-group">
                      {
                        this.state.record.accepted_quote != 2 &&
                        <a href="javascript:void(0);" className="btn btn-edit-g create-invoice" onClick={(e) => this.showInvoiceForm(e, record)}>
                          <i><img src="/assets/img/ic_invoice@1x.svg" alt="" /></i>{
                            ((this.state.quote_status == 0 || this.state.quote_status == 1 || this.state.quote_status == 3) && (this.state.record.accepted_quote != 2)) ? (record.invoice ? "EDIT INVOICE" : "CREATE INVOICE") : "View Invoice"}</a>
                      }

                      {/*<a href="javascript:void(0);" className="btn btn-trash icon-btn">
                      <i><img src="/assets/img/ic_delete@1x.svg" alt=""/></i>
                    </a>*/}
                    </div>
                  </PermissibleRender>
                </div>
              </div>
            </div>
          </div>
        }
        {this.state.showInvoiceForm && <QuoteInvoice quote_status={this.state.quote_status} invoice_quotation_data={record} quotation_index={this.state.quotation_index} />}
      </div>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    user: { ...state.user },
    status: state.quote.status
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    quoteDetail: (data) => {
      return dispatch(quoteDetail(data));
    },
    quoteDetailSuccess: (payload) => {
      dispatch(quoteDetailSuccess(payload));
    }

  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(QuotationDetail);
