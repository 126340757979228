import React, { Component } from 'react';
import { Route, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Moment from 'react-moment';
import Loader from '../../../components/Loader';
import 'moment-timezone';
import { objToQueryParams, WEBSITE_TITLE } from "../../../constants";
import { listNewQuotes, listNewQuotesSuccess, listQuoteRejectionReasons, listQuoteRejectionReasonsSuccess, fetchQuoteListingFilters, fetchQuoteListingFiltersSuccess } from '../../../actions/quotes';
import TravelerInfo from './TravelerInfo';
import QuoteFilters from './QuoteFilters';
import RejectQuote from './RejectQuote';
import QuoteNotesNew from './QuoteNotesNew';
import $ from 'jquery';
import CurrencyFormat from 'react-currency-format';
import MetaTags from 'react-meta-tags';
import { PermissibleRender } from '@brainhubeu/react-permissible';

class FollowUpQuotesList extends Component {
  constructor(props) {
    super(props);
    var page = 1;
    var searchQuery = '';
    if (props.location.search) {
      const values = queryString.parse(this.props.location.search);
      if (values.page) {
        page = values.page;
      }
      if (values.search) {
        searchQuery = values.search;
      }
    }
    this.state = {
      permissions: props.user && props.user.user ? props.user.user.permissions : [],
      page: page,
      searchQuery: searchQuery,
      status: props.status,
      records: props.quotes ? props.quotes : [],
      openEditForm: false,
      rejection_reasons: props.rejection_reasons ? props.rejection_reasons : null,
      quoteFilterData: props.quote_filters ? props.quote_filters.replied : null,
      fetchingRecords: true,
    };
    this.fetchRecords = this.fetchRecords.bind(this);
  }

  componentDidMount() {
    this.fetchRecords();
    this.fetchRejectionReasons();
    this.fetchQuoteFilters();

  }

  fetchQuoteFilters() {
    this.props.fetchQuoteListingFilters().then((response) => {
      if (response.value.data.result === 'success') {
        this.props.fetchQuoteListingFiltersSuccess(response.value.data.payload);
      }
    })
  }

  fetchRejectionReasons() {
    this.props.listQuoteRejectionReasons().then((response) => {
      if (response.value.data.result === 'success') {
        this.props.listQuoteRejectionReasonsSuccess(response.value.data.payload);
      }
    })
  }



  fetchRecords(params) {
    if (!params) {
      params = { followed_up: 1 };
    } else {
      params = Object.assign(params, { followed_up: 1 });
    }
    this.setState({ fetchingRecords: true });
    this.props.listNewQuotes(params).then((response) => {
      this.setState({ fetchingRecords: false });
      if (response.value.data.result === 'success') {
        this.props.listNewQuotesSuccess(response.value.data.payload);
        this.setState({ fetchingResults: false });
      }
    })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.status != nextProps.status) {
      this.setState({ records: nextProps.quotes });
    }
    if (!this.props.rejection_reasons && nextProps.rejection_reasons) {
      this.setState({ rejection_reasons: nextProps.rejection_reasons });
    }
    if (!this.props.quote_filters && nextProps.quote_filters) {
      this.setState({ quoteFilterData: nextProps.quote_filters.replied });
    }
  }

  render() {
    return (
      <div className="dashboard-content">
        <MetaTags>
          <title>{WEBSITE_TITLE} – quotation follow up</title>
        </MetaTags>
        <div className="content sm-gutter">
          <div className="container-fluid p-t-0 p-b-25 sm-padding-10 new-quotes">
            <QuoteFilters quoteFilterData={this.state.quoteFilterData} fetchRecords={this.fetchRecords} fetchingRecords={this.state.fetchingRecords} listPage="follow_up" />
            <div className="filter-height"></div>
            <div className="row">
              {
                this.state.fetchingRecords && <Loader />
              }
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 new-quotes-left">
                <div className="header-block-ui no-border">
                  <h3>Quotations Follow Up</h3>
                  {/*<div className="filter-sort">
                      <label>Sort by </label>
                      <select className="cs-select cs-skin-slide select-style-ui" data-init-plugin="cs-select">
                        <option value="sightseeing">Latest</option>
                        <option value="business">Popular</option>
                      </select>
                    </div>*/}
                </div>

                {
                  this.state.records.length > 0 &&
                  this.state.records.map((obj, inxex) => (
                    <div key={obj.id} className="card info-card card-borderless tab-ui-block shadow-card quotation-follow">
                      <div className="quotes-status-block active-quotes">
                        <div className="quotes-id-block">
                          <div className="qi-left-block">
                            <a target="_blank" href={"/admin/quotes/follow_up/detail/" + obj.id}><label>ID {obj.id}</label></a>
                            <div className="id-status">Active</div>
                            <time className="cls-time"><Moment format="DD MMM, YY h:mm a" fromNow fromNowDuring={360000000000}>{obj.created_at + '-0000'}</Moment></time>
                          </div>
                          <div className="qi-right-block">
                            <ul>

                              {/* <i className="quote-follow-icon">
                                <img src="/assets/img/ic_visibility.svg" alt=""/>
                              </i> */}
                              {
                                obj.quotation_read_id != 0 &&
                                <li>
                                  <i className="quote-follow-icon hide-icon">
                                    <img src="/assets/img/ic_visibility.svg" alt="" />
                                  </i>
                                  <div className="quote-follow-content">
                                    <p>Quote Worth <CurrencyFormat value={obj.total_package_price} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={obj.currency + ' '} /> seen <i><Moment format="DD MMM, YY h:mm a" fromNow fromNowDuring={3600000000}>{obj.read_time + '-0000'}</Moment></i></p>

                                  </div>
                                </li>
                              }
                              {
                                obj.quotation_read_id == 0 &&
                                <li>
                                  <i className="quote-follow-icon hide-icon">
                                    <img src="/assets/img/ic_visibility.svg" alt="" />
                                  </i>
                                  <div className="quote-follow-content">
                                    <p>Quote not seen yet</p>

                                  </div>
                                </li>
                              }
                              {
                                obj.followup_time &&
                                <li>
                                  <i className="quote-follow-icon">
                                    <img src="/assets/img/restore.svg" alt="" />
                                  </i>
                                  <div className="quote-follow-content">
                                    <p>Last followed up about <i><Moment format="DD MMM, YY h:mm a" fromNow fromNowDuring={3600000000}>{obj.followup_time + '-0000'}</Moment></i></p>
                                  </div>
                                </li>
                              }

                            </ul>
                          </div>
                        </div>
                        <div className="content-quotes">
                          <h3>Quote request by {obj.name} from {obj.departure} to {obj.destination}</h3>
                        </div>
                        <TravelerInfo quickView={true} travelerInfo={obj} />

                        <div className="qutoes-block-bottom ">
                          <div className="quote-user">
                            <figure>
                              <img src="/assets/img/alexa.png" />
                            </figure>
                            <figcaption >
                              <label>{obj.name}</label>
                              <PermissibleRender userPermissions={this.state.permissions} requiredPermissions={['view_client_info']} >
                                <span className="user-number">+91-7384949382</span>
                              </PermissibleRender>
                            </figcaption>
                          </div>
                          <div className="new-quote-action" >
                            <PermissibleRender userPermissions={this.state.permissions} requiredPermissions={['manage_quotation_followup', 'view_quotation']} >
                              <a href={"/admin/quotes/follow_up/detail/" + obj.id} target="_blank" className="btn btn-primary">View Details</a>
                              {/* this.state.rejection_reasons && this.state.rejection_reasons.length > 0 && <RejectQuote rejection_reasons={this.state.rejection_reasons} quote_id = {obj.id} fetchRecords = {this.fetchRecords} />*/}
                              <QuoteNotesNew trip_id={obj.id} type="button" elementId={"note_container_" + obj.id} />
                            </PermissibleRender>
                          </div>
                        </div>
                      </div>
                      <div className="bg-light-blue">
                        <div className="row">
                          <div className="col-md-8">
                            <div className="last-message">
                              <h6>LAST MESSAGE</h6>
                              {obj.message != "" && <p>{obj.message}</p>}
                              {obj.message == "" && <p>----</p>}
                              {obj.message && <span className="last-message-time"><Moment format="DD MMM, YY &nbsp; h:mm a" >{obj.message_date + '-0000'}</Moment></span>}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="last-message">
                              <h6>NOTES</h6>
                              {obj.note != "" && <p id={"note_container_" + obj.id}>{obj.note_main_option}</p>}
                              {obj.note == "" && <p id={"note_container_" + obj.id}>----</p>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                }
                {

                  !this.state.fetchingRecords && this.state.records.length <= 0 &&
                  <div className="card info-card card-borderless tab-ui-block shadow-card quotation-follow">
                    <div className="quotes-unavailable">
                      <figure className="icon-block"><ion-icon name="list-box"></ion-icon></figure>
                      <figcaption>
                        <p>There are no quotes for followup are available at this time</p>
                      </figcaption>
                    </div>
                  </div>
                }
              </div>

            </div>
          </div>
        </div>
      </div>

    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    user: { ...state.user },
    quotes: state.quote.new_quotes,
    status: state.quote.status,
    rejection_reasons: state.quote.rejection_reasons ? state.quote.rejection_reasons : null,
    quote_filters: state.quote.quote_filters ? state.quote.quote_filters : null,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    listNewQuotes: (data) => {
      return dispatch(listNewQuotes(data));
    },
    listNewQuotesSuccess: (payload) => {
      dispatch(listNewQuotesSuccess(payload));
    },
    listQuoteRejectionReasons: (params) => {
      return dispatch(listQuoteRejectionReasons(params));
    },
    listQuoteRejectionReasons: (params) => {
      return dispatch(listQuoteRejectionReasons(params));
    },
    listQuoteRejectionReasonsSuccess: (data) => {
      dispatch(listQuoteRejectionReasonsSuccess(data));
    },

    fetchQuoteListingFilters: () => {
      return dispatch(fetchQuoteListingFilters());
    },
    fetchQuoteListingFiltersSuccess: (data) => {
      dispatch(fetchQuoteListingFiltersSuccess(data));
    }

  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(FollowUpQuotesList);
