import React, { Component } from 'react';

class Dashboard extends Component {
  	render() {
      return (
        <div className="dashboard-content">
          <div className="content sm-gutter">
            <div className="container-fluid p-t-0 p-b-25 sm-padding-10">
              <div className="header-block-ui">
                <h3>Dashboard</h3>
                {/*<div className="collapse-button">
                  <a href="javascript:void(0);">COLLAPSE</a>
                </div>*/}
              </div>
            </div>
            <div className="container-fluid p-t-0 p-b-25 sm-padding-10">
              <div className="row">
                <div className="col-lg-4 col-sm-6  d-flex flex-column">
                  <div className="card social-card info-card full-width m-b-10 no-border shadow-card b-r-6">
                    <div className="card-header ">
                      <h5 className="pull-left">Packages Sold <i className="fa fa-circle text-complete fs-6"></i></h5>
                      <div className="pull-right small hint-text">
                        <a href="javascript:void(0);">DETAILS</a>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                    <div className="card-description">
                      <div className="card card-borderless">
                        <div className="tab-content">
                          <div className="tab-pane active" id="tab2hellowWorld">
                            <div className="info-tab-count">
                              <div className="counter-tab">04</div>
                            </div>
                          </div>
                          <div className="tab-pane " id="tab2FollowUs">
                            <div className="info-tab-count">
                              <div className="counter-tab">15</div>
                            </div>
                          </div>
                          <div className="tab-pane" id="tab2Inspire">
                            <div className="info-tab-count">
                              <div className="counter-tab">31</div>
                            </div>
                          </div>
                        </div>
                        <div className="img-card">
                          <img src="/assets/img/luggage.png" />
                        </div>
                      </div>
                    </div>
                    <div className="card-footer clearfix">
                      <ul className="nav nav-tabs nav-tabs-simple" role="tablist" data-init-reponsive-tabs="dropdownfx">
                          <li className="nav-item">
                            <a className="active" data-toggle="tab" role="tab" data-target="#tab2hellowWorld" href="#">TODAY</a>
                          </li>
                          <li className="nav-item">
                            <a href="#" data-toggle="tab" role="tab" data-target="#tab2FollowUs">LAST WEEK</a>
                          </li>
                          <li className="nav-item">
                            <a href="#" data-toggle="tab" role="tab" data-target="#tab2Inspire">LAST MONTH</a>
                          </li>
                        </ul>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-sm-6  d-flex flex-column">
                  <div className="card social-card info-card full-width m-b-10 no-border shadow-card b-r-6">
                    <div className="card-header ">
                      <h5 className="pull-left">Total Sales <i className="fa fa-circle text-complete fs-6"></i></h5>
                      <div className="pull-right small hint-text">
                        <a href="javascript:void(0);">DETAILS</a>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                    <div className="card-description">
                      <div className="card card-borderless">
                        <div className="tab-content">
                          <div className="tab-pane active" id="saletoday">
                            <div className="info-tab-count">
                              <div className="counter-tab"><sub>₹</sub>12.4k</div>
                            </div>
                          </div>
                          <div className="tab-pane " id="saleweek">
                            <div className="info-tab-count">
                              <div className="counter-tab"><sub>₹</sub>28.4k</div>
                            </div>
                          </div>
                          <div className="tab-pane" id="salemonth">
                            <div className="info-tab-count">
                              <div className="counter-tab"><sub>₹</sub>111.4k</div>
                            </div>
                          </div>
                        </div>
                        <div className="img-card">
                          <img src="/assets/img/dollar-icon.svg" />
                        </div>
                      </div>
                    </div>
                    <div className="card-footer clearfix">
                      <ul className="nav nav-tabs nav-tabs-simple" role="tablist" data-init-reponsive-tabs="dropdownfx">
                          <li className="nav-item">
                            <a className="active" data-toggle="tab" role="tab" data-target="#saletoday" href="#">TODAY</a>
                          </li>
                          <li className="nav-item">
                            <a href="#" data-toggle="tab" role="tab" data-target="#saleweek">LAST WEEK</a>
                          </li>
                          <li className="nav-item">
                            <a href="#" data-toggle="tab" role="tab" data-target="#salemonth">LAST MONTH</a>
                          </li>
                        </ul>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-sm-6  d-flex flex-column">
                  <div className="card social-card info-card full-width m-b-10 no-border shadow-card b-r-6">
                    <div className="card-header ">
                      <h5 className="pull-left">Total Quotes <i className="fa fa-circle text-complete fs-6"></i></h5>
                      <div className="pull-right small hint-text">
                        <a href="javascript:void(0);">DETAILS</a>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                    <div className="card-description">
                      <div className="card card-borderless">
                        <div className="tab-content">
                          <div className="tab-pane active" id="quotestoday">
                            <div className="info-tab-count">
                              <div className="counter-tab">07</div>
                            </div>
                          </div>
                          <div className="tab-pane " id="quotesweek">
                            <div className="info-tab-count">
                              <div className="counter-tab">15</div>
                            </div>
                          </div>
                          <div className="tab-pane" id="quotesmonth">
                            <div className="info-tab-count">
                              <div className="counter-tab">31</div>
                            </div>
                          </div>
                        </div>
                        <div className="img-card">
                          <img src="/assets/img/speech-bubble.svg" />
                        </div>
                      </div>
                    </div>
                    <div className="card-footer clearfix">
                      <ul className="nav nav-tabs nav-tabs-simple" role="tablist" data-init-reponsive-tabs="dropdownfx">
                          <li className="nav-item">
                            <a className="active" data-toggle="tab" role="tab" data-target="#quotestoday" href="#">TODAY</a>
                          </li>
                          <li className="nav-item">
                            <a href="#" data-toggle="tab" role="tab" data-target="#quotesweek">LAST WEEK</a>
                          </li>
                          <li className="nav-item">
                            <a href="#" data-toggle="tab" role="tab" data-target="#quotesmonth">LAST MONTH</a>
                          </li>
                        </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fluid p-t-0 p-b-25 sm-padding-10 quotes-request-title">
              <div className="header-block-ui">
                <h3>Quotes & Requests</h3>
              </div>
              <div className="row">
                <div className="col-lg-8 col-sm-8">
                  <div className="card info-card card-borderless shadow-card tab-ui-block">
                    <ul className="nav nav-tabs nav-tabs-simple" role="tablist" data-init-reponsive-tabs="dropdownfx">
                      <li className="nav-item">
                        <a className="active" data-toggle="tab" role="tab" data-target="#all-quotes" href="#">ALL</a>
                      </li>
                      <li className="nav-item">
                        <a href="#" data-toggle="tab" role="tab" data-target="#inprogress-quotes">IN PROGRESS</a>
                      </li>
                      <li className="nav-item">
                        <a href="#" data-toggle="tab" role="tab" data-target="#active-quotes">ACTIVE</a>
                      </li>
                      <li className="nav-item">
                        <a href="#" data-toggle="tab" role="tab" data-target="#pending-quotes">PAYMENT PENDING</a>
                      </li>
                    </ul>
                    <div className="tab-content p-0">
                      <div className="tab-pane active" id="all-quotes">
                        <div className="quotes-status-block inprogress-quotes current-quote-active">
                          <div className="quotes-id-block">
                            <label>ID 35829843</label>
                            <div className="id-status">IN PROGRESS</div>
                          </div>
                          <div className="reply-btn">
                            <a href="javascript:void(0);" className="btn-blue-light">REPLY</a>
                          </div>
                          <div className="content-quotes">
                            <h3>Quote request by Alexa Tenorio from New delhi to Singapore</h3>
                            <p>Please give me details of rooms in each hotels. if possible i want a pool in phuket and krabi…</p>
                          </div>
                          <div className="quote-user">
                            <figure>
                              <img src="/assets/img/alexa.png" />
                            </figure>
                            <figcaption>
                              <label>Gladys Kanyinda</label>
                              <span>+91-912-826-7574</span>
                            </figcaption>
                            <time>20 mins ago</time>
                          </div>
                        </div>

                        <div className="quotes-status-block waiting-quotes">
                          <div className="quotes-id-block">
                            <label>ID 35829843</label>
                            <div className="id-status">WAITING FOR REPLY</div>
                          </div>
                          <div className="reply-btn">
                            <a href="javascript:void(0);" className="btn-blue-light">REPLY</a>
                          </div>
                          <div className="content-quotes">
                            <h3>Quote request by Henry Itondo from Mumbai to Switzerland</h3>
                            <p>Hey! I need hotel in a better hotel in this budget.</p>
                          </div>
                          <div className="quote-user">
                            <figure>
                              <img src="/assets/img/alexa.png" />
                            </figure>
                            <figcaption>
                              <label>Gladys Kanyinda</label>
                              <span>+91-912-826-7574</span>
                            </figcaption>
                            <time>20 mins ago</time>
                          </div>
                        </div>

                        <div className="quotes-status-block active-quotes">
                          <div className="quotes-id-block">
                            <label>ID 35829843</label>
                            <div className="id-status">Active</div>
                          </div>
                          <div className="reply-btn">
                            <a href="javascript:void(0);" className="btn-blue-light">REPLY</a>
                          </div>
                          <div className="content-quotes">
                            <h3>New Message by Lacara Jones on Previous Quote</h3>
                            <p>Hey! I need hotel in a better hotel in this budget.</p>
                          </div>
                          <div className="quote-user">
                            <figure>
                              <img src="/assets/img/alexa.png" />
                            </figure>
                            <figcaption>
                              <label>Gladys Kanyinda</label>
                              <span>+91-912-826-7574</span>
                            </figcaption>
                            <time>20 mins ago</time>
                          </div>
                        </div>

                        <div className="quotes-status-block pending-quotes">
                          <div className="quotes-id-block">
                            <label>ID 35829843</label>
                            <div className="id-status">PAYMENT PENDING</div>
                          </div>
                          <div className="reply-btn">
                            <a href="javascript:void(0);" className="btn-blue-light">REPLY</a>
                          </div>
                          <div className="content-quotes">
                            <h3>New Message by Lacara Jones on Previous Quote</h3>
                            <p>Hey! I need hotel in a better hotel in this budget.</p>
                          </div>
                          <div className="quote-user">
                            <figure>
                              <img src="/assets/img/alexa.png" />
                            </figure>
                            <figcaption>
                              <label>Gladys Kanyinda</label>
                              <span>+91-912-826-7574</span>
                            </figcaption>
                            <time>20 mins ago</time>
                          </div>
                        </div>

                      </div>
                      <div className="tab-pane " id="inprogress-quotes">
                        <div className="quotes-status-block inprogress-quotes">
                          <div className="quotes-id-block">
                            <label>ID 35829843</label>
                            <div className="id-status">IN PROGRESS</div>
                          </div>
                          <div className="reply-btn">
                            <a href="javascript:void(0);" className="btn-blue-light">REPLY</a>
                          </div>
                          <div className="content-quotes">
                            <h3>Quote request by Alexa Tenorio from New delhi to Singapore</h3>
                            <p>Please give me details of rooms in each hotels. if possible i want a pool in phuket and krabi…</p>
                          </div>
                          <div className="quote-user">
                            <figure>
                              <img src="/assets/img/alexa.png"/>
                            </figure>
                            <figcaption>
                              <label>Gladys Kanyinda</label>
                              <span>+91-912-826-7574</span>
                            </figcaption>
                            <time>20 mins ago</time>
                          </div>
                        </div>

                      </div>
                      <div className="tab-pane" id="active-quotes">
                        <div className="quotes-status-block active-quotes">
                          <div className="quotes-id-block">
                            <label>ID 35829843</label>
                            <div className="id-status">Active</div>
                          </div>
                          <div className="reply-btn">
                            <a href="javascript:void(0);" className="btn-blue-light">REPLY</a>
                          </div>
                          <div className="content-quotes">
                            <h3>New Message by Lacara Jones on Previous Quote</h3>
                            <p>Hey! I need hotel in a better hotel in this budget.</p>
                          </div>
                          <div className="quote-user">
                            <figure>
                              <img src="/assets/img/alexa.png" />
                            </figure>
                            <figcaption>
                              <label>Gladys Kanyinda</label>
                              <span>+91-912-826-7574</span>
                            </figcaption>
                            <time>20 mins ago</time>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="pending-quotes">
                        <div className="quotes-status-block pending-quotes">
                          <div className="quotes-id-block">
                            <label>ID 35829843</label>
                            <div className="id-status">PAYMENT PENDING</div>
                          </div>
                          <div className="reply-btn">
                            <a href="javascript:void(0);" className="btn-blue-light">REPLY</a>
                          </div>
                          <div className="content-quotes">
                            <h3>New Message by Lacara Jones on Previous Quote</h3>
                            <p>Hey! I need hotel in a better hotel in this budget.</p>
                          </div>
                          <div className="quote-user">
                            <figure>
                              <img src="/assets/img/alexa.png" />
                            </figure>
                            <figcaption>
                              <label>Gladys Kanyinda</label>
                              <span>+91-912-826-7574</span>
                            </figcaption>
                            <time>20 mins ago</time>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-sm-4">
                  <div className="quotes-detail card info-card card-borderless shadow-card tab-ui-block inprogress-quotes">
                    <div className="head-quotes-detail">
                      <h3>ID 35829843</h3>
                      <a href="javascript:void(0);">CLOSE</a>
                    </div>
                    <div className="quotes-name">
                      <h3>Quote request by Alexa Tenorio from New delhi to Singapore</h3>
                      <div className="id-status">IN PROGRESS</div>
                    </div>
                    <div className="quotes-user-detail">
                      <div className="quote-user">
                        <figure>
                          <img src="/assets/img/alexa.png" />
                        </figure>
                        <figcaption>
                          <label>Gladys Kanyinda</label>
                          <span>Contact Not Available</span>
                        </figcaption>
                        <div className="mail-send-option">
                          <a href="javascript:void(0);">SEND MAIL</a>
                        </div>
                      </div>
                    </div>
                    <div className="detail-action">
                      <a href="javascript:void(0);" className="btn btn-primary">View Details</a>
                      <a href="javascript:void(0);" className="btn btn-complete">Conversation</a>
                      <a href="javascript:void(0);" className="btn border-btn">More</a>
                    </div>
                    <div className="detail-tour">
                      <div className="row">
                        <div className="col-sm-6">
                          <label>DESTINATION</label>
                          <span>New Zealand</span>
                        </div>
                        <div className="col-sm-6">
                          <label>FROM</label>
                          <span>Bengaluru</span>
                        </div>
                        <div className="col-sm-6">
                          <label>DEPARTURE DATE</label>
                          <span>11 Feb, 18</span>
                        </div>
                        <div className="col-sm-6">
                          <label>DURATION</label>
                          <span>6 Days 5 Nights</span>
                        </div>
                        <div className="col-sm-6">
                          <label>Type of Package</label>
                          <span className="highlight-red">Honeymoon</span>
                        </div>
                        <div className="col-sm-6">
                          <label>Budget (with Flight)</label>
                          <span>156k - 172k <small>per person</small></span>
                        </div>
                        <div className="col-sm-6">
                          <label>TRAVELERS</label>
                          <span>2 Adults · 0 Children</span>
                        </div>
                        <div className="col-sm-6">
                          <label>HOTEL</label>
                          <span>Yes, 3/4 Star</span>
                        </div>
                      </div>
                    </div>
                    <div className="latest-msg-block">
                      <div className="head-block-ui">
                        <h2>Latest Messages</h2>
                        <a href="javascript:void(0);">VIEW ALL</a>
                      </div>
                      <blockquote>
                        <p>Please give me details of rooms in each hotels. if possible i want a pool in phuket and krabi</p>
                        <label>Alexa</label>
                        <time>20mins ago</time>
                      </blockquote>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    	);
  	}
}
export default Dashboard;
