import React, { Component } from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import {ROUTES} from '../../routesList';
import $ from 'jquery';
import { PermissibleRender } from '@brainhubeu/react-permissible';


class LeftNavigation extends Component {
  constructor(props){
    super(props);
    this.state = {
      currrentUrl: props.location.pathname,
      permissions: props.permissions,
    }
  }

  componentWillReceiveProps(nextProps){
    if(this.props.location.pathname != nextProps.location.pathname){
      this.setState({currrentUrl: nextProps.location.pathname});
    }
  }

  componentDidMount(){

    $("body").addClass('fixed-header dashboard menu-pin');
    $("body").removeClass('nav-webe-hide');
    $(document).on('click', '.sidebar-menu a', function(e) {
      if ($(this).parent().children('.sub-menu') === false) {
          return;
      }
      var el = $(this);
      var parent = $(this).parent().parent();
      var li = $(this).parent();
      var sub = $(this).parent().children('.sub-menu');
      if(li.hasClass("open active")){
         el.children('.arrow').removeClass("open active");
         sub.slideUp(200, function() {
             li.removeClass("open active");
         });
      }else{
         parent.children('li.open').children('.sub-menu').slideUp(200);
         parent.children('li.open').children('a').children('.arrow').removeClass('open active');
         parent.children('li.open').removeClass("open active");
         el.children('.arrow').addClass("open active");
         sub.slideDown(200, function() {
            li.addClass("open active");
        });
      }
    });
  }
  render() {
    return (
      <nav className="page-sidebar" data-pages="sidebar">
        <div className="sidebar-header">
          <Link to="/">Travalot</Link>
        </div>
        <div className="sidebar-menu">
          {/*<div className="date-time-sidebar m-b-30">
            <span className="day">FRI</span>
            <div className="date-inner">
              <label>25<sub>Nov, 2018</sub></label>
            </div>
          </div>*/}
          <ul className="menu-items">
            <li className={((this.state.currrentUrl.indexOf("/admin/dashboard") !== -1) || this.state.currrentUrl == '/')?' active': ''}>
              <Link to="/" className="detailed" >
                <span className="icon-thumbnail"><ion-icon name="globe"></ion-icon></span>
                <span className="title">Dashboard</span>
              </Link>
            </li>
            {/*<li className={(this.state.currrentUrl.indexOf("/admin/unverified/quotes/list") !== -1)?'active': ''}>
              <Link to="/admin/unverified/quotes/list" >
                <span className="icon-thumbnail"><ion-icon name="list-box"></ion-icon></span>
                <span className="title">Unverified Leads</span>
              </Link>
            </li>*/}
            <li className={(this.state.currrentUrl.indexOf("/admin/leads/") !== -1)?'open active': ''}>
               <a href="javascript:void(0)" className="detailed">
                  <span className="icon-thumbnail">
                     <ion-icon name="list-box"></ion-icon>
                  </span>
                  <span className="title">Leads</span>
                  <span className={(this.state.currrentUrl.indexOf("/admin/leads/") !== -1)?'open arrow': 'arrow'}></span>
               </a>
               <ul className="sub-menu">
                 <PermissibleRender userPermissions={this.state.permissions} requiredPermissions={['manage_unverified_leads']} >
                    <li className={(this.state.currrentUrl.indexOf("/admin/leads/unverified/list") !== -1)?'active': ''}>
                       <Link to="/admin/leads/unverified/list">Unverified Leads</Link>
                    </li>
                </PermissibleRender>
                <PermissibleRender userPermissions={this.state.permissions} requiredPermissions={['manage_verified_leads']} >
                  <li className={(this.state.currrentUrl.indexOf("/admin/leads/verified/list") !== -1)?'active': ''}>
                     <Link to="/admin/leads/verified/list">Verified Leads</Link>
                  </li>
                </PermissibleRender>
                <PermissibleRender userPermissions={this.state.permissions} requiredPermissions={['manage_rejected_leads']} >
                  <li className={(this.state.currrentUrl.indexOf("/admin/leads/rejected/list") !== -1)?'active': ''}>
                     <Link to="/admin/leads/rejected/list">Rejected Leads</Link>
                  </li>
                </PermissibleRender>
               </ul>
            </li>
            <PermissibleRender userPermissions={this.state.permissions} requiredPermissions={['manage_new_quotes']} >
              <li className={(this.state.currrentUrl.indexOf("/admin/quotes/new/list") !== -1)?'active': ''}>
                <Link to="/admin/quotes/new/list" >
                  <span className="icon-thumbnail"><ion-icon name="list-box"></ion-icon></span>
                  <span className="title">New Quotes</span>
                </Link>
              </li>
            </PermissibleRender>
            <PermissibleRender userPermissions={this.state.permissions} requiredPermissions={['manage_quotation_followup']} >
              <li className={(this.state.currrentUrl.indexOf("/admin/quotes/follow_up/list") !== -1)?'active': ''}>
                <Link to="/admin/quotes/follow_up/list" >
                  <span className="icon-thumbnail"><ion-icon name="list-box"></ion-icon></span>
                  <span className="title">Quotation Follow up</span>
                </Link>
              </li>
            </PermissibleRender>
            <PermissibleRender userPermissions={this.state.permissions} requiredPermissions={['manage_payments_vouchers']} >
              <li className={(this.state.currrentUrl.indexOf("/admin/payments-vouchers") !== -1)?'open active': ''}>
                <Link to="/admin/payments-vouchers" className="detailed">
                  <span className="icon-thumbnail"><ion-icon name="wallet"></ion-icon></span>
                  <span className="title">Payments & Vouchers</span>
                </Link>
              </li>
            </PermissibleRender>
            <PermissibleRender userPermissions={this.state.permissions} requiredPermissions={['manage_all_quotes']} >
              <li className={(this.state.currrentUrl.indexOf("/admin/all-quotes/list") !== -1)?'open active': ''}>
                <Link to="/admin/all-quotes/list" className="detailed">
                  <span className="icon-thumbnail"><ion-icon name="wallet"></ion-icon></span>
                  <span className="title">All Quotes</span>
                </Link>
              </li>
            </PermissibleRender>
            <li className={(this.state.currrentUrl.indexOf("/admin/quotes/during-stay") !== -1 || this.state.currrentUrl.indexOf("/admin/quotes/post-stay") !== -1)?'open active': ''}>
               <a href="javascript:void(0)" className="detailed">
                  <span className="icon-thumbnail"><ion-icon name="ios-timer"></ion-icon></span>
                  <span className="title">Stay</span>
                  <span className={(this.state.currrentUrl.indexOf("/admin/quotes/during-stay") !== -1 || this.state.currrentUrl.indexOf("/admin/quotes/post-stay") !== -1)?'open arrow': 'arrow'}></span>
               </a>
               <ul className="sub-menu">
                 <PermissibleRender userPermissions={this.state.permissions} requiredPermissions={['manage_during_stay']} >
                   <li className={(this.state.currrentUrl.indexOf("/admin/quotes/during-stay/list") !== -1)?'active': ''}>
                    <Link to="/admin/quotes/during-stay/list">During Stay</Link>
                  </li>
                </PermissibleRender>
                <PermissibleRender userPermissions={this.state.permissions} requiredPermissions={['manage_post_stay']} >
                  <li className={(this.state.currrentUrl.indexOf("/admin/quotes/post-stay/list") !== -1)?'active': ''}>
                     <Link to="/admin/quotes/post-stay/list">Post Stay</Link>
                  </li>
                </PermissibleRender>
               </ul>
            </li>
            <PermissibleRender userPermissions={this.state.permissions} requiredPermissions={['manage_analytics']} >
              <li className={(this.state.currrentUrl.indexOf("/admin/stats") !== -1)?'active': ''}>
                <a target="_blank" href="/admin/stats/performance" >
                  <span className="icon-thumbnail"><ion-icon name="analytics"></ion-icon></span>
                  <span className="title">Analytics & Reports</span>
                </a>
              </li>
            </PermissibleRender>
            <PermissibleRender userPermissions={this.state.permissions} requiredPermissions={['manage_calendar']} >
              <li className={(this.state.currrentUrl.indexOf("/admin/calendar") !== -1)?'open active': ''}>
                <Link to="/admin/calendar" className="detailed">
                  <span className="icon-thumbnail"><ion-icon name="calendar"></ion-icon></span>
                  <span className="title">Calendar</span>
                </Link>
              </li>
            </PermissibleRender>
            <li className={(this.state.currrentUrl.indexOf("/admin/leads/unassigned/") !== -1)?'open active': ''}>
              <Link to="/admin/leads/unassigned/list" className="detailed">
                <span className="icon-thumbnail"><ion-icon name="ios-timer"></ion-icon></span>
                <span className="title">Assign Leads</span>
              </Link>
            </li>
            <li>
              <Link to="index.html" className="detailed">
                <span className="icon-thumbnail"><ion-icon name="ios-timer"></ion-icon></span>
                <span className="title">Approve Quotation</span>
              </Link>
            </li>


          </ul>
          <div className="clearfix"></div>
        </div>
      </nav>
    );
  }
}
export default withRouter(LeftNavigation);
