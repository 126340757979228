import React, {Component} from 'react';
import {Route, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import Moment from 'react-moment';
import 'moment-timezone';
import { Field, reduxForm, change, SubmissionError, FormSection } from 'redux-form';
import renderTextarea from '../../FormFields/renderTextarea';
import renderDateTimePicker from '../../FormFields/renderDateTimePicker';
import $ from 'jquery';
import {quoteAddNote, quoteNotesListing} from '../../../actions/quotes';
import {ADD_NOTES_DATA} from '../../../constants';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';

function validate(){

}

class QuoteNotesNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      elementToBeUpdated: props.elementId?props.elementId:null,
      type: props.type?props.type:null,
      quote_id: props.trip_id,
      trip_id: props.trip_id,
      notes: null,
      noteData: null,
      openModal: false
    };
  }

  componentDidMount() {
    this.fetchNotes();
  }

  submitForm(values){
    return this.props.quoteAddNote(values).then((response) => {
      if (response.value.data.result == 'success') {
        if(this.state.elementToBeUpdated){
          $('#'+this.state.elementToBeUpdated).html(response.value.data.payload.note_main_option);
        }else{
          this.fetchNotes();
        }
        toast.success(response.value.data.flash_message);
        this.props.reset("QuoteNotesNew");
        this.setState({openModal: false, noteData: null});
      }
    });
  }

  fetchNotes() {
    this.props.quoteNotesListing({quote_id: this.state.quote_id}).then((response) => {
      if (response.value.data.result == 'success') {
        var notes = response.value.data.payload;
        this.setState({notes: notes});
      }
    });
  }

  componentWillReceiveProps(nextProps) {

  }

  updateTextarea(e, note) {
    e.preventDefault();
    $("#quote-note-textarea").val(note);
  }



  openNoteForm(e, index, note_id){

    e.preventDefault();
    this.props.change('note_main_option', ADD_NOTES_DATA[index].label);
    this.props.change('quote_id', this.state.trip_id);
    this.props.change('note_id', note_id);
    this.setState({noteData: ADD_NOTES_DATA[index], openModal: true});
  }

  closeModal(){
    this.props.reset("QuoteNotesNew");
    this.setState({openModal: false, noteData: null});
  }

  render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    return (
      <div className={this.state.type=='button'?'dropdown-style dropdown':"dropdown-style dropdown note-block_list"}>
        {this.state.type=='button' && <a href="javascript:void(0);" data-toggle="dropdown">Add Note</a>}
        <div className={ `${" dropdown-menu dropdown-list-accept "} ${ this.state.type != 'button'?'dropdown-list-accept_list':' ' } `} role="menu" aria-labelledby="notification-center">
          <div className="head-block-filter">
            <h2>Notes</h2>
          </div>
          <ul className={this.state.type=='button'?'':"note-detail_list"}>
            {
              ADD_NOTES_DATA.map((obj, index) => {
                return(
                  <li key={index}>
                    <a href="#" onClick = {(e) => this.openNoteForm(e, index, obj.id)}><i><img src={"/assets/img/"+obj.img} alt="" /></i>{obj.label}</a>
                  </li>
                )
              })
            }

          </ul>

          <div className={this.state.type=='button'?"":'listing-wrapper_list'}>
            {this.state.type !='button' && <ul>
              {
                this.state.notes && this.state.notes.length > 0 && this.state.notes.map((obj, index) => (
                  <li key={obj.id}>
                    <div className="l-img-block">
                      <span>{obj.path && <img src={obj.path} alt=""/>}</span>
                    </div>
                    <div className="l-content-block">
                      <h3 className="post-title">{obj.note_main_option}</h3>
                      {obj.note_sub_option && <span className="post-sub-title">{obj.note_sub_option}</span>}
                      {obj.note_additional_info && <p className="post-text">{obj.note_additional_info}</p>}
                      <p className="post-date"><Moment format="DD MMM, YY &nbsp; h:mm a" >{obj.created_at+'-0000'}</Moment></p>
                    </div>
                </li>
                ))
              }





            </ul>
          }
          </div>

        </div>
        {
          this.state.noteData &&

          <div>
          <Modal isOpen={true} toggle={this.state.openModal} wrapClassName = "rejection-modal">
            <ModalHeader toggle={this.toggle} charCode="X">Add Note - TRIP ID {this.state.trip_id}</ModalHeader>
            <form onSubmit={ handleSubmit(this.submitForm.bind(this)) }>
            <ModalBody>
                  <div className="tc-block">
                    <div className="img-block"><span><img src={"/assets/img/"+this.state.noteData.img} alt=""/></span></div>
                    <div className="content-block">
                      <h4>{this.state.noteData.label}</h4>
                      <Field component = "input" type = "hidden" name="note_main_option"  />
                      <Field component = "input" type = "hidden" name="quote_id"  />
                      <ul>
                        {
                          this.state.noteData.data && this.state.noteData.data.length > 0 && this.state.noteData.data.map((obj, index) => {
                            return(
                              <li key = {index}>
                                  <div className="radio radio-success">
                                      <Field component="input" type="radio"  value={obj.label} name="note_sub_option" id={"radio_reason"+index} />
                                      <label htmlFor={"radio_reason"+index}>{obj.label}</label>
                                   </div>
                              </li>
                            )
                          })
                        }

                      </ul>
                    </div>
                  </div>

                  <div className="bt-block">
                      <Field name="note_additional_info" fieldClass=" " label = "Your Note" placeholder="Enter additional information" component={renderTextarea} />
                      <Field label="Set Reminder" component={renderDateTimePicker} name = 'note_snooze_time' date={true} />
                  </div>
              </ModalBody>
              <ModalFooter>
                <button type="submit" disabled={submitting} className="btn btn-primary btn-cons">Add Note {submitting && <i className="fa fa-spinner fa-spin"></i>}</button>
                <Button color="secondary" onClick={(e) => this.closeModal()}>Cancel</Button>
              </ModalFooter>
              </form>
            </Modal>
            </div>
          }

      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    quoteAddNote: (data) => {
      return dispatch(quoteAddNote(data));
    },
    quoteNotesListing: (data) => {
      return dispatch(quoteNotesListing(data));
    }
  };
}

QuoteNotesNew =  connect(null, mapDispatchToProps)(QuoteNotesNew);

QuoteNotesNew = reduxForm({
  form: 'QuoteNotesNew',  // a unique identifier for this form
  validate,
  enableReinitialize: true
})(QuoteNotesNew);

export default QuoteNotesNew;
